import { AfterViewInit, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AccessRightsService } from 'src/app/services/accessRights.service';
import { FrameworkService } from 'src/app/services/framework.service';
import { homeNews } from '../../models/homeNews';
import { CacheService } from '../../services/cache.service';
import { StorageService } from '../../authentication/storage.service';
import { SubjectService } from 'src/app/services/subject.service';
import { enumCacheStatusLoad, enumClubActivityType, enumHRefType, enumTimelineType } from 'src/app/enum/Enum';
import { eventClub } from '../../models/eventClub';

@Component({
   selector: 'app-home',
   templateUrl: './home.component.html',
   styleUrls: ['./home.component.scss'],
   encapsulation: ViewEncapsulation.None
})

export class HomeComponent implements OnInit, AfterViewInit {
   public allHomeNews = [];
   public allSponsors = [];
   public isTurnAdminButtonsOff: boolean = true;
   public joinVABourlettisGroupOnFacebook: string = "";
   public openAchvbPortal: string = "";
   public openFvwbPortal: string = "";
   public titleDescriptionDisplay: string = "";
   public EnumHRefType = enumHRefType;
   public allMatchNextArray: [string, eventClub][] = null;
   public allClubEvents = new Map<string, eventClub>();
   public allMatchNext = new Map<string, eventClub>();
   public isNetworkStatusOnline: boolean = false;
   public EnumTimelineType = enumTimelineType;
   public EnumClubActivityType = enumClubActivityType;
   
   constructor(
      private frameworkService: FrameworkService,
      private cacheService: CacheService,
      private subjectService: SubjectService,
      private translateService: TranslateService,
      public accessRightsService: AccessRightsService,
      public storageService: StorageService,
      private router: Router,
      private sanitizer: DomSanitizer,
      config: NgbCarouselConfig) {

      //caroussel
      config.interval = 4000;
      config.keyboard = true;
      config.pauseOnHover = true;
      config.animation = true;
   }

   public ngOnInit(): void {
      this.subjectService.GlobalCacheLoadedStatusSubject.subscribe(value => { if (value != null && value >= enumCacheStatusLoad.loaded) this.initialize(); });
      this.subjectService.IsTurnOffAdminButtonSubject.subscribe(value => { this.isTurnAdminButtonsOff = value; });
      this.subjectService.IsNetworkStatusOnlineSubject.subscribe(value => { this.isNetworkStatusOnline = value; });
   }

   public getHtml(isCritical: boolean, value: string, isShouldAuthenticated: boolean) {
      if (isShouldAuthenticated)
         return !this.accessRightsService.isUserConnected ? "(Identification requise)" : this.frameworkService.replaceCrLfByBr(value ?? "");
      else
         return this.frameworkService.replaceCrLfByBr(value ?? "");
   }

   public getImgBackgroundURL(url: string) {
      return this.sanitizer.bypassSecurityTrustStyle('url(' + url + ')');
   }

   public getImgURL(url: string) {
      return this.sanitizer.bypassSecurityTrustUrl(JSON.parse(url));
   }

   public getNewsClass(isCritical: boolean) {
      return isCritical ? "criticalNews" : "news";
   }

   public getUpdatingDate(homeNews: homeNews): Date {
      if (homeNews.sysUpdatedOn) return homeNews.sysUpdatedOn;
      if (homeNews.sysCreatedOn) return homeNews.sysCreatedOn;
   }

   public getClosingDayInfo(closingDayReason) {
      return this.frameworkService.getClosingDayInfo(closingDayReason);
   }

   public getComiteeMeetingInfo(meeting) {
      return this.frameworkService.getComiteeMeetingInfo(meeting);
   }

   public getIconTimeLine(type: enumClubActivityType) {
      if (type == enumClubActivityType.BIRTHDAY)
         return "cake";
      if (type == enumClubActivityType.COMITEE_MEETING || type == enumClubActivityType.AG_MEETING)
         return "event_note";
      if (type == enumClubActivityType.SUPPER)
         return "event";
      if (type == enumClubActivityType.CLOSING)
         return "door_front";
      if (type == enumClubActivityType.TOURNAMENT_DAY)
         return "sports_volleyball";
      return "sports_volleyball";
   }

   public getCalendarActivityDate(date: Date) {
      return this.frameworkService.getCalendarActivityDate(date);
   }

   public getEventInfo(event) {
      return this.frameworkService.getEventInfo(event);
   }

   public goOnActivity() {
      this.router.navigate(['/activity']);
   }

   public goOnRanking() {
      this.router.navigate(['/ranking']);
   }

   public goOnSponsors() {
      this.router.navigate(['/sponsor']);
   }

   public initialize() {
      this.translatePage();
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
         this.translatePage();
      });

      this.refresh();
   }

   public isDisplayed(isShouldAuthenticated: boolean) {
      if (isShouldAuthenticated)
         return this.accessRightsService.isUserConnected;
      else
         return true;
   }

   public isInformationEditable() {
      return this.accessRightsService.isInformationEditable;
   }

   public ngAfterViewInit(): void {
   }

   public refresh() {
      this.allHomeNews = this.cacheService.getAllActiveHomeNews().OrderBy(i => i.order).ToArray();
      this.allSponsors = this.cacheService.getAllSponsors();

      this.allClubEvents = this.cacheService.getAllClubEvents();
      this.allMatchNext = this.frameworkService.findAllEventClubsOverToday(this.allClubEvents);
      this.allMatchNext.forEach((value, key) => {
         if ((value as eventClub).clubActivityType != enumClubActivityType.COMITEE_MEETING  &&
            ((value as eventClub).clubActivityType == enumClubActivityType.COMITEE_MEETING && !this.accessRightsService.isUserConnected) &&
            (value as eventClub).clubActivityType != enumClubActivityType.CLOSING &&
            (value as eventClub).clubActivityType != enumClubActivityType.SUPPER &&
            (value as eventClub).clubActivityType != enumClubActivityType.AG_MEETING 
         ) {
            this.allMatchNext.delete(key);
         }
      });

      this.allMatchNextArray = Array.from(this.allMatchNext.entries());
   }

   public getTiPaddingClass(i, array, isTimelineInverted) {
      return this._getTiPaddingClass(this.getTiPaddingClassCondition(i, array), isTimelineInverted);
   }
   public getTiPaddingClassCondition(i, array) {
      return false;
   }
   private _getTiPaddingClass(isHidden, isTimelineInverted) {
      if (isTimelineInverted)
         return (isHidden) ? "timeline-inverted tiSpaceNormal" : "timeline-inverted tiSpace";
      else
         return (isHidden) ? "tiSpaceNormal" : "tiSpace";
   }

   public translatePage() {
      this.translateService.get(['HomeTitleDescription'])
         .subscribe(translations => {
            this.titleDescriptionDisplay = translations['HomeTitleDescription'];
         });

      this.translateService.get(['JoinVABourlettisGroupOnFacebook'])
         .subscribe(translations => {
            this.joinVABourlettisGroupOnFacebook = translations['JoinVABourlettisGroupOnFacebook'];
         });

      this.translateService.get(['OpenFvwbPortal'])
         .subscribe(translations => {
            this.openFvwbPortal = translations['OpenFvwbPortal'];
         });

      this.translateService.get(['OpenAchvbPortal'])
         .subscribe(translations => {
            this.openAchvbPortal = translations['OpenAchvbPortal'];
         });
   }
}
