import { AfterViewInit, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { enumCacheStatusLoad, enumHRefType, enumPrivacyLevel, enumRoles } from 'src/app/enum/Enum';
import { user } from 'src/app/models/user/user';
import { FrameworkService } from 'src/app/services/framework.service';
import { sponsor } from '../../models/sponsor';
import { CacheService } from '../../services/cache.service';
import { AccessRightsService } from 'src/app/services/accessRights.service';
import { SubjectService } from 'src/app/services/subject.service';

@Component({
   selector: 'app-sponsor',
   templateUrl: './sponsor.component.html',
   styleUrls: ['./sponsor.component.scss'],
   encapsulation: ViewEncapsulation.None
})

export class SponsorComponent implements OnInit, AfterViewInit {
   public allSponsors;
   public isTurnAdminButtonsOff: boolean = true;
   public sponsorResponsible: user;
   public titleDescriptionDisplay: string = "";
   public EnumPrivacyLevel = enumPrivacyLevel;
   public EnumHRefType = enumHRefType;

   constructor(
      private cacheService: CacheService,
      private subjectService: SubjectService,
      public accessRightsService: AccessRightsService,
      private translateService: TranslateService,
      private frameworkService: FrameworkService,
      private sanitizer: DomSanitizer,
      config: NgbCarouselConfig) {

      //caroussel
      config.interval = 5500;
      config.keyboard = true;
      config.pauseOnHover = true;
      config.animation = true;
   }

   public ngOnInit() {
      this.subjectService.GlobalCacheLoadedStatusSubject.subscribe(value => { if (value != null && value >= enumCacheStatusLoad.loaded) this.initialize(); });
   }

   public getAddress(sponsor: sponsor) {
      if (sponsor.zipCode + sponsor.locality != "")
         return sponsor.address + " - " + sponsor.zipCode + " " + sponsor.locality;
      else
         return sponsor.address;
   }

   public getImgURL(url: string) {
      return this.sanitizer.bypassSecurityTrustUrl(JSON.parse(url));
   }

   public getImgBackgroundURL(url: string) {
      return this.sanitizer.bypassSecurityTrustStyle('url(' + url + ')');
   }

   public getPhoneFormat(phone: string) {
      return this.frameworkService.phoneFormat(phone);
   }

   public initialize() {
      this.subjectService.IsTurnOffAdminButtonSubject.subscribe(value => { this.isTurnAdminButtonsOff = value; });

      this.translatePage();
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
         this.translatePage();
      });

      this.allSponsors = this.cacheService.getAllSponsors();

      this.sponsorResponsible = this.cacheService.getAllUsersWithActiveLogin().FirstOrDefault(u =>
      (u.roleInComity?.includes(this.translateService.instant(enumRoles[enumRoles.SponsorShipManager])) ||
         u.roleInClubCustom?.includes(this.translateService.instant(enumRoles[enumRoles.SponsorShipManager]))
      )
      );
   }

   public isInformationEditable() {
      return this.accessRightsService.isInformationEditable;
   }

   public ngAfterViewInit(): void {
   }

   public translatePage() {
      this.translateService.get(['SponsorTitleDescription'])
         .subscribe(translations => {
            this.titleDescriptionDisplay = translations['SponsorTitleDescription'];
         });
   }
}
