import { Component, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import 'linq-typed';
import { enumCacheStatusLoad, enumPrivacyLevel, enumRoles } from 'src/app/enum/Enum';
import { user } from 'src/app/models/user/user';
import { FrameworkService } from 'src/app/services/framework.service';
import { CacheService } from '../../services/cache.service';
import { SubjectService } from 'src/app/services/subject.service';

@Component({
   selector: 'app-trainingInfo',
   templateUrl: './trainingInfo.component.html',
   styleUrls: ['./trainingInfo.component.scss']
})

export class TrainingInfoComponent implements OnInit {
   public allTeamsFiltered = [];
   public titleDescriptionDisplay: string = "";
   public EnumPrivacyLevel = enumPrivacyLevel;

   constructor(
      private cacheService: CacheService,
      private subjectService: SubjectService,
      private translateService: TranslateService,
      private frameworkService: FrameworkService
   ) {
   }

   public ngOnInit() {
      this.subjectService.GlobalCacheLoadedStatusSubject.subscribe(value => { if (value != null && value >= enumCacheStatusLoad.loaded) this.initialize(); });
   }
   
   public getPhoneFormat(phone: string) {
      return this.frameworkService.phoneFormat(phone);
   }

   public getTeamContactOfTeam(teamSysId: number): user {
      return this.cacheService.getAllUsers().FirstOrDefault(i => i.teamSysId == teamSysId && i.roleInClubCustom?.includes(this.translateService.instant(enumRoles[enumRoles.TeamContact])));
   }

   public initialize() {
      this.translatePage();
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
         this.translatePage();
      });

      this.refresh();
   }

   public refresh() {
      this.allTeamsFiltered = this.cacheService.getAllTeamsWithoutPicture().filter(i => i.division != "" && i.sysId > 1);
   }

   public translatePage() {
      //TrainingInfoTitle
      this.translateService.get(['TrainingInfoTitle'])
         .subscribe(translations => {
            this.titleDescriptionDisplay = translations['TrainingInfoTitle'];
         });
   }
}
