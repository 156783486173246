<mat-card class="mat-card-color popupDimensionImageCropMain">
   <mat-card-header class="mat-card-header-color">
      <mat-card-title>
         <span class="material-icons matcard-title-icon"
            style="margin-left:auto">photo</span>{{ 'ImageCropTitle' | translate }}
      </mat-card-title>
   </mat-card-header>
   <mat-card-content class="mat-card-content-padding">
      <div style="display:flex">
         <button type="button" mat-raised-button color="primary" class="spanButtonSeparator" (click)="fileInput.click()">
            <mat-icon>add_photo_alternate</mat-icon>&nbsp;&nbsp; {{ 'Browse' | translate }}
         </button>
         <input hidden (change)="fileChangeEvent($event)" #fileInput type="file" id="file">
         &nbsp;
         <button type="button" mat-raised-button color="primary" class="spanButtonSeparator" style="margin-left:auto;"
            (click)="onCloseDialog()">
            <mat-icon>cancel</mat-icon>&nbsp;&nbsp; {{ 'Cancel' | translate }}
         </button>
      </div>
      <div class="br1"></div>
      <div style="display:flex">
         <div *ngIf="accessRightsService.isUserConnectedAdministratorOrSupervisor()">
            <a href="https://tools.picsart.com/convert/pdf-to-jpg/" target="_blank">
               <button type="button" mat-raised-button color="primary" class="spanButtonSeparator">
                  <span class="material-icons" style="margin-left:auto">picture_as_pdf</span>
               </button>
            </a>
         </div>
         <div *ngIf="accessRightsService.isUserConnectedAdministratorOrSupervisor()">
            <a href="https://www.iloveimg.com/compress-image" target="_blank">
               <button type="button" mat-raised-button color="primary" class="spanButtonSeparator">
                  <span class="material-icons" style="margin-left:auto">vrpano</span>
               </button>
            </a>
         </div>
      </div>

      <div class="br1"></div>

      <div class="jdax-panel-round-flat" *ngIf="isSaveDialogButtonEnable">
         <button type="button" mat-raised-button color="primary" class="spanButtonSeparator" (click)="rotateLeft()">
            <mat-icon>rotate_left</mat-icon>
         </button>
         <button type="button" mat-raised-button color="primary" class="spanButtonSeparator" (click)="rotateRight()">
            <mat-icon>rotate_right</mat-icon>
         </button>
         <input [(ngModel)]="rotation" placeholder="Rotation" type="number" (keyup)="updateRotation()"
            style="width:50px" /> °

         <div class="br1"></div>

         <button type="button" mat-raised-button color="primary" class="spanButtonSeparator" (click)="zoomOut()">
            <mat-icon>zoom_out</mat-icon>
         </button>
         <button type="button" mat-raised-button color="primary" class="spanButtonSeparator" (click)="zoomIn()">
            <mat-icon>zoom_in</mat-icon>
         </button>
         <button type="button" mat-raised-button color="primary" class="spanButtonSeparator" (click)="flipHorizontal()">
            <img src="../../app/images/system/flip-horizontal.png" class="iconImageDisplaySize"
               alt="Flip horizontal">
         </button>
         <button type="button" mat-raised-button color="primary" class="spanButtonSeparator" (click)="flipVertical()">
            <img src="../../app/images/system/flip-vertical.png" class="iconImageDisplaySize"
               alt="Flip vertical">
         </button>

         <div class="br1"></div>

         <button type="button" mat-raised-button color="primary" class="spanButtonSeparator" (click)="resetImage()">
            <mat-icon>layers_clear</mat-icon>
         </button>
         <button type="button" mat-raised-button color="primary" class="spanButtonSeparator"
            (click)="toggleContainWithinAspectRatio()">
            <mat-icon>aspect_ratio</mat-icon>
         </button>

         {{(containWithinAspectRatio ? 'ImageCropAspectRatioFill' : 'ImageCropAspectRatioContain') | translate }}

         <div class="br1"></div>

         <hr>

         <div class="br1"></div>

         <div style="display: flex;align-items: center;justify-content: center;" *ngIf="showCropper">
            <button type="button" mat-raised-button color="primary" class="spanButtonSeparator" (click)="onSaveDialog()"
               [disabled]="!isSaveDialogButtonEnable">
               <mat-icon>save</mat-icon>&nbsp;&nbsp; {{ 'Accept' | translate }}
            </button>&nbsp;
               <img [src]="croppedImage" class="croppedImage"
               [style.border]="croppedImage ? '1px solid black' : 'none'" />
         </div>

         <div class="br1"></div>

         <div>
            <app-imageCrop 
               [resizeToWidth]="resizeToWidth" 
               [format]="format"
               [imageQuality]="imageQuality"
               [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="false"
               [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="4 / 3"
               [cropperMinWidth]="128" [onlyScaleDown]="true" [roundCropper]="false" [canvasRotation]="canvasRotation"
               [transform]="transform" [alignImage]="'left'" [style.display]="showCropper ? null : 'none'" 
               (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady($event)"
               (loadImageFailed)="loadImageFailed()" ></app-imageCrop>
         </div>
      </div>
   </mat-card-content>
</mat-card>