import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { AppConfigService } from './appconfig.service';
import { Observable, catchError, forkJoin, from, map, of, switchMap } from 'rxjs';
import { FrameworkService } from './framework.service';
import { SystemService } from './system.service';
import { SubjectService } from './subject.service';
import { user } from '../models/user/user';
import { EncryptionService } from './encryption.service';
import { encryptedValue } from 'src/app/authentication/encryptedValue';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  readonly VAPID_PUBLIC_KEY = 'BGl8DWZxQyicK--TMCCe7JfQxA9tmvNYgGkMXB6eOSGat35sffmXqoWEFJaKK4QYHNMJqhsHU14icExODHlxLyE';

  constructor(
    private frameworkService: FrameworkService,
    private systemService: SystemService,
    private appConfigService: AppConfigService,
    private subjectService: SubjectService,
    private encryptionService: EncryptionService,
    private swPush: SwPush,
    private httpClient: HttpClient) { }

    subscribeToNotifications(userSysId: number): Observable<user> {
      return from(this.swPush.requestSubscription({
        serverPublicKey: this.VAPID_PUBLIC_KEY
      })).pipe(
        switchMap(sub => {
          const subscription = {
            userId: userSysId,
            endpoint: sub.endpoint,
            p256dh: sub.toJSON().keys.p256dh,
            auth: sub.toJSON().keys.auth
          };
  
          return this.systemService.NotificationSubscribe(subscription).pipe(
            map(res => {
              if (res) {
                let dataDecrypted = this.encryptionService.decryptObject(false, (res as encryptedValue).value);
                  return dataDecrypted;
              } else {
                  return null;
              }
            }),
            catchError(error => {
              console.error('Error subscribing to notifications:', error);
              return of(null);
            })
          );
        })
      );
  }
  

  requestPermission() {
    Notification.requestPermission().then(permission => {
      if (permission === 'granted') {
        //console.log("<- Notification permission granted");
        this.frameworkService.displayAlert(true, 'Vos notifications web push sont activées.');

      } else {
        //console.log("<- Notification permission denied");
        this.frameworkService.displayAlert(false, 'Vos notifications web push sont bloquées.');
      }
      this.subjectService.IsButtonInLoadingSubject.next(false);
    });
  }
}