<div class="mat-card-popup">
   <mat-card class="mat-card-color">
      <mat-card-header>
         <mat-card-title><img class="mat-card-icon" src="../../../app/images/account/login.png"
               alt="Login"> {{ 'LoginConnectionTitle' |
            translate }}</mat-card-title>
         <mat-card-subtitle></mat-card-subtitle>
      </mat-card-header>

      <mat-card-content class="mat-card-content-padding">
         <form [formGroup]="form">
            <div class="form-group text-center">
               <input type="text" formControlName="username" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && form.controls.username.errors }"
                  placeholder="{{ 'Username' | translate }}" />
               <div *ngIf="submitted && form.controls.username.errors" class="invalid-feedback">
                  <div *ngIf="form.controls.username.errors.required">{{ 'UsernameIsRequired' | translate }}</div>
               </div>
            </div>

            <div class="form-group text-center">
               <div style="display:flex">
                  <input [type]="hidePassword ? 'password' : 'text'" formControlName="password"
                     class="form-control"
                     [ngClass]="{ 'is-invalid': submitted && form.controls.password.errors }"
                     placeholder="{{ 'Password' | translate }}" style="margin-right:5px" />
                  <mat-icon matSuffix (click)="hidePassword = !hidePassword" style="margin-top:10px;margin-left:auto">
                     {{hidePassword ?
                     'visibility_off' : 'visibility'}}</mat-icon>
               </div>
               <div *ngIf="submitted && form.controls.password.errors" class="invalid-feedback">
                  <div *ngIf="form.controls.password.errors.required">{{ 'PasswordIsRequired' | translate }}</div>
               </div>
            </div>

            <app-alertShow></app-alertShow>

            <div class="text-center font-weight-light">
               <app-button icon="vpn_key" [class]="'spanButtonSeparator'" [text]="'LoginSignInCommand' | translate" [disabled]="!isNetworkStatusOnline" [isBackgroundOpaque]="true" (onClick)="onSignInCommand()" ></app-button>
            </div>

            <div style="height:2%"><br /></div>

            <div class="text-center font-weight-light">
               Pour votre première connexion,<br />cliquer sur <app-button icon="" [class]="'spanButtonSeparator'" [text]="'LoginForgotPassword' | translate" [disabled]="!isNetworkStatusOnline" [isBackgroundOpaque]="true" (onClick)="onLoginForgotPasswordCommand()" ></app-button>
               <br/>afin d'initialiser votre mot de passe.
            </div>

            <div style="height:2%"><br /></div>


            <!-- T1 -->
            <!-- <div class="text-center font-weight-light">
        {{ 'LoginDontHaveAnAccountYet' | translate }} <a routerLink="/register" class="auth-link text-white">{{ 'RegistrationTitle' | translate }}</a>
      </div> -->
         </form>
      </mat-card-content>
   </mat-card>
</div>