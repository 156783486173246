import { Injectable, Injector } from '@angular/core';
import { authToken } from './authToken';
import { encryptedValue } from './encryptedValue';
import { EncryptionService } from 'src/app/services/encryption.service';
import { user } from 'src/app/models/user/user';
import { AccessRightsService } from 'src/app/services/accessRights.service';
import { FrameworkService } from 'src/app/services/framework.service';

const TOKEN_KEY = "error";
const DATA_PRIVACY_KEY = "dataprivacy";
const LAST_UPDATE_KEY = "lastupdate";
const LAST_RESET_KEY = "lastreset";

@Injectable({
   providedIn: 'root'
})
export class StorageService {
   constructor(
      private encryptionService: EncryptionService) {
   }

   authToken: authToken = null;

   public getUserConnected(): user | null {
      try {
         if (localStorage.getItem(TOKEN_KEY) == null || localStorage.getItem(TOKEN_KEY).toUpperCase() == "" || localStorage.getItem(TOKEN_KEY).toUpperCase() == "NULL" || JSON.parse(localStorage.getItem(TOKEN_KEY)).value == null)
            return null;
         if (this.encryptionService.decryptObject(true, JSON.parse(localStorage.getItem(TOKEN_KEY)).value) == undefined)
            return null;
         else
            return this.encryptionService.decryptObject(true, JSON.parse(localStorage.getItem(TOKEN_KEY)).value).user;
      }
      catch (_) {
         return null;
      }
   }

   public getTokenFromStorage(): authToken | null {
      if (localStorage.getItem(TOKEN_KEY) == null || localStorage.getItem(TOKEN_KEY) == "" || localStorage.getItem(TOKEN_KEY) == "NULL" || JSON.parse(localStorage.getItem(TOKEN_KEY)).value == null)
         return null;
      if (this.encryptionService.decryptObject(true, JSON.parse(localStorage.getItem(TOKEN_KEY)).value) == undefined)
         return null;
      else
         return this.encryptionService.decryptObject(true, JSON.parse(localStorage.getItem(TOKEN_KEY)).value);
   }

   public updateTokenUser(user: user): void {
      var authToken = this.getTokenFromStorage();
      authToken.user = user;
      this.authToken = authToken;
      var o = new encryptedValue();
      o.value = this.encryptionService.encryptObject(false, true, authToken);
      localStorage.setItem(TOKEN_KEY, JSON.stringify(o));
   }

   public saveToken(encryptedValue: encryptedValue): void {
      localStorage.setItem(TOKEN_KEY, JSON.stringify(encryptedValue));
      this.authToken = this.encryptionService.decryptObject(true, encryptedValue.value);
   }

   public getDataPrivacyCookie() {
      return localStorage.getItem(DATA_PRIVACY_KEY);
   }
   public getLastUpdateCookie() {
      return localStorage.getItem(LAST_UPDATE_KEY);
   }
   public getLastResetCookie() {
      return localStorage.getItem(LAST_RESET_KEY);
   }

   public isDataPrivacyAccepted() {
      return localStorage.getItem(DATA_PRIVACY_KEY) != null && localStorage.getItem(DATA_PRIVACY_KEY) == "ACCEPTED";
   }

   public isDataPrivacyIsNull() {
      return localStorage.getItem(DATA_PRIVACY_KEY) == null;
   }

   public setDataPrivacyCookie(value: boolean) {
      localStorage.setItem(DATA_PRIVACY_KEY, value ? "ACCEPTED" : "REFUSED");
   }
   public setLastUpdateCookie(value) {
      localStorage.setItem(LAST_UPDATE_KEY, value);
   }
   public setLastResetCookie(value) {
      localStorage.setItem(LAST_RESET_KEY, value);
   }

   public signOut(): void {
      localStorage.removeItem(TOKEN_KEY);
   }

   // public removeDataPrivacyCookie(): void {
   //    localStorage.removeItem(DATA_PRIVACY_KEY);
   // }
   // public removeLastUpdateCookie(): void {
   //    localStorage.removeItem(LAST_UPDATE_KEY);
   // }
   // public removeLastResetCookie(): void {
   //    localStorage.removeItem(LAST_RESET_KEY);
   // }

}