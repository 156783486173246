import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { enumActivityType, enumCacheStatusLoad, enumMatchDisplayStatus } from 'src/app/enum/Enum';
import { FrameworkService } from 'src/app/services/framework.service';
import { PrintService } from 'src/app/services/print.service';
import { match } from '../../models/match';
import { ranking } from '../../models/ranking';
import { team } from '../../models/team';
import { CacheService } from '../../services/cache.service';
import { SubjectService } from 'src/app/services/subject.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
   selector: 'app-ranking',
   templateUrl: './ranking.component.html',
   styleUrls: ['./ranking.component.scss'],
   encapsulation: ViewEncapsulation.None
})

export class RankingComponent implements OnInit, AfterViewInit {
   @ViewChild('tabContentRanking') tabContentRanking!: ElementRef<HTMLDivElement>;
   @ViewChild('tabContentResult') tabContentResult!: ElementRef<HTMLDivElement>;

   public allMainRankingOfTeamDic = new Map<Number, ranking[]>();
   public allMatchOfTeamDic = new Map<Number, match[]>();
   public allMatchLiveOfTeamDic = new Map<Number, match[]>();
   public allMatchFutureOfTeamDic = new Map<Number, match[]>();
   public allReserveRankingOfTeamDic = new Map<Number, ranking[]>();
   public allResultsSwitch: boolean = false;
   public allTeams: team[] = [];
   public displayedMatchColumns = [];
   public displayedMatchHeaderColumns = [];
   public displayedRankingColumns = [];
   public displayedRankingHeaderColumns = [];
   public isDisplayOnAMobile: boolean = false;
   public titleDescriptionDisplay: string = "";
   public allToggleLabel: string = "";
   public toggleTeamList = [];
   public allToggleTeamAreEnabled = true;

   constructor(
      private frameworkService: FrameworkService,
      private cacheService: CacheService,
      private subjectService: SubjectService,
      private translateService: TranslateService,
      public sanitizer: DomSanitizer,
      private printService: PrintService) {
   }
   
   ngAfterViewInit(): void {
   }

   public ngOnInit() {
      this.subjectService.GlobalCacheLoadedStatusSubject.subscribe(value => { if (value != null && value >= enumCacheStatusLoad.loaded) this.initialize(); });

      this.getScreenSize();

      if (this.isDisplayOnAMobile) {
         this.displayedRankingColumns = ['position', 'logo', 'teamName', 'jo', 'pPlus', 'ga', 'pe', 'sPlus', 'sMinus', 'coef'];
         this.displayedRankingHeaderColumns = ['Pos', "", 'Equipe', 'Jo', 'P+', 'Ga', 'Pe', 'S+', 'S-', "Coef"];
      }
      else {
         this.displayedRankingColumns = ['position', 'logo', 'teamName', 'jo', 'pPlus', 'ga', 'pe', 'ff', 'sPlus', 'sMinus', 'coef'];
         this.displayedRankingHeaderColumns = ['Pos', 'logo', 'Equipe', 'Jo', 'P+', 'Ga', 'Pe', 'Ff', 'S+', 'S-', "Coef"];
      }

      if (this.isDisplayOnAMobile) {
         this.displayedMatchColumns = ['day', 'score', 'fromTeam'];
         this.displayedMatchHeaderColumns = ['Date', 'Score', 'Rencontre'];
      }
      else {
         this.displayedMatchColumns = ['day', 'score', 'scoreDetail', 'fromTeam'];
         this.displayedMatchHeaderColumns = ['Date', 'Score', 'Détail du score', 'Rencontre'];
      }
   }

   @HostListener('window:resize', ['$event'])
   public getScreenSize() {
      this.isDisplayOnAMobile = (window.innerWidth <= 960);
   }

   public isFinished(match: match) {
      return match.score == "3 - 0" ||
         match.score == "3 - 1" ||
         match.score == "3 - 2" ||
         match.score == "0 - 3" ||
         match.score == "1 - 3" ||
         match.score == "2 - 3" ||
         match.scoreReserve == "3 - 0" ||
         match.scoreReserve == "2 - 1" ||
         match.scoreReserve == "0 - 3" ||
         match.scoreReserve == "1 - 2";
   }

   public classForMatchOfClub(match: match, column: string) {
      let wordWrap = "";
      if (column == "fromTeam")
         wordWrap = " wordWrap";

      if (column != "fromTeam" && match.score != "" && match.score != "0 - 0" && match.isAtHome == null && !this.isFinished(match)) {
         return "matCell inProgress" + wordWrap;
      }
      else {
         if (column == "fromTeam")
            return "matCell matCell100Percent" + wordWrap;
         else
            return "matCell" + wordWrap;
      }
   }

   public classForTeamNameOfClub(ranking: ranking, main: boolean) {
      return ranking.teamName.toUpperCase().includes(this.frameworkService.getSportsClubName().toUpperCase()) ? "matCell matRowHighlight" + (main ? "" : "Reserve") : "matCell";
   }

   public displayColumnRanking(column: string, ranking: ranking) {
      switch (column) {
         case "logo":
            return "<img src='" + ranking.logo + "' alt='Logo' width='20' height='20'>";
         default:
            return ranking[column];
      }
   }

   public displayColumn(noScore: boolean, column: string, match: match) {
      switch (column) {
         case "score":
            if (noScore)
               return "";
            
            let scores = match.score.split('-');
            let scoreRate = "<span class='material-icons matcard-title-icon matIconColorForPrint'>sports_volleyball</span>";
            if (match.isAtHome && Number(scores[0]) > Number(scores[1]) || (!match.isAtHome && Number(scores[0]) < Number(scores[1])))
               scoreRate = "<span class='material-icons matcard-title-icon'>emoji_events</span>";
            if (match.isAtHome == null)
               scoreRate = "";

            return "<div class='matchScoreRate'>" + scoreRate + " <span class='matchScore'>" + match.score + ((match.scoreReserve == "") ? "" : "/" + match.scoreReserve) + "</span></div>";
         case "day":
            return this.frameworkService.getDateOnlyFormatForScreen(match.day.toString());
         case "fromTeam":
            let temp: string = "";
            if (match.type != enumActivityType[enumActivityType.CHAMPIONSHIP] && match.team.teamCategory != "U17") {
               temp += this.translateService.instant(match.type) + "<div class='br1'></div>";
            }
            else {
               if (match.type == enumActivityType[enumActivityType.CHAMPIONSHIP] && match.number.startsWith("CL"))
                  temp += this.translateService.instant(match.type) + " " + this.translateService.instant("CHAMPIONSHIP_FINAL") + "<div class='br1'></div>";
            }

            if (match.isAtHome != null) {
               if (match.isAtHome)
                  temp += "<span class='VAB'>" + this.frameworkService.getSportsClubNameToUseOnDisplay(this.isDisplayOnAMobile) + "</span> - " + match.awayTeam + " " + (this.isDisplayOnAMobile ? (match.scoreDetail != "" ? " <br class='noPrint'> " : "") + match.scoreDetail : "");
               else
                  temp += " " + match.fromTeam + " - <span class='VAB'>" + this.frameworkService.getSportsClubNameToUseOnDisplay(this.isDisplayOnAMobile) + "</span>" + (this.isDisplayOnAMobile ? (match.scoreDetail != "" ? " <br class='noPrint'> " : "") + match.scoreDetail : "");
            }
            else
               temp += " " + match.fromTeam + " - " + match.awayTeam + (this.isDisplayOnAMobile ? (match.scoreDetail != "" ? " <br class='noPrint'> " : "") + match.scoreDetail : "");
            
            if (match.referee1 != "") {
               temp += "<br> Arbitrage : " + match.referee1;
               if (match.referee2 != "")
                  temp += ", " + match.referee2;
            }
            
            return temp;
         default:
            return match[column];
      }
   }

   public getAllResultsIcon() {
      return this.allResultsSwitch ? "remove" : "view_list";
   }

   public getAllResultsText() {
      this.subjectService.IsButtonInLoadingSubject.next(false);
      return this.allResultsSwitch ? "VAB" : "Tout";
   }

   public getDateReport(ranking: ranking) {
      if (ranking == null)
         return "";
      else
         return "(" + this.frameworkService.getDateOnlyFormatForScreen(ranking.reportingDate.toString()) + ")";
   }

   public getInvoiceDetails(invoiceId) {
      const amount = Math.floor((Math.random() * 100));
      return new Promise(resolve =>
         setTimeout(() => resolve({ amount }), 1000)
      );
   }

   public getMainRankingOfTeam(team: team) {
      return Array.from(this.allMainRankingOfTeamDic.get(team.sysId).values());
   }

   public getMatCardHeaderColorResult(team: team) {
      return "mat-card-header-color-result " + team.class;
   }

   public getMatchOfTeam(team: team) {
      return Array.from(this.allMatchOfTeamDic.get(team.sysId).values());
   }

   public getMatchLiveOfTeam(team: team) {
      return Array.from(this.allMatchLiveOfTeamDic.get(team.sysId).values());
   }
   public getMatchFutureOfTeam(team: team) {
      return Array.from(this.allMatchFutureOfTeamDic.get(team.sysId).values());
   }

   public getReserveRankingOfTeam(team: team) {
      return Array.from(this.allReserveRankingOfTeamDic.get(team.sysId).values());
   }

   public initialize() {
      this.translatePage();
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
         this.translatePage();
      });

      this.refresh();
   }

   public loadAllMatchOfTeams(all: boolean) {
      this.allTeams.forEach(i => {
         let allMatchs = [];
         let allMatchsLive = [];
         let allMatchsFuture = [];
         allMatchsLive = this.cacheService.getAllMatchs().filter(m => m.teamSysId == i.sysId && !this.isFinished && (m.status == enumMatchDisplayStatus[enumMatchDisplayStatus.JOU] && (m.score != '' && m.score != '0 - 0' && m.isAtHome == null)));
         allMatchsFuture = this.cacheService.getAllMatchs().filter(m => m.teamSysId == i.sysId && (m.status == enumMatchDisplayStatus[enumMatchDisplayStatus.AJO]));
         if (all) {
            allMatchs = this.cacheService.getAllMatchs().filter(m => m.teamSysId == i.sysId && this.isFinished && (m.status == enumMatchDisplayStatus[enumMatchDisplayStatus.JOU]));
         }
         else {
            allMatchs = this.cacheService.getAllMatchs().filter(m => m.teamSysId == i.sysId && this.isFinished && (m.status == enumMatchDisplayStatus[enumMatchDisplayStatus.JOU]) &&
               (m.fromTeam.toLowerCase().includes(this.frameworkService.getSportsClubName()) ||
                  m.awayTeam.toLowerCase().includes(this.frameworkService.getSportsClubName())))
         }

         allMatchs.sort((a, b) => this.frameworkService.getDateFormatYyyyMmDd(a.day) + a.team + a.type > this.frameworkService.getDateFormatYyyyMmDd(b.day) + b.team + b.type ? -1 : 1);
         allMatchsLive.sort((a, b) => this.frameworkService.getDateFormatYyyyMmDd(a.day) + a.team + a.type > this.frameworkService.getDateFormatYyyyMmDd(b.day) + b.team + b.type ? -1 : 1);
         allMatchsFuture.sort((a, b) => this.frameworkService.getDateFormatYyyyMmDd(a.day) + a.team + a.type > this.frameworkService.getDateFormatYyyyMmDd(b.day) + b.team + b.type ? 1 : -1);

         this.allMatchOfTeamDic.set(i.sysId, allMatchs);
         this.allMatchLiveOfTeamDic.set(i.sysId, allMatchsLive);
         this.allMatchFutureOfTeamDic.set(i.sysId, allMatchsFuture);

      });
   }

   public onAllResultsClick() {
      this.allResultsSwitch = !this.allResultsSwitch;
      this.loadAllMatchOfTeams(this.allResultsSwitch);
   }


   public onPrintRanking() {
      const printWindow = window.open('', '_blank');
      printWindow!.document.write(
         `
        <html>
          <head>
            <title>Print Tab</title>
            <style>
              body { font-family: Arial, sans-serif; }
.flexStart {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}
    .noPrint {
    display: none !important;
  }
  
.matTable {
  width: 100%;
  font-size: small;
    background-color: #FFFFFF;
    color: #000000;
    border-radius: 0px;
    font-size: x-large;
    padding: 5px;
    border-spacing: 0px;
    border-collapse: collapse;
}


.matCell {
  white-space: nowrap;
  border: solid 2px #000000;
  text-align: left;
   background: none;
   color: #000000;
   border-radius: 0px;
   font-size: large;
   padding: 5px;
}

.material-icons {
   font-size: 16px;
   font-family: 'Material Icons', 'Material Icons';
   -webkit-font-smoothing: antialiased;
   text-rendering: optimizeLegibility;
   -moz-osx-font-smoothing: grayscale;
   font-feature-settings: 'liga';
}
.matHeader {
  border: solid 2px #000000;
  padding: 10px;
  white-space: nowrap;
background-color: #cacaca;
font-size: x-large;
border-radius: 0px;
}

.matHeaderDate {
width: 50px;
}
.matHeaderScore {
width: 300px;
}

.matHeaderEquipe {
width: 600px;
}

.matRowHighlight {
   background-color: #bf0303 !important;
         color: white !important;
         font-size: x-large;

}

.matRowHighlightReserve {
   background-color: #2575dd !important;
         color: white !important;
         font-size: x-large;
}
         .printMargin {
         margin-top: 50px;
         }
          .printHeader {
    font-size: 40px;
  }
    .dateUpdate {
      font-size: medium;
}

.VAB {
  color: rgb(200, 120, 0);
}

            </style>
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
          </head>
          <body>
            ${this.tabContentRanking.nativeElement.innerHTML}
            ${this.tabContentResult.nativeElement.innerHTML}
          </body>
        </html>
      `);
      
      printWindow!.document.close();
      printWindow!.onload = () => {
         // Now the content is fully loaded, so you can trigger the print action
         printWindow!.focus(); // Focus on the new window (optional)
         printWindow!.print(); // Trigger print dialog
       };

      this.subjectService.IsButtonInLoadingSubject.next(false);
   }

   public onPrint(divName) {
      const printContents = document.getElementById(divName).outerHTML;
      const originalContents = document.body.outerHTML;

      document.body.innerHTML = printContents;
      window.print();
      document.body.innerHTML = originalContents;

      window.location.replace(window.location.href.replace('http', !window.location.href.includes("localhost:4300") ? 'https' : 'http'));
      window.location.reload();
   }

   public loadAllRankingOfTeams() {
      let selectedTeamsSysId = [];
      this.toggleTeamList.forEach(element => {
         if (!element.enabled) selectedTeamsSysId.push(element.sysId);
      });

      this.allTeams = this.cacheService.getAllTeamsWithoutPicture().filter(i => i.division != "" && selectedTeamsSysId.Contains(i.sysId)).OrderBy(i => i.teamDisplay).ToArray();
      this.allTeams.forEach(i => {
         let allRanking = this.cacheService.getAllRankings().filter(j => j.teamSysId == i.sysId && !j.isReserve).OrderBy(i => i.position).ToArray();
         this.allMainRankingOfTeamDic.set(i.sysId, allRanking);
         allRanking = this.cacheService.getAllRankings().filter(j => j.teamSysId == i.sysId && j.isReserve).OrderBy(i => i.position).ToArray();
         this.allReserveRankingOfTeamDic.set(i.sysId, allRanking);
      });

      this.loadAllMatchOfTeams(this.allResultsSwitch);





      // this.allMatchs = this.cacheService.getAllMatchs().filter(m => selectedTeamsSysId.Contains(m.teamSysId) && m.status == enumMatchDisplayStatus[enumMatchDisplayStatus.AJO] &&
      //    (m.fromTeam.toLowerCase().includes(this.frameworkService.getSportsClubName()) ||
      //       m.awayTeam.toLowerCase().includes(this.frameworkService.getSportsClubName())))
      //    .sort((a, b) => this.frameworkService.getDateFormatYyyyMmDd(a.day) + a.team + a.type < this.frameworkService.getDateFormatYyyyMmDd(b.day) + b.team + b.type ? -1 : 1);
   }



   public onFilterChange(): void {
      this.setAllToggleTeamAreEnabled();
      if (this.allToggleTeamAreEnabled)
         this.allToggleLabel = "Toute";
      else
         this.allToggleLabel = "Aucune";
      this.loadAllRankingOfTeams();
   }




   onTeamToggleChanged(event: MatSlideToggleChange, item: any) {
      item.enabled = event.checked;
      let isAllEnabled: boolean = false;
      this.toggleTeamList.forEach(element => {
         if (element.enabled) isAllEnabled = true;
      });
      this.allToggleTeamAreEnabled = isAllEnabled;
      this.onFilterChange();
   }

   setAllToggleTeamAreEnabled() {
      let isAllEnabled: boolean = false;
      this.toggleTeamList.forEach(element => {
         if (element.enabled) isAllEnabled = true;
      });
      this.allToggleTeamAreEnabled = isAllEnabled;
   }

   onToggleAllTeams(event: any) {
      this.toggleTeamList.forEach(item => item.enabled = event.checked);

      this.onFilterChange();
   }


   public refresh() {
      this.allToggleTeamAreEnabled = false;

      this.toggleTeamList = this.cacheService.getAllTeamsWithoutPicture().filter(i => i.division != "" && i.sysId > 1 && i.isRankingAvailable).OrderBy(i => i.teamDisplay).ToArray().map(x => (
         {
            name: x.teamCategory,
            sysId: x.sysId,
            enabled: false,
            class: x.class
         })
      );
      this.onFilterChange();
   }

   public translatePage() {
      //RankingTitle
      this.translateService.get(['RankingTitle'])
         .subscribe(translations => {
            this.titleDescriptionDisplay = translations['RankingTitle'];
         });
   }
}
