import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OkDialogComponent, OkDialogModel } from 'src/app/components/okDialog/okDialog.component';
import { SubjectService } from 'src/app/services/subject.service';
import { CacheService } from 'src/app/services/cache.service';

@Component({
   selector: 'app-tooltip',
   templateUrl: './tooltip.component.html',
   styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent implements OnInit {
  @Input() public isTooltip: boolean = false;
  @Input() public message: string = "";
  @Input() public title: string = "";

  constructor(
      private subjectService: SubjectService,
      public dialog: MatDialog) {     
   }

  public ngOnInit() {
   }

  public onClick() {
      const dialogRefData = new OkDialogModel(this.title, this.message, this.isTooltip);

      const dialogRef = this.dialog.open(OkDialogComponent, {
         position: { top: '0px' },
         maxWidth: "100%",
         width: "100%",
         data: dialogRefData
      });

      dialogRef.afterClosed().subscribe(dialogResult => {
         this.subjectService.IsButtonInLoadingSubject.next(false);
      });
   }
}
