<div>
   <div *ngIf="sportclub == null">
      <mat-card class="mat-card-center-95P">
         <mat-card-header class="mat-card-header-color">
            <mat-card-title>
               <span class="material-icons matcard-title-icon"
                  style="margin-left:auto">festival</span>{{titleDescriptionDisplay}}
            </mat-card-title>
         </mat-card-header>
         <mat-card-content>
            (Données indisponibles)
         </mat-card-content>
      </mat-card>
   </div>
   <div *ngIf="sportclub != null">
      <mat-card class="mat-card-center-95P">
         <mat-card-header class="mat-card-header-color">
            <mat-card-title>
               <span class="material-icons matcard-title-icon"
                  style="margin-left:auto">festival</span>{{titleDescriptionDisplay}}
            </mat-card-title>
         </mat-card-header>
         <mat-card-content>
            <div class="matcard matcardFloat">
               <app-panel [widthPanel]="'400px'">
                  <span panel-title>Siège social</span>
                  <div panel-content>
                     <span>
                        <span class="material-icons">place</span> <a
                           href="https://maps.google.com/?q={{sportclub.address}} {{sportclub.zipCode}} {{sportclub.locality}}&sll={{sportclub.lat}},{{sportclub.long}}"
                           target="_blank" rel="noopener" class="auth-link">{{sportclub.address}} {{sportclub.zipCode}}
                           {{sportclub.locality}}</a>
                     </span>
                     <div class="br1"></div>
                     N° de matricule : {{sportclub.registrationNumber}}
                  </div>
               </app-panel>
            </div>

            <div class="matcard matcardFloat">
               <app-panel [widthPanel]="'400px'">
                  <span panel-title>Compte bancaire</span>
                  <div panel-content>
                     {{sportclub.account}}
                  </div>
               </app-panel>
            </div>

            <div class="matcard matcardFloat">
               <app-panel [widthPanel]="'400px'">
                  <span panel-title>Cotisations</span>
                  <div panel-content>
                     <div class="gridMatch">
                        <div class="columnLeft">Senior</div>
                        <div class="columnRight">{{sportclub.subscription1}} €</div>
                     </div>
                     <div class="gridMatch">
                        <div class="columnLeft">Junior</div>
                        <div class="columnRight">{{sportclub.subscription2}} €</div>
                     </div>
                     <div class="gridMatch">
                        <div class="columnLeft">Junior -12 ans</div>
                        <div class="columnRight">{{sportclub.subscription3}} €</div>
                     </div>
                     <div class="gridMatch">
                        <div>Réduction familiale à partir du 2ème membre</div>
                     </div>
                  </div>
               </app-panel>
            </div>

            <div class="matcard matcardFloat">
               <app-panel>
                  <span panel-title>NOS VALEURS</span>
                  <div panel-content>
                     Aujourd'hui, constitué de 4 équipes et de son école de jeune, le club a l'ambition de grandir sans
                     perdre son identité, en restant accessible et familial.<br>
                     Nous voulons offrir un encadrement de qualité où chaque pratiquant peut progresser et développer au
                     mieux ses aptitudes et ce en demandant la cotisation la moins chère de toute la Province !<br>
                     Nous souhaitons favoriser la pratique du volley comme facteur d'intégration et encourager les
                     valeurs fondamentales de la vie en groupe.<br>
                     Enfin, nous promouvons la santé sur le plan physique, psychique et social.
                  </div>
               </app-panel>
            </div>

            <div class="matcard matcardFloat">
               <app-panel>
                  <span panel-title>LE CLUB AUJOURD'HUI</span>
                  <div panel-content>
                     Notre Ecole des jeunes est en pleine restructuration avec :<br>
                     Dès septembre une mise en place d'une section pour les plus jeunes dans laquelle la psychomotricité
                     occupera une place importante.<br>
                     Une collaboration avec les Ecoles de l'Entité d'Anderlues qui va être organisée durant le mois de
                     septembre (journée découverte) afin d'accueillir de nouveaux jeunes entre 8 et 12 ans.<br>
                     Un entraînement « perfectionnement » qui sera également instauré accessibles aux jeunes du club
                     âgés de 12 à 16 ans.
                  </div>
               </app-panel>
            </div>

            <div class="matcard matcardFloat">
               <app-panel>
                  <span panel-title><span class="material-icons">history</span> L'historique du club</span>
                  <div panel-content>
                     <img src="../../../app/images/logo/VAnderlues-LogoSmall.jpg">
                     <div class="br1"></div>
                     Le Volley Anderlues fut créé en 2015, sous l'impulsion d'un groupe de joueurs
                     et de leur coach issus de la N3 de Binche, ainsi que grâce au soutien communal et à de généreux
                     sponsors. Le club a très vite grandi et dès 2018, 4 équipes étaient inscrites en championnat.<br>
                     En 2017, une école de jeunes prend naissance, la formation des jeunes étant dans l'ADN du club.<br>
                     En 2019, après un remaniement de sa structure, le club change de nom pour prendre celui de V.A.
                     Bourlettis.<br>
                     Dès 2020, le retour de Denis Van Bever, figure bien connue dans le milieu du volley-ball, à la
                     Présidence du club, initie une nouvelle dynamique au sein de la gestion ce celui-ci.<br>
                     Malgré la crise sanitaire, le club parvient à maintenir 2 équipes en championnat.<br>
                     Suite à la Covid, notre Ecole de jeunes est la principale victime et nous passons de 30 à 8
                     membres. Loin de se décourager, nous relançons le mouvement et actuellement nous remontons la pente
                     avec 15 jeunes.<br>
                     Cette saison 2023/2024, est un tournant dans la vie du club. Grâce à la montée de nos 2 équipes «
                     seniors », nous inscrivons 2 nouvelles équipes dont une constituée de toutes nos cadettes dont la
                     plupart sont dans notre Ecole des jeunes depuis sa création.
                  </div>
               </app-panel>
            </div>

            <div class="matcard matcardFloat">
               <app-panel>
                  <span panel-title><span class="material-icons">history</span> Anciennes photos d'équipe</span>
                  <div panel-content>
                     <div class="matcard">
                        <mat-card>
                           <mat-card-header class="mat-card-header-color">
                              <mat-card-title>Saison 2023/2024 - P2 Dames</mat-card-title>
                              <mat-card-subtitle>
                                 (Coach) Denis Van bever - (2) Sabine Geens - (3) Eloïse Vita - (4) Sarah Sturiale - (5) Cloé Morsiau - (9) Maïwenn Bossiroy - (10) Maddy Vanpevenage - (11) Marlène Wargnie - (12) Stéphanie Visee - (13) Marie Charlier - (15) Céline Charlier - (17) Laurie Blondelle - (22) Méline Beuvelet - (24) Prescillia Michaux - (25) Viorica Godaux - (53) Valentine Laisnez
                              </mat-card-subtitle>
                           </mat-card-header>
                           <mat-card-content>
                              <div class="flexCenter"><img class="image" loading="lazy"
                                    src="../../../app/images/content/P2D2023-2024.jpg"
                                    alt="P2D2023-2024"></div>
                           </mat-card-content>
                        </mat-card>
                     </div>
                     <div class="matcard">
                        <mat-card>
                           <mat-card-header class="mat-card-header-color">
                              <mat-card-title>Saison 2023/2024 - P2 Messieurs</mat-card-title>
                              <mat-card-subtitle>
                                 (Coach) Manon Petit - (1) Luka Petit - (2) Benoît Thirifays - (3) Basile Bredun - (4) David Dal cin - (5) Anatole Spiteri - (6) Hugo Courroux - (8) Jean-françois Broze - (9) Jonatane Vanderbeck - (10) Julien Cabo - (11) Christophe Coliche - (13) Xavier Rosman - (15) Jacques Dakskobler
                              </mat-card-subtitle>
                           </mat-card-header>
                           <mat-card-content>
                              <div class="flexCenter"><img class="image" loading="lazy"
                                    src="../../../app/images/content/P2M2023-2024.jpg"
                                    alt="P2M2023-2024"></div>
                           </mat-card-content>
                        </mat-card>
                     </div>
                     <div class="matcard">
                        <mat-card>
                           <mat-card-header class="mat-card-header-color">
                              <mat-card-title>Saison 2023/2024 - P4 Dames</mat-card-title>
                              <mat-card-subtitle>
                                 (Coach) Yvan Emma - Athéna Serra - (1) Clara Renaux - (6) Lehna Hassaini - (7) Lisa Labar - (8) Clizia Vullo - (14) Emma Vagnier - (16) Clélya Duymelinck - (18) Juliana Kiss-fodor - (20) Alessia Di pillo - (21) Maëra Sebaihi - (23) Lauraline Copet - (26) Sacha Desaulty - (28) Selena Barina - (33) Alicia Perez touris - Pauline Dubray - Tania Kenfack - Emily Montreuil
                              </mat-card-subtitle>
                           </mat-card-header>
                           <mat-card-content>
                              <div class="flexCenter"><img class="image" loading="lazy"
                                    src="../../../app/images/content/P4D2023-2024.jpg"
                                    alt="P4D2023-2024"></div>
                           </mat-card-content>
                        </mat-card>
                     </div>
                     <div class="matcard">
                        <mat-card>
                           <mat-card-header class="mat-card-header-color">
                              <mat-card-title>Saison 2023/2024 - P3 Messieurs</mat-card-title>
                              <mat-card-subtitle>
                                 (7) Romain Mathieu - (12) Benoît Mathieu - (14) Louis Windels - (17) Alessio Cali - (18) Mateo Cali - (21) Raphaël Van vlasselaer - (22) Maxime Van vlasselaer - (24, Coach) Jean-philippe Van leemput - (25) Jimmy Feincoeur - (26) Jean-luc Mathieu - (28) Alexis Dorant - (30) Jason Watté - (66) Michel Roelandt - Noa Mota-iglesias - Maxence Romnée - Emir Sherafetov
                              </mat-card-subtitle>
                           </mat-card-header>
                           <mat-card-content>
                              <div class="flexCenter"><img class="image" loading="lazy"
                                    src="../../../app/images/content/P3M2023-2024.jpg"
                                    alt="P3M2023-2024"></div>
                           </mat-card-content>
                        </mat-card>
                     </div>
                     <div class="matcard">
                        <mat-card>
                           <mat-card-header class="mat-card-header-color">
                              <mat-card-title>Saison 2023/2024 - Jeunes</mat-card-title>
                              <mat-card-subtitle>
                                 Lena Angiolillo - Aleyna Arbia - Laureline Bas - Mina-elise Chergui - Matheis Cocquyt - Selena Gelpi - Nora Laoukili - Timéo Lété - Eowyn Meurice - Ethan Moret - Lilas Piette - Eve Pirard - Yanis Robert - Hélène Romain - Emma Rousseau
                              </mat-card-subtitle>
                           </mat-card-header>
                           <mat-card-content>
                           </mat-card-content>
                        </mat-card>
                     </div>

                     <div class="matcard">
                        <mat-card>
                           <mat-card-header class="mat-card-header-color">
                              <mat-card-title>Saison 2022/2023 - P3 Dames</mat-card-title>
                              <mat-card-subtitle>
                                 (Coach) Denis Van bever - (1, Passeur) Clara Renaux - (2, Attaquant) Sabine Geens - (3,
                                 Opposit) Eloïse Vita - (5, Universel) Lou Scandella - (6, Attaquant) Julie Moureaux -
                                 (9, Universel) Maïwenn Bossiroy - (10, Attaquant) Maddy Vanpevenage - (12, Passeur)
                                 Cloé Morsiau - (13, Attaquant) Marie Charlier - (14, Attaquant) Emma Vagnier - (15,
                                 Central) Céline Charlier - (16, Passeur) Alison Beeckman - (17, Central) Laurie
                                 Blondelle - (22, Central) Méline Beuvelet - (24, Universel) Prescillia Michaux - (25,
                                 Opposit) Viorica Godaux - (27, Libéro) Kenza Kheloufi - (53, Central) Valentine Laisnez
                              </mat-card-subtitle>
                           </mat-card-header>
                           <mat-card-content>
                              <div class="flexCenter"><img class="image" loading="lazy"
                                    src="../../../app/images/content/P3D2022-2023.png"
                                    alt="P3D2022-2023"></div>
                           </mat-card-content>
                        </mat-card>
                     </div>
                     <div class="matcard">
                        <mat-card>
                           <mat-card-header class="mat-card-header-color">
                              <mat-card-title>Saison 2022/2023 - P3 Messieurs</mat-card-title>
                              <mat-card-subtitle>
                                 (Coach) Manon Petit - Jimmy Feincoeur - Liam Van brusselen - (1, Passeur) Luka Petit -
                                 (2, Opposit) Benoît Thirifays - (3, Attaquant) Basile Bredun - (4, Attaquant) Louis
                                 Windels - (5, Attaquant) Anatole Spiteri - (6, Opposit) Hugo Courroux - (7, Passeur)
                                 Julien Willems - (8, Central) Jean-françois Broze - (9, Libéro) Jason Beeckman - (10,
                                 Central) Julien Cabo - (11, Central) Christophe Coliche - (12, Attaquant) Jonatane
                                 Vanderbeck - (13, Opposit) Xavier Rosman - (15, Libéro) Jacques Dakskobler - (17,
                                 Attaquant) Alessio Cali - (18, Central) Mateo Cali
                              </mat-card-subtitle>
                           </mat-card-header>
                           <mat-card-content>
                              <div class="flexCenter"><img class="image" loading="lazy"
                                    src="../../../app/images/content/P3M2022-2023.png"
                                    alt="P3M2022-2023"></div>
                           </mat-card-content>
                        </mat-card>
                     </div>
                     <div class="matcard">
                        <mat-card>
                           <mat-card-header class="mat-card-header-color">
                              <mat-card-title>Saison 2022/2023 - Cadets/Cadettes 4X4</mat-card-title>
                              <mat-card-subtitle>
                                 Jade Alleman - Aleyna Arbia - Selena Barina - Florian Corten - Loona Dassonville -
                                 Juliana Kiss-fodor - Emma Legrand - Alicia Perez touris - Liam Van brusselen - Clara
                                 Renaux - Hanna Van windekens - Lisa Labar - Maxime Van vlasselaer - Dalia Kheloufi -
                                 Lilia Kheloufi - Lehna Hassaini - Emma Vagnier
                              </mat-card-subtitle>
                           </mat-card-header>
                           <mat-card-content>
                              <div class="flexCenter"><img class="image" loading="lazy"
                                    src="../../../app/images/content/CAD2022-2023.png"
                                    alt="CAD2022-2023"></div>
                           </mat-card-content>
                        </mat-card>
                     </div>
                     <div class="matcard">
                        <mat-card>
                           <mat-card-header class="mat-card-header-color">
                              <mat-card-title>Saison 2018/2019 - P3 Messieurs</mat-card-title>
                              <mat-card-subtitle>
                                 En haut, de gauche à droite : (11) Johan Pieterarens - (9) Christopher Rodriguez
                                 - (8) Jonathan
                                 Bas
                                 - (7) Julien Willems - (6, Coach) Frédéric Charels
                                 <div class="br1"></div>
                                 En bas : (1) Grégory Dethye - (2) Benoît Thirifays - (3) Gilles Coliche - (4)
                                 Christophe Coliche
                                 - (5) Anatole Spiteri - (15) Jacques Dakskobler
                              </mat-card-subtitle>
                           </mat-card-header>
                           <mat-card-content>
                              <div class="flexCenter"><img class="image" loading="lazy"
                                    src="../../../app/images/content/P3M2018-2019.jpg"
                                    alt="P3M2018-2019"></div>
                           </mat-card-content>
                        </mat-card>
                     </div>
                     <div class="matcard">
                        <mat-card>
                           <mat-card-header class="mat-card-header-color">
                              <mat-card-title>Saison 2017/2018 - P3 Messieurs</mat-card-title>
                              <mat-card-subtitle>
                                 En haut, de gauche à droite : (18) Julien Gobbo - (15) Jacques Dakskobler - (13)
                                 Xavier Rosman -
                                 (12) Nicolas Van Bever - (11) Johan Pieterarens - (10) Adelin Binard - (9)
                                 Christopher Rodriguez
                                 <div class="br1"></div>
                                 En bas : (1) Grégory Dethye - (2) Benoît Thirifays - (3) Jonathan Lixon - (5)
                                 Jordan Behets -
                                 (6 , Coach) Frédéric Charels - (7) Alain Thirifays
                              </mat-card-subtitle>
                           </mat-card-header>
                           <mat-card-content>
                              <div class="flexCenter"><img class="image" loading="lazy"
                                    src="../../../app/images/content/P3M2017-2018.png"
                                    alt="P3M2017-2018"></div>
                           </mat-card-content>
                        </mat-card>
                     </div>
                     <div class="matcard">
                        <mat-card>
                           <mat-card-header class="mat-card-header-color">
                              <mat-card-title>Saison 2017/2018 - P1 Messieurs</mat-card-title>
                              <mat-card-subtitle>En haut, de gauche à droite : (18) Julien Willems - (15) Nicolas
                                 Godfroid - (14)
                                 Diego Nollet - (13) Lardinois Thomas - (12) David Claude - (11) Christophe
                                 Coliche - (9)
                                 Bonferraro Romain
                                 <div class="br1"></div>
                                 En bas : (1, Coach) Arnaud Roulet - (2) Alan Willame - (4) Mathieu
                                 Hoberg - (5) Maxime Buja
                              </mat-card-subtitle>
                           </mat-card-header>
                           <mat-card-content>
                              <div class="flexCenter"><img class="image" loading="lazy"
                                    src="../../../app/images/content/P1M2017-2018.png"
                                    alt="P1M2017-2018"></div>
                           </mat-card-content>
                        </mat-card>
                     </div>
                     <div class="matcard">
                        <mat-card>
                           <mat-card-header class="mat-card-header-color">
                              <mat-card-title>Saison 2017/2018 - P3 Dames</mat-card-title>
                              <mat-card-subtitle>
                                 En haut, de gauche à droite : (Coach) Denis Van Bever - (10) Mélina
                                 Stamatoukos - (11)
                                 Victoria Godaux - (12) Morgane Michot - (13) Carole Derny - (14) Lindsay Alev -
                                 (15) Céline
                                 Charlier - (17) Marie Charlier - (18) Christelle Limage
                                 <div class="br1"></div>
                                 En bas : (1) Sarah Mertens - (2) Vanessa Ghysels , (3) Rosanna Caizza - (4)
                                 Emeline Detandt - (6) Claudia Bertelli - (7) Florence Piron - (8) Maylis
                                 Thirifays
                              </mat-card-subtitle>
                           </mat-card-header>
                           <mat-card-content>
                              <div class="flexCenter"><img class="image" loading="lazy"
                                    src="../../../app/images/content/P3D2017-2018.png"
                                    alt="P3D2017-2018"></div>
                           </mat-card-content>
                        </mat-card>
                     </div>
                     <div class="matcard">
                        <mat-card>
                           <mat-card-header class="mat-card-header-color">
                              <mat-card-title>Saison 2016/2017 - P2 Messieurs</mat-card-title>
                              <mat-card-subtitle>
                                 En haut, de gauche à droite : (Coach) Denis Van Bever - (14) Jérôme Hublet -
                                 Benoit
                                 (13) Thirifays - (12) Nicolas Van Bever - (11) Christophe Coliche - (10) Adelin
                                 Binard - (9)
                                 Romain
                                 Bonferraro - (8) Florian Defour
                                 <div class="br1"></div>
                                 En bas : (7) Jonathan Nicaise - (6) Axel Van Den Broeck - (5) Maxime Buja -
                                 (3) Frédéric Charels - (2) Alan Willame - (1) Arnaud Roulet
                              </mat-card-subtitle>
                           </mat-card-header>
                           <mat-card-content>
                              <div class="flexCenter"><img class="image" loading="lazy"
                                    src="../../../app/images/content/P2M2016-2017.jpg"
                                    alt="P2M2016-2017"></div>
                           </mat-card-content>
                        </mat-card>
                     </div>
                     <div class="matcard">
                        <mat-card>
                           <mat-card-header class="mat-card-header-color">
                              <mat-card-title>Saison 2016/2017 - P4 Dames</mat-card-title>
                              <mat-card-subtitle>
                                 En haut, de gauche à droite : (Coach) Denis Van Bever - (1) Sarah Mertens -
                                 (2) Vanessa
                                 Ghysels - (3) Rosana Caizza - (4) Emeline Detandt - (5) Fanny Carlier - (6)
                                 Claudia Bertelli -
                                 (7) Florence Piron
                                 <div class="br1"></div>
                                 En bas : (8) Maylis Thirifays - (9) Aurore Duchene - (10) Mélina Stamatoukos -
                                 (11) Victoria
                                 Godaux
                                 - (18) Christelle Limage
                              </mat-card-subtitle>
                           </mat-card-header>
                           <mat-card-content>
                              <div class="flexCenter"><img class="image" loading="lazy"
                                    src="../../../app/images/content/P4D2016-2017.jpg"
                                    alt="P4D2016-2017"></div>
                           </mat-card-content>
                        </mat-card>

                     </div>
                  </div>
               </app-panel>
            </div>
         </mat-card-content>
      </mat-card>
   </div>
</div>