import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { timer } from 'rxjs';
import { CacheService } from 'src/app/services/cache.service';
import { AccountService } from "../../services/account.service";
import { AlertShowService } from "../../services/alertShow.service";
import { AppConfigService } from '../../services/appconfig.service';
import { FrameworkService } from '../../services/framework.service';
import { ConfirmedValidator } from './validators/confirmed.validator';
import { StrengthPasswordValidator } from './validators/strengthPassword.validator';
import { encryptedValue } from 'src/app/authentication/encryptedValue';
import { EncryptionService } from '../../services/encryption.service';
import { SubjectService } from '../../services/subject.service';

//THIS COMPONENT HAS NOT BEEN UPDATED BECAUSE IT IS HIDDEN SINCE THE BEGINNING OF DEV (SHOULD BE UPDATED MAYBE)

@Component({
   selector: 'app-register',
   templateUrl: './register.component.html',
   styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
   public barLabel: string;
   public form: FormGroup;
   public hideConfirmationPassword: boolean = true;
   public hidePassword: boolean = true;
   public isNetworkStatusOnline: boolean = false;
   public isRegistered: boolean = false;
   public submitted: boolean = false;

   constructor(
      private subjectService: SubjectService,
      private appConfigService: AppConfigService,
      private translateService: TranslateService,
      private formBuilder: FormBuilder,
      private accountService: AccountService,
      private alertShowService: AlertShowService,
      private frameworkService: FrameworkService,
      private encryptionService: EncryptionService
   ) {
      this.subjectService.IsNetworkStatusOnlineSubject.subscribe(value => { this.isNetworkStatusOnline = value; });
   }

   public getConfirmationPassword() {
      return this.form.controls["confirmPassword"].value;
   }

   public getPassword() {
      return this.form.controls["password"].value;
   }

   public ngOnInit() {
      this.form = this.formBuilder.group(
         {
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            email: ['', Validators.required],
            password: ['', [Validators.required, Validators.minLength(6)]],
            confirmPassword: ['', [Validators.required, Validators.minLength(6)]]
         },
         {
            validators:
               [
                  ConfirmedValidator('password', 'confirmPassword'),
                  StrengthPasswordValidator('password', this.frameworkService)
               ]
         }
      );

      this.translatePage();
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
         this.translatePage();
      });
   }

   public onRegisterCommand() {
      this.submitted = true;

      // reset alerts on submit
      this.alertShowService.clear();
      // stop here if form is invalid
      if (this.form.invalid) {
         this.subjectService.IsButtonInLoadingSubject.next(false);
         return;
      }

      this.accountService.isUsernameAvailable(this.form.value).subscribe({
         next: data => {
            let dataDecrypted = this.encryptionService.decryptObject(false, (data as encryptedValue).value);

            if (dataDecrypted == 0) {
               this.accountService.register(this.form.value).subscribe({
                  next: data => {
                     let dataDecrypted = this.encryptionService.decryptObject(false, (data as encryptedValue).value);

                     this.isRegistered = true;
                     this.alertShowService.success(this.translateService.instant('RegistrationSuccessfull'), { keepAfterRouteChange: true });
                     timer(this.frameworkService.saveStatusResetTimeOnSuccess()).subscribe(x => { this.alertShowService.clear() });
                     this.subjectService.IsButtonInLoadingSubject.next(false);
                  },
                  error: err => {
                     this.alertShowService.error(err.mes);
                     timer(this.frameworkService.saveStatusResetTimeOnFailed()).subscribe(x => { this.alertShowService.clear() });
                     this.subjectService.IsButtonInLoadingSubject.next(false);
                  }
               });
            }
            else {
               this.alertShowService.error(this.translateService.instant('RegistrationUsernameAlreadyExists'));
               timer(this.frameworkService.saveStatusResetTimeOnFailed()).subscribe(x => { this.alertShowService.clear() });
            }
            this.subjectService.IsButtonInLoadingSubject.next(false);
         },
         error: err => {
            this.alertShowService.error(err.mes);
            this.subjectService.IsButtonInLoadingSubject.next(false);
         }
      });
   }

   public translatePage() {
      this.translateService.get(['ChangePasswordStrengthDescription'])
         .subscribe(translations => {
            this.barLabel = translations['ChangePasswordStrengthDescription'];
         });
   }
}