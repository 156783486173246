import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { user } from '../models/user/user';
import { SubjectService } from '../services/subject.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  public userConnectedSubject: user = null;

  constructor(
      private router: Router,
      private subjectService: SubjectService
   ) {
      this.subjectService.UserConnectedSubject.subscribe(x => { this.userConnectedSubject = x; });
   }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      const user = this.userConnectedSubject;

      //TODO (User) : Only give access based on workgroup and/or roleInComity
      if (user) {
         // authorised so return true
         return true;
      }

      // not logged in so redirect to login page with the return url
      this.router.navigate(['/login']);
      return false;
   }
}