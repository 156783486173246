import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FrameworkService } from 'src/app/services/framework.service';
import { CacheService } from 'src/app/services/cache.service';
import { DataPrivacyDialogComponent, DataPrivacyDialogModel } from './dataPrivacyDialog.component';
import { GDPRDialogComponent, GDPRDialogModel } from './gDPRDialog.component';
import { VersionDialogComponent, VersionDialogModel } from './versionDialog.component';
import { enumCacheStatusLoad, enumCacheTypeLoad } from 'src/app/enum/Enum';
import { StorageService } from 'src/app/authentication/storage.service';
import { SubjectService } from 'src/app/services/subject.service';
import { AccessRightsService } from 'src/app/services/accessRights.service';

@Component({
   selector: 'app-footer',
   templateUrl: './footer.component.html',
   styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
   public currentApplicationLastVersionUpdate: string = "indisponible";
   public currentDataLastVersionUpdate: string = "";
   public currentApplicationVersionNumber: string = "indisponible";
   public isDisplayOnAMobile: boolean = false;

   constructor(
      private cacheService: CacheService,
      public subjectService: SubjectService,
      private frameworkService: FrameworkService,
      public storageService: StorageService,
      public accessRightsService: AccessRightsService,
      public dialog: MatDialog) {
      this.subjectService.GlobalCacheLoadedStatusSubject.subscribe(value => { if (value != null && value >= enumCacheStatusLoad.loaded) this.initialize(); });
      this.subjectService.VersionNumberSubject.subscribe(version => {
         if (version != null)
         {
            this.currentApplicationVersionNumber = version.split(',')[0].replace("#", "");
            this.currentApplicationLastVersionUpdate = version.split(',')[1];
         }
      });
      this.subjectService.DataLastUpdateSubject.subscribe(data => this.currentDataLastVersionUpdate = this.frameworkService.convertDateTimeToNowDDMMYYYYHHMMSSFromString(data));
      this.getScreenSize();
   }

   public ngOnInit(): void {
   }

   @HostListener('window:resize', ['$event'])
   public getScreenSize() {
      this.isDisplayOnAMobile = (window.innerWidth <= 960);
   }

   //POPUP PRIVACY
   public getDataPrivacyPopup() {
      const dialogData = new DataPrivacyDialogModel("Protection de vos données");

      //this.frameworkService.logInfo("dialog open ","DataPrivacy (footer)");
      const dialogRef = this.dialog.open(DataPrivacyDialogComponent, {
         maxWidth: "100%",
         width: "100%",
         maxHeight: "100%",
         height: "100%",
         data: dialogData
      });

      dialogRef.afterClosed().subscribe(dialogResult => {
         if (dialogResult) {
            if (this.storageService.isDataPrivacyAccepted()) {
               //Already accepted -> nothing todo
               this.subjectService.IsButtonInLoadingSubject.next(false);
            }
            else {
               //accepted -> Set privacy cookie and reload data
               this.storageService.setDataPrivacyCookie(true); //DATA_PRIVACY_KEY <- true
               this.cacheService.cacheFullReload(true);
            }
         }
         else {
            if (this.storageService.isDataPrivacyAccepted()) {
               //Accepted -> Refused -> reset data and reload
               this.cacheService.resetData();
               this.subjectService.IsButtonInLoadingSubject.next(false);
            }
            else {
               //Already refused -> nothing todo
               this.subjectService.IsButtonInLoadingSubject.next(false);
            }

         }
      });
   }

   public getGDPR() {
      this.dialog.open(GDPRDialogComponent, {
         position: { top: '0px' },
         maxWidth: "100%",
         width: "100%",
         data: new GDPRDialogModel("Règles GDPR")
      });
   }

   public getHistoryVersion() {
      this.dialog.open(VersionDialogComponent, {
         position: { top: '0px' },
         maxWidth: "100%",
         width: "100%",
         data: new VersionDialogModel("Version du site")
      });
   }

   public initialize() {
   }
}
