import { Component, Input, OnInit } from '@angular/core';
import { eventClub } from 'src/app/models/eventClub';
import { enumMatchDisplayType, enumTimelineType } from '../../enum/Enum';

@Component({
   selector: 'ti',
   templateUrl: './timelineDetail.component.html',
   styleUrls: ['./timelineDetail.component.scss']
})
export class TimelineDetailComponent implements OnInit {
  @Input() public class: string;
  @Input() public classContent: string = "";
  @Input() public isLabelHidden: boolean = false;
  @Input() public date: string;
  @Input() public eventClub: eventClub;
  @Input() public icon: string;
  @Input() public isNetworkStatusOnline: boolean = true;
  @Input() public isUserConnected: boolean = false;
  @Input() public memberPresenceCountActivity = new Map<string, string>();
  @Input() public text: string;
  @Input() public timelineType: enumTimelineType = enumTimelineType.NONE;
  @Input() public visible: boolean = true;

  public EnumMatchDisplayType = enumMatchDisplayType;
  public EnumTimelineType = enumTimelineType;

  constructor() {
   }

  public ngOnInit() {
      this.classContent = "timeline-panel " + this.classContent;
   }
}
