import { Component, ElementRef, Inject, OnInit, Optional, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Observable, forkJoin } from 'rxjs';
import { enumRoles, enumBlobType, enumQueryFilterOp } from 'src/app/enum/Enum';
import { ModalFormParam } from 'src/app/models/system/modalFormParam';
import { user } from 'src/app/models/user/user';
import { AccessRightsService } from 'src/app/services/accessRights.service';
import { FrameworkService } from 'src/app/services/framework.service';
import { blob } from '../../models/blob';
import { championship } from '../../models/championship';
import { comiteeMeeting } from '../../models/comiteeMeeting';
import { queryFilter } from '../../models/queryFilter';
import { AppointmentService } from '../../services/appointmentService';
import { BlobService } from '../../services/blob.service';
import { CacheService } from '../../services/cache.service';
import { ComiteeMeetingService } from '../../services/comiteeMeeting.service';
import { encryptedValue } from '../../authentication/encryptedValue';
import { EncryptionService } from 'src/app/services/encryption.service';
import { StorageService } from '../../authentication/storage.service';
import { SubjectService } from 'src/app/services/subject.service';
import { SnackbarService } from 'src/app/services/snackbar.service';

@Component({
   selector: 'app-comiteeMeetingEditionDialog',
   templateUrl: './comiteeMeetingEdition.component.html',
   styleUrls: ['./comiteeMeetingEdition.component.scss']
})
export class ComiteeMeetingEditionComponent implements OnInit {
   @ViewChild('pdfFileInput') public pdfFileInput: ElementRef;

   public allComiteeRoles = new Map<string, user>();
   public allComiteeRolesPresent = new Map<string, boolean>();
   public allComiteeRolesUser = new Map<string, user>();
   public cancelTitle: string;
   public championship: championship;
   public comiteeMeeting: comiteeMeeting;
   public crudType: number;
   public date: Date;
   public emailCc: string = "";
   public emailTo: string = "";
   public form: FormGroup = new FormGroup({});
   public isDeleteVisible: boolean = false;
   public isOnEdition: boolean = false;
   public isPdfFileLoaded: boolean = false;
   public isSaveVisible: boolean = false;
   public pdfFile: Blob | undefined;
   public pdfFileSysId: number = 0;
   public pointsToView: string = "";
   public selectedTime;
   public title: string;

   constructor(
      public snackbarService: SnackbarService,
      public dialogRef: MatDialogRef<ComiteeMeetingEditionComponent>,
      @Inject(MAT_DIALOG_DATA) public data: ComiteeMeetingEditionModel,
      private comiteeMeetingService: ComiteeMeetingService,
      private frameworkService: FrameworkService,
      private translateService: TranslateService,
      private subjectService: SubjectService,
      public accessRightsService: AccessRightsService,
      public storageService: StorageService,
      private blobService: BlobService,
      private cacheService: CacheService,
      private appointmentService: AppointmentService,
      private encryptionService: EncryptionService,
      @Optional() @Inject(MAT_DIALOG_DATA) public modalFormParam: ModalFormParam,
      @Optional() public dialog: MatDialog) {
      this.form.addControl("time", new FormControl(''));
      this.form.addControl("location", new FormControl(''));
      this.form.addControl("pointsToView", new FormControl(''));
      this.form.addControl("reportOfMeeting", new FormControl(''));
      this.form.addControl("externalDocumentOfMeetingPdf", new FormControl(''));
      this.form.addControl("sendNotification", new FormControl(''));
      this.cancelTitle = this.translateService.instant("Cancel");
      this.comiteeMeeting = data.comiteeMeeting;
      if (this.comiteeMeeting != null) this.pointsToView = this.comiteeMeeting.pointsToView;
      this.championship = data.championship;

      let allOfficalRoles = this.accessRightsService.getOfficalComiteeMembers().map(i => i.name);
      let allExtraRoles = this.accessRightsService.getExtraComiteeMembers().map(i => i.name);
      let allExtraRolesWithoutTeamDelegate = this.accessRightsService.getExtraComiteeMembersWithoutTeamDelegate().map(i => i.name);

      let allComiteeRoles = new Map<string, user>();
      allOfficalRoles.forEach(r => {
         this.cacheService.getAllUsersWithActiveLogin().filter(u => u.roleInComity?.includes(r) || u.roleInClubCustom?.includes(r)).forEach(
            s => { allComiteeRoles.set(r, s); });
      });
      allExtraRoles.forEach(r => {
         this.cacheService.getAllUsersWithActiveLogin().filter(u => u.roleInClub?.includes(r) || u.roleInClubCustom?.includes(r)).forEach(
            s => { allComiteeRoles.set(r, s); });
      });

      let temp = [];
      allOfficalRoles.filter(i => i != this.translateService.instant(enumRoles[enumRoles.Secretary])).forEach(r => {
         this.cacheService.getAllUsersWithActiveLogin().filter(u => u.roleInComity?.includes(r) || u.roleInClubCustom?.includes(r)).forEach(
            u => { if (u.isNotificationByEmailActivate && u.isNotificationComiteeMeetingByEmailActivate) temp.push(u); });
      });
      allExtraRolesWithoutTeamDelegate.forEach(r => {
         this.cacheService.getAllUsersWithActiveLogin().filter(u => u.roleInComity?.includes(r) || u.roleInClubCustom?.includes(r)).forEach(
            u => { if (u.isNotificationByEmailActivate && u.isNotificationComiteeMeetingByEmailActivate) temp.push(u); });
      });

      var secretary = this.cacheService.getAllUsersWithActiveLogin().FirstOrDefault(u =>
         (u.isNotificationByEmailActivate && u.isNotificationComiteeMeetingByEmailActivate) &&
         (u.roleInComity?.includes(this.translateService.instant(enumRoles[enumRoles.Secretary])) ||
            u.roleInClubCustom?.includes(this.translateService.instant(enumRoles[enumRoles.Secretary]))
         )
      );

      if (secretary == null)
         this.emailTo = "jacques.dakskobler@gmail.com";
      else
         this.emailTo = secretary?.email;

      this.emailCc = [...new Set(temp)].map(i => i.email).join(";");

      this.allComiteeRoles = allComiteeRoles;
      this.setAllComiteeRolesList();

      this.allComiteeRolesUser.forEach((value, key) => {
         this.allComiteeRolesPresent.set(key, false);
      });

      this.comiteeMeeting?.presentMember?.split(';').forEach(i => {
         this.allComiteeRolesPresent.set(i, true);
      });

      this.date = data.date;



      this.isOnEdition = data.isOnEdition;
      if (data.isOnEdition) {
         if (data.comiteeMeeting == null) {
            this.crudType = 1;
            this.isDeleteVisible = false;
            this.isSaveVisible = !this.isNotPresidentOrAdmin();
            this.resetValues();
         }
         else {
            this.crudType = 2;
            this.isDeleteVisible = !this.isNotPresidentOrAdmin();
            this.isSaveVisible = !this.isNotPresidentOrAdmin();
            this.setValues(data.comiteeMeeting);
         }
      }
      else {
         this.cancelTitle = this.translateService.instant("Close");
         this.isDeleteVisible = false;
         this.isSaveVisible = false;
         this.setValues(data.comiteeMeeting);
      }
      //this.frameworkService.logInfo(this.crudType, "crudType");
      this.title = "Réunion du " + this.frameworkService.getCalendarActivityDate(data.date, true);
   }

   public getAllComiteeRolesUserValues() {
      return Array.from(this.allComiteeRolesUser.values());
   }

   public getClass(member: user) {
      return this.allComiteeRolesPresent.get(member.sysId.toString()) ? "spanButtonWidth presentMember" : "spanButtonWidth notPresentMember";
   }

   public getHtml(value: string) {
      return this.frameworkService.replaceCrLfByBr(value ?? "");
   }

   public getPresentMember(): string {
      let result = "#";
      this.allComiteeRolesPresent.forEach((value, key) => {
         if (value) {
            result += ";" + key;
         }
      });
      return result.replace("#;", "").replace("#", "");
   }

   public getTimeChangedValue($event) {
      this.selectedTime = this.form.get('time').value;
   }

   public isNotPresidentOrAdmin(): boolean {
      if (!this.accessRightsService.isUserConnected)
         return true;
      else
         return !(this.accessRightsService.getMemberIsPresident(this.storageService.getUserConnected()) ||
            this.accessRightsService.getMemberIsVicePresident(this.storageService.getUserConnected()) ||
            this.storageService.getUserConnected().workgroupSysId <= 2); //Only admin or supervisor;
   }

   public manageRIUComiteeMeeting(meeting: comiteeMeeting, pdfFile: blob) {
      switch (this.crudType) {
         //Delete
         case 0:
            {
               let externalDocumentSysId = meeting.externalDocumentSysId;
               if (externalDocumentSysId != null) {
                  this.blobService.delete(externalDocumentSysId).subscribe({
                     next: dataB => {
                        if (dataB as number >= 0) {
                           this.sendComiteeMeetingAppointment(meeting, null);
                           this.comiteeMeetingService.delete(meeting.sysId).subscribe({
                              next: dataCM => {
                                 if (dataCM as number >= 0) {
                                    this.cacheService.manageRIUComiteeMeeting(meeting, null);
                                    this.subjectService.IsComiteeMeetingCacheLoadedSubject.next(true);
                                    this.frameworkService.displayAlert(true);
                                 }
                                 else
                                    this.frameworkService.displayAlert(false);
                                 this.subjectService.IsButtonInLoadingSubject.next(false);
                              },
                              error: err => {
                                 this.frameworkService.displayAlert(null, err);
                                 this.subjectService.IsButtonInLoadingSubject.next(false);
                              }
                           });
                        }
                        else {
                           this.frameworkService.displayAlert(false);
                        }

                        this.subjectService.IsButtonInLoadingSubject.next(false);
                     },
                     error: err => {
                        this.frameworkService.displayAlert(null, err);
                        this.subjectService.IsButtonInLoadingSubject.next(false);
                     }
                  });
               }
               else {
                  this.sendComiteeMeetingAppointment(meeting, null);
                  this.comiteeMeetingService.delete(meeting.sysId).subscribe({
                     next: dataCM => {
                        if (dataCM as number >= 0) {
                           this.cacheService.manageRIUComiteeMeeting(meeting, null);
                           this.subjectService.IsComiteeMeetingCacheLoadedSubject.next(true);
                           this.frameworkService.displayAlert(true);
                        }
                        else
                           this.frameworkService.displayAlert(false);
                        this.subjectService.IsButtonInLoadingSubject.next(false);
                     },
                     error: err => {
                        this.frameworkService.displayAlert(null, err);
                        this.subjectService.IsButtonInLoadingSubject.next(false);
                     }
                  });
               }
               break;
            }
         //Insert
         case 1:
            {
               //this.frameworkService.logInfo(meeting, "insert meeting CRUD 1");
               this.comiteeMeetingService.insert(meeting).subscribe({
                  next: dataCM => {
                     let dataCMDecrypted = this.encryptionService.decryptObject(false, (dataCM as encryptedValue).value);

                     if (dataCM != null) {
                        this.sendComiteeMeetingAppointment(dataCMDecrypted as comiteeMeeting, true);
                        //this.frameworkService.logInfo(dataCM, "dataCM return CRUD 1");
                        this.cacheService.manageRIUComiteeMeeting(dataCMDecrypted, true);
                        this.subjectService.IsComiteeMeetingCacheLoadedSubject.next(true);
                        this.frameworkService.displayAlert(true);
                     }
                     else
                        this.frameworkService.displayAlert(false);
                     this.subjectService.IsButtonInLoadingSubject.next(false);
                  },
                  error: err => {
                     this.frameworkService.displayAlert(null, err);
                     this.subjectService.IsButtonInLoadingSubject.next(false);
                  }
               });
               break;
            }
         //Update
         case 2:
            {
               //Check if the pdf is not yet saved in database
               const queryFilters = [];
               queryFilters.push(new queryFilter({ propertyName: "Title", operation: enumQueryFilterOp.Equals, value: meeting.reportOfMeeting }));
               queryFilters.push(new queryFilter({ propertyName: "ChampionshipSysId", operation: enumQueryFilterOp.Equals, value: this.championship.sysId }));

               this.blobService.getAllActiveWithoutIncluding(queryFilters).subscribe({
                  next: async data => {
                     var dataDecypted = this.encryptionService.decryptObject(false, data.value);

                     if (this.frameworkService.convertObjectToArray<blob>(dataDecypted).length > 0) {
                        //Pdf has been found in database

                        if (pdfFile != null) {
                           //Update it into database and save the comiteemeeting
                           let tempContent = pdfFile.content;
                           pdfFile = dataDecypted[0];
                           pdfFile.content = tempContent;

                           this.blobService.update(pdfFile).subscribe({
                              next: data => {
                                 let dataDecrypted = this.encryptionService.decryptObject(false, (data as encryptedValue).value);

                                 if (dataDecrypted != null && meeting != null) {
                                    meeting.externalDocumentSysId = (dataDecrypted as blob).sysId;

                                    this.comiteeMeetingService.update(meeting).subscribe({
                                       next: data => {
                                          let dataDecrypted = this.encryptionService.decryptObject(false, (data as encryptedValue).value);

                                          if (dataDecrypted != null) {
                                             this.sendComiteeMeetingAppointment(dataDecrypted as comiteeMeeting, false);
                                             this.cacheService.manageRIUComiteeMeeting(dataDecrypted, false);
                                             this.subjectService.IsComiteeMeetingCacheLoadedSubject.next(true);
                                             this.frameworkService.displayAlert(true);
                                          }
                                          else
                                             this.frameworkService.displayAlert(false);
                                          this.subjectService.IsButtonInLoadingSubject.next(false);
                                       },
                                       error: err => {
                                          this.frameworkService.displayAlert(null, err);
                                          this.subjectService.IsButtonInLoadingSubject.next(false);
                                       }
                                    });
                                 }
                                 else
                                    this.frameworkService.displayAlert(false);
                                 this.subjectService.IsButtonInLoadingSubject.next(false);
                              },
                              error: err => {
                                 this.frameworkService.displayAlert(null, err);
                                 this.subjectService.IsButtonInLoadingSubject.next(false);
                              }
                           });
                        }
                        else {
                           let externalDocumentSysId = meeting.externalDocumentSysId;
                           meeting.externalDocumentSysId = null;
                           meeting.reportOfMeeting = "";
                           this.comiteeMeetingService.update(meeting).subscribe({
                              next: dataCM => {
                                 let dataCMDecrypted = this.encryptionService.decryptObject(false, (dataCM as encryptedValue).value);

                                 if (dataCMDecrypted != null) {
                                    this.sendComiteeMeetingAppointment(dataCMDecrypted as comiteeMeeting, false);

                                    this.blobService.delete(externalDocumentSysId).subscribe({
                                       next: dataB => {
                                          if (dataB as number >= 0 && meeting != null) {
                                             this.cacheService.manageRIUComiteeMeeting(dataCMDecrypted, false);
                                             this.subjectService.IsComiteeMeetingCacheLoadedSubject.next(true);
                                             this.frameworkService.displayAlert(true);
                                          }
                                          else
                                             this.frameworkService.displayAlert(false);
                                          this.subjectService.IsButtonInLoadingSubject.next(false);
                                       },
                                       error: err => {
                                          this.frameworkService.displayAlert(null, err);
                                          this.subjectService.IsButtonInLoadingSubject.next(false);
                                       }
                                    });
                                 }
                                 else
                                    this.frameworkService.displayAlert(false);
                                 this.subjectService.IsButtonInLoadingSubject.next(false);
                              },
                              error: err => {
                                 this.frameworkService.displayAlert(null, err);
                                 this.subjectService.IsButtonInLoadingSubject.next(false);
                              }
                           });
                        }
                     }
                     else {
                        if (pdfFile != null) {
                           //Pdf has not been found in database
                           //Add it into database and save the comiteemeeting
                           this.blobService.insert(pdfFile).subscribe({
                              next: dataB => {
                                 let dataBDecrypted = this.encryptionService.decryptObject(false, (dataB as encryptedValue).value);

                                 if (dataBDecrypted != null && meeting != null) {
                                    meeting.externalDocumentSysId = (dataBDecrypted as blob).sysId;
                                    this.comiteeMeetingService.update(meeting).subscribe({
                                       next: dataCM => {
                                          let dataCMDecrypted = this.encryptionService.decryptObject(false, (dataCM as encryptedValue).value);

                                          if (dataCMDecrypted != null) {
                                             this.sendComiteeMeetingAppointment(dataCMDecrypted as comiteeMeeting, false);
                                             this.cacheService.manageRIUComiteeMeeting(dataCMDecrypted, false);
                                             this.subjectService.IsComiteeMeetingCacheLoadedSubject.next(true);
                                             this.frameworkService.displayAlert(true);
                                          }
                                          else
                                             this.frameworkService.displayAlert(false);
                                          this.subjectService.IsButtonInLoadingSubject.next(false);
                                       },
                                       error: err => {
                                          this.frameworkService.displayAlert(null, err);
                                          this.subjectService.IsButtonInLoadingSubject.next(false);
                                       }
                                    });
                                 }
                                 else
                                    this.frameworkService.displayAlert(false);
                                 this.subjectService.IsButtonInLoadingSubject.next(false);
                              },
                              error: err => {
                                 this.frameworkService.displayAlert(null, err);
                                 this.subjectService.IsButtonInLoadingSubject.next(false);
                              }
                           });
                        }
                        else {
                           this.comiteeMeetingService.update(meeting).subscribe({
                              next: dataCM => {
                                 let dataCMDecrypted = this.encryptionService.decryptObject(false, (dataCM as encryptedValue).value);

                                 if (dataCMDecrypted != null) {
                                    this.sendComiteeMeetingAppointment(dataCMDecrypted as comiteeMeeting, false);
                                    this.cacheService.manageRIUComiteeMeeting(dataCMDecrypted, false);
                                    this.subjectService.IsComiteeMeetingCacheLoadedSubject.next(true);
                                    this.frameworkService.displayAlert(true);
                                 }
                                 else
                                    this.frameworkService.displayAlert(false);
                                 this.subjectService.IsButtonInLoadingSubject.next(false);
                              },
                              error: err => {
                                 this.frameworkService.displayAlert(null, err);
                                 this.subjectService.IsButtonInLoadingSubject.next(false);
                              }
                           });
                        }
                     }
                  },
                  error: err => {
                      this.subjectService.IsButtonInLoadingSubject.next(false);
                      this.frameworkService.displayAlert(null, err);
                  }
                });
               break;
            }
      }
   }

   public ngOnInit() {
   }

   public onCancel(): void {
      this.dialogRef.close(false);
      this.subjectService.IsButtonInLoadingSubject.next(false);
   }

   public onClickApproved(bool: boolean): void {
      this.comiteeMeeting.isApproved = !bool;
      this.subjectService.IsButtonInLoadingSubject.next(false);
   }

   public onDelete(): void {
      this.crudType = 0;
      this.manageRIUComiteeMeeting(this.comiteeMeeting, null);
      this.dialogRef.close(true);
      this.subjectService.IsButtonInLoadingSubject.next(false);
   }

   public onMemberClick(member: user): void {
      this.allComiteeRolesPresent.set(member.sysId.toString(), !this.allComiteeRolesPresent.get(member.sysId.toString()));
   }

   public onPdfReportDelete() {
      this.subjectService.IsButtonInLoadingSubject.next(false);
      this.pdfFile = null;
   }

   public async onSave(): Promise<void> {
      let meeting = new comiteeMeeting();
      if (this.crudType == 2) {
         meeting = this.comiteeMeeting;
      }


      meeting.date = this.frameworkService.getDateAndTimeFormatDatabase(this.date, this.form.get("time").value);
      meeting.location = this.form.get("location").value;
      meeting.championship = this.championship;
      meeting.championshipSysId = meeting.championship.sysId;
      meeting.presentMember = this.getPresentMember();

      let newPdfBlob = new blob();
      if (this.pdfFile != null) {
         //Get the PDF file
         //let blobTemp = await this.pdfViewerService.getCurrentDocumentAsBlob();
         let base64String = await this.frameworkService.convertBlobToBase64(this.pdfFile);
         newPdfBlob.championshipSysId = this.championship.sysId;
         newPdfBlob.type = enumBlobType.Pdf;
         newPdfBlob.title = "ComiteeMeeting_" + this.frameworkService.getDateFormatYyyyMmDd(this.date);
         newPdfBlob.content = base64String.toString();
         //Get the PDF file

         meeting.reportOfMeeting = newPdfBlob.title;
      }
      else {
         newPdfBlob = null;
      }
      this.manageRIUComiteeMeeting(meeting, newPdfBlob);
      this.dialogRef.close(true);
   }

   public onUploadPdfFile(file: any) {
      this.subjectService.IsButtonInLoadingSubject.next(false);
      if (file.target.files && file.target.files[0]) {
         let reader = new FileReader();
         reader.onload = async (e: any) => {
            let base64 = await fetch(e.target.result);
            this.pdfFile = await base64.blob();
         };
         reader.readAsDataURL(file.target.files[0]);
         this.pdfFileInput.nativeElement.value = '';
      }
   }

   public resetValues() {
      this.form.controls['time'].setValue("20:45");
      this.selectedTime = this.form.get('time').value;
      this.form.get('location').setValue("Cafétariat / Salle omnisport d'Anderlues");
   }

   public sendComiteeMeetingAppointment(comiteeMeeting: comiteeMeeting, isCancelled?: boolean) {

      if (this.form.get("sendNotification").value) {
         if (this.emailTo == "jacques.dakskobler@gmail.com")
            this.frameworkService.displayAlert(null, "Aucune notification active pour le secrétaire n'a été trouvé.");

         let appServiceArray: Observable<any>[] = [];

         appServiceArray.push(this.appointmentService.sendComiteeMeetingAppointment(comiteeMeeting.sysId, this.emailTo, this.emailCc, isCancelled));

         forkJoin(appServiceArray).subscribe(res => {
            this.subjectService.IsButtonInLoadingSubject.next(false);
            if (res[0])
               this.frameworkService.displayAlert(true, "Les informations pour la réunion de comité ont été envoyées aux membres du comité.");
            else
               this.frameworkService.displayAlert(false, "Les informations pour la réunion de comité n'ont pas pu être envoyées aux membres du comité.");
         });
      }
   }

   public setAllComiteeRolesList(): void {
      this.allComiteeRolesUser = new Map<string, user>();
      this.allComiteeRolesPresent = new Map<string, boolean>();

      //Build the list of user to display who are at least 1 role
      this.allComiteeRoles.forEach((value, key) => {
         let temp = Array.from(this.allComiteeRolesUser.values());
         if (temp.filter(i => i.sysId == value.sysId).length == 0) {
            this.allComiteeRolesUser.set(value.sysId.toString(), value);
         }
      });

      this.allComiteeRolesUser.forEach((value, key) => {
         this.allComiteeRolesPresent.set(key, false);
      });
   }

   public setValues(comiteeMeeting: comiteeMeeting) {
      if (comiteeMeeting != null) {
         this.form.controls['time'].setValue(this.frameworkService.getTimeFormatHHmm(comiteeMeeting.date));
         this.form.get('location').setValue(comiteeMeeting.location);

         //Check if the pdf is not yet saved in database
         const queryFilters = [];
         queryFilters.push(new queryFilter({ propertyName: "Title", operation: enumQueryFilterOp.Equals, value: comiteeMeeting.reportOfMeeting }));
         queryFilters.push(new queryFilter({ propertyName: "ChampionshipSysId", operation: enumQueryFilterOp.Equals, value: this.championship.sysId }));

         this.blobService.getAllActiveWithoutIncluding(queryFilters).subscribe({
            next: async data => {
               var dataDecrypted = this.encryptionService.decryptObject(false, data.value);

               if (this.frameworkService.convertObjectToArray<blob>(dataDecrypted).length > 0) {
                  let base64 = await fetch((dataDecrypted[0] as blob).content);
                  this.pdfFile = await base64.blob();
               }
               else
                  this.pdfFile = null;
               this.isPdfFileLoaded = true;
            },
            error: err => {
                this.subjectService.IsButtonInLoadingSubject.next(false);
                this.frameworkService.displayAlert(null, err);
            }
          });
      }
   }
}

export class ComiteeMeetingEditionModel {
   constructor(public isOnEdition: boolean, public date: Date, public comiteeMeeting: comiteeMeeting, public championship: championship) {
   }
}
