import { Injectable, OnDestroy, OnInit } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { activityByMember } from "src/app/models/activityByMember";
import { user } from "../models/user/user";
import { enumCacheStatusLoad } from "../enum/Enum";

@Injectable()
export class SubjectService implements OnInit, OnDestroy {
   public MessageCacheSubject: BehaviorSubject<string> = new BehaviorSubject("");
   public StepNumberCacheSubject: BehaviorSubject<number> = new BehaviorSubject(0);

   public IsVersionCacheLoadedSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

   public IsWindowResizingSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
   public IsTurnOffAdminButtonSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
   public IsSynchronisationInProgressSubject: BehaviorSubject<number> = new BehaviorSubject(null);
   public IsSynchronisationMembersInProgressSubject: BehaviorSubject<number> = new BehaviorSubject(null);
   public IsSynchronisationSeniorMatchsInProgressSubject: BehaviorSubject<number> = new BehaviorSubject(null);
   public IsSynchronisationYoungMatchsInProgressSubject: BehaviorSubject<number> = new BehaviorSubject(null);
   public IsButtonInLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
   public IsPrivacyPopupClosedSubject: BehaviorSubject<boolean> = new BehaviorSubject(null);

   //0 : not on timeout
   //1 : on timeout
   //3 : on restart of the hubconnection
   public HubConnectionStatusSubject: BehaviorSubject<number> = new BehaviorSubject(0);
   public IsNetworkStatusOnlineSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
   public IsDatagridShouldbeRefreshedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

   public ActivityByMemberInsertedOrUpdatedSubject: BehaviorSubject<activityByMember> = new BehaviorSubject(null); 
   public UserConnectedSubject: BehaviorSubject<user> = new BehaviorSubject(null);

   public IsComiteeMeetingCacheLoadedSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
   public IsTrainingPlayerCacheLoadedSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

   public GlobalCacheLoadedStatusSubject: BehaviorSubject<number> = new BehaviorSubject(enumCacheStatusLoad.none);
   
   public VersionNumberSubject: BehaviorSubject<string> = new BehaviorSubject("Version indisponible");
   public DataLastUpdateSubject: BehaviorSubject<string> = new BehaviorSubject("indisponible");

   public IsDataUpdatingSubject: BehaviorSubject<boolean> = new BehaviorSubject(null);

   public GlobalCacheLoadStatus: number;
   public IsGlobalCacheInLoading: boolean;

   constructor() {
      this.GlobalCacheLoadedStatusSubject.subscribe(value => 
         {
            if (value != null)
            {
               this.GlobalCacheLoadStatus = value;
               this.IsGlobalCacheInLoading = this.GlobalCacheLoadStatus == enumCacheStatusLoad.loading;
            }
         });
   }

   ngOnInit(): void {

   }

   public ngOnDestroy(): void {
      this.IsButtonInLoadingSubject.unsubscribe();
      this.IsPrivacyPopupClosedSubject.unsubscribe();
      this.IsComiteeMeetingCacheLoadedSubject.unsubscribe();
      this.GlobalCacheLoadedStatusSubject.unsubscribe();
      this.MessageCacheSubject.unsubscribe();
      this.IsTrainingPlayerCacheLoadedSubject.unsubscribe();
      this.IsVersionCacheLoadedSubject.unsubscribe();
      this.IsTurnOffAdminButtonSubject.unsubscribe();
      this.IsSynchronisationInProgressSubject.unsubscribe();
      this.IsSynchronisationMembersInProgressSubject.unsubscribe();
      this.IsSynchronisationSeniorMatchsInProgressSubject.unsubscribe();
      this.IsSynchronisationYoungMatchsInProgressSubject.unsubscribe();

      this.ActivityByMemberInsertedOrUpdatedSubject.unsubscribe();
      this.IsWindowResizingSubject.unsubscribe();
      this.HubConnectionStatusSubject.unsubscribe();
      this.IsNetworkStatusOnlineSubject.unsubscribe();
      this.IsDatagridShouldbeRefreshedSubject.unsubscribe();
      this.StepNumberCacheSubject.unsubscribe();
      this.UserConnectedSubject.unsubscribe();
      this.IsDataUpdatingSubject.unsubscribe();
   }
}