import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { StorageService } from '../../app/authentication/storage.service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take, timeout } from 'rxjs/operators';
import { SubjectService } from '../services/subject.service';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { EncryptionService } from '../services/encryption.service';
import { encryptedValue } from 'src/app/authentication/encryptedValue';

@Injectable()
export class JwtInterceptor implements HttpInterceptor, OnDestroy {
   [x: string]: any;

   private isNetworkStatusOnline: boolean = false;
   private isRefreshing = false;
   private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

   constructor(
      public storageService: StorageService,
      private subjectService: SubjectService,
      private authenticationService: AuthenticationService,
      private encryptionService: EncryptionService
   ) {
      this.subjectService.IsNetworkStatusOnlineSubject.subscribe(value => { this.isNetworkStatusOnline = value; });
   }

   public ngOnDestroy(): void {
   }

   public interceptResume(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<Object>> {
      const authToken = this.storageService.getTokenFromStorage();
      const userConnected = this.storageService.getUserConnected();

      let token = "";
      if (userConnected != null) {
         token = authToken.accessToken;
      }

      let delay = 9000;
      //FvwbController/Synchronize,SynchronizeMembers,SynchronizeSeniorMatchs,SynchronizeYoungMatchs
      //UserPublicController/SendVCardToUserAsync
      if (request.url.includes("Q201200001") ||
          request.url.includes("Q201200002") ||
          request.url.includes("Q201200003") ||
          request.url.includes("Q201200004") ||
          request.url.includes("Q202800013")) {
         delay = 240000;
      }

      return next.handle(this.addTokenHeader(request, token)).pipe(
         map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse && event.body !== null) {
            }
            return event;
         }),
         timeout(delay), // Set a timeout of 9 seconds
         catchError(error => {
            if (error.name === 'TimeoutError') {
               //if (this.signalRService != undefined) this.signalRService.closeHubConnection();
            } else {
               //if (this.signalRService != undefined) this.signalRService.closeHubConnection();
            }
            throw error;
         })
      );
   }

   public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<Object>> {
      
      //SystemController / GetDateServer
      if (request.url.includes("Q202200001")) {
         return next.handle(request);
      }

      if (request.url.includes("/assets/") ||
         request.url.includes("/appsettings/") ||
         request.url.includes("/Setting/") ||
         request.url.includes("/C2"))
      {
         return next.handle(this.addTokenHeader(request, '')).pipe(
            catchError(error => {
              // Handle error and decide whether to retry or propagate
              return throwError(error);
            })
          );
      }
      else {
         //If Data privacy IS NOT NULL
         if (!this.storageService.isDataPrivacyIsNull() && !this.subjectService.IsGlobalCacheInLoading) {
            //Check if the token exists
            if (this.storageService.getTokenFromStorage() != null) {
               //Check if the token is not expired
               var isTokenExpired = ((Date.now() - new Date(this.storageService.getTokenFromStorage()?.refreshTokenExpiryTime).getTime()) > 0);
               //Check if access token expired
               if (isTokenExpired && this.storageService.getTokenFromStorage() != null) {
                  return this.handleTokenRefresh(request, next);
               }
               else {
                  //Token is good, resume the process
                  return this.interceptResume(request, next);
               }
            }
            else {
               //The user is not logged !!!, DO nothing, resume the process without the token
               return this.interceptResume(request, next);
            }
         }
         //If Data privacy IS NULL
         else {
            return this.interceptResume(request, next);
         }
      }
   }

   private handleTokenRefresh(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      if (!this.isRefreshing) {
         this.isRefreshing = true;
         this.refreshTokenSubject.next(null);
         return this.authenticationService.refreshToken(this.storageService.getTokenFromStorage()).pipe(
            switchMap((newToken: encryptedValue) => {
               this.isRefreshing = false;
               this.refreshTokenSubject.next(newToken);
               this.storageService.saveToken(newToken);
               return next.handle(this.addTokenHeader(request, this.storageService.getTokenFromStorage().accessToken));
            })
            ,
            catchError((error) => {
               this.isRefreshing = false;
               return throwError(error);
            })
         );
      } else {
         return this.refreshTokenSubject.pipe(
            filter(token => token != null),
            take(1),
            switchMap(() => {
               return next.handle(this.addTokenHeader(request, this.storageService.getTokenFromStorage().accessToken));
            })
         );
      }
   }

   private addTokenHeader(request: HttpRequest<any>, accessToken: string) {
      if (request.url.includes('Q202200002'))
      {  
         return request.clone({
            setHeaders: {
               'comment': this.encryptionService.getEncryptX(true, this.storageService.getUserConnected()?.sysId.toString())
            }
         });
      }

      if (accessToken == "" || request.url.includes('/C20'))
      {  
         return request.clone({
            setHeaders: {
               'Content-Type': 'application/json',
               'comment': this.encryptionService.getEncryptX(true, this.storageService.getUserConnected()?.sysId.toString())
            }
         });
      }
      else
      {
         return request.clone({
            setHeaders: {
               Authorization: `Bearer ${accessToken}`,
               'Content-Type': 'application/json',
               'comment': this.encryptionService.getEncryptX(true, this.storageService.getUserConnected()?.sysId.toString())
            }
         });
      }
   }
}