import Dexie, { Table } from 'dexie';
import { sportsClub } from '../models/sportsClub';
import { championship } from '../models/championship';
import { lastUpdates } from 'src/app/models/lastupdates/lastUpdates';
import { workgroup } from 'src/app/models/workgroup/workgroup';
import { setting } from '../models/setting';
import { homeNews } from '../models/homeNews';
import { team } from '../models/team';
import { sponsor } from '../models/sponsor';
import { activity } from '../models/activity';
import { buildingTrainingClosingDay } from '../models/buildingTrainingClosingDay';
import { user } from 'src/app/models/user/user';
import { ranking } from '../models/ranking';
import { match } from '../models/match';
import { trainingPlayer } from '../models/trainingPlayer';
import { comiteeMeeting } from '../models/comiteeMeeting';
import { strings } from '../models/strings';
import { applyEncryptionMiddleware } from "dexie-encrypted";
import { cryptoOptions } from "dexie-encrypted";
import { clearAllTables } from "dexie-encrypted";
import { CryptoSettings } from 'dexie-encrypted/dist/types';

const nacl = require("tweetnacl");
const keyPair = nacl.sign.keyPair.fromSeed(new Uint8Array(32));

export class AppDB extends Dexie {
  sportsClubDB!: Table<sportsClub, number>;
  todoDB!: Table<strings, number>;
  versionningDB!: Table<strings, number>;
  lastUpdatesDB!: Table<lastUpdates, number>;
  championshipsDB!: Table<championship, number>;
  workgroupsDB!: Table<workgroup, number>;
  settingsDB!: Table<setting, number>;
  buildingTrainingClosingDayDB!: Table<buildingTrainingClosingDay, number>;
  homeNewsDB!: Table<homeNews, number>;
  teamsDB!: Table<team, number>;
  teamsOfPCDB!: Table<team, number>;
  sponsorsDB!: Table<sponsor, number>;
  activitiesDB!: Table<activity, number>;
  usersDB!: Table<user, number>;
  rankingsDB!: Table<ranking, number>;
  matchsDB!: Table<match, number>;
  usersOfPCDB!: Table<user, number>;
  trainingPlayersDB!: Table<trainingPlayer, number>;
  comiteeMeetingsDB!: Table<comiteeMeeting, number>;

  constructor() {
    super('Vabourlettis');

    if (localStorage.getItem("dataprivacy") != null && localStorage.getItem("dataprivacy") == "ACCEPTED") {
      this.initializeDB();
      this.open();
    }
  }

  initializeDB() {
    /*
         https://github.com/mark43/dexie-encrypted
   
         cryptoOptions.NON_INDEXED_FIELDS - all data other than indices will be encrypted.
         cryptoOptions.UNENCRYPTED_LIST - all data other than indices and listed fields will be encrypted.
         cryptoOptions.ENCRYPT_LIST - listed fields will be encrypted.
   
         tableDB: {
           type: cryptoOptions.ENCRYPT_LIST,
           fields: ['property1', 'property2']
         },
       */

    let param: CryptoSettings<AppDB> = {
      sportsClubDB: cryptoOptions.NON_INDEXED_FIELDS,
      todoDB: cryptoOptions.NON_INDEXED_FIELDS,
      versionningDB: cryptoOptions.NON_INDEXED_FIELDS,
      lastUpdatesDB: cryptoOptions.NON_INDEXED_FIELDS,
      championshipsDB: cryptoOptions.NON_INDEXED_FIELDS,
      workgroupsDB: cryptoOptions.NON_INDEXED_FIELDS,
      settingsDB: cryptoOptions.NON_INDEXED_FIELDS,
      buildingTrainingClosingDayDB: cryptoOptions.NON_INDEXED_FIELDS,
      homeNewsDB: cryptoOptions.NON_INDEXED_FIELDS,
      teamsDB: cryptoOptions.NON_INDEXED_FIELDS,
      teamsOfPCDB: cryptoOptions.NON_INDEXED_FIELDS,
      sponsorsDB: cryptoOptions.NON_INDEXED_FIELDS,
      activitiesDB: cryptoOptions.NON_INDEXED_FIELDS,
      usersDB: cryptoOptions.NON_INDEXED_FIELDS,
      rankingsDB: cryptoOptions.NON_INDEXED_FIELDS,
      matchsDB: cryptoOptions.NON_INDEXED_FIELDS,
      usersOfPCDB: cryptoOptions.NON_INDEXED_FIELDS,
      trainingPlayersDB: cryptoOptions.NON_INDEXED_FIELDS,
      comiteeMeetingsDB: cryptoOptions.NON_INDEXED_FIELDS
    }

    applyEncryptionMiddleware<AppDB>(
      this,
      keyPair.publicKey,
      param,
      clearAllTables
    );

    this.version(1).stores({
      sportsClubDB: 'sysId',
      versionningDB: '++id',
      todoDB: '++id',
      lastUpdatesDB: 'sysId',
      championshipsDB: 'sysId',
      workgroupsDB: 'sysId',
      settingsDB: 'sysId',
      buildingTrainingClosingDayDB: 'sysId',
      homeNewsDB: 'sysId',
      teamsDB: 'sysId',
      teamsOfPCDB: 'sysId',
      sponsorsDB: 'sysId',
      activitiesDB: 'sysId',
      usersDB: 'sysId',
      rankingsDB: 'sysId',
      matchsDB: 'sysId',
      usersOfPCDB: 'sysId',
      trainingPlayersDB: 'sysId',
      comiteeMeetingsDB: 'sysId'
    });
  }


  async isDatabaseExist(): Promise<boolean> {
    try {
      await db.open();
      await db.close();
      return true;
    } catch (error) {
      if (error.name === 'NoSuchDatabaseError') {
        // Database does not exist
        return false;
      } else {
        // Other errors, e.g., IndexedDB not supported
        console.error('Unexpected error when checking database existence:', error);
        throw error;
      }
    }
  }

}

export const db = new AppDB();