import { Component, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import 'linq-typed';
import { team } from 'src/app/models/team';
import { CacheService } from 'src/app/services/cache.service';
import { enumDatagridHeaderOrder } from "../../enum/Enum";
import { datagridFilterDetail } from '../../models/system/datagridFilter';
import { datagridParam } from '../../models/system/datagridParam';
import { user } from "../../models/user/user";
import { FrameworkService } from '../../services/framework.service';
import { SubjectService } from '../../services/subject.service';

type AOA = any[][];

@Component({
   selector: 'app-adminUser-list',
   templateUrl: './adminUser-list.component.html',
   styleUrls: ['./adminUser-list.component.scss']
})
export class AdminUserListComponent implements OnInit {
   public allTeamOfCurrentChampionship: team[];
   public allUsersCurrentCS;
   //public allUsersPreviousCS;
   public championshipNameDisplay: string = "";
   public currentChampionshipSysId = 0;
   public data_affiliation: AOA;
   public data_dirigeants: AOA;
   public data_encadrants: AOA;
   public data_joueurs: AOA;
   public data_licences: AOA;
   public data_salles: AOA;
   public datagridParam: datagridParam<user>;
   public dialog: any;
   public exportFilesFromFvwbPortal: string = "";
   public importChampionshipDisplay: string = "";
   public importDataDescriptionDisplay: string = "";
   public importDataTitleDisplay: string = "";
   public isLoading: boolean = false;
   public previousChampionshipSysId = 0;

   constructor(
      private cacheService: CacheService,
      private subjectService: SubjectService,
      private frameworkService: FrameworkService,
      private translateService: TranslateService) {
      this.subjectService.IsDatagridShouldbeRefreshedSubject.subscribe(r => {
         if (this.isLoading) this.refresh();
      });
   }

   public getDatagridFilters($event) {
      this.subjectService.IsDatagridShouldbeRefreshedSubject.next(true);
   }

   public initialize() {
      this.translatePage();
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
         this.translatePage();
      });

      this.refresh();
   }

   public ngOnInit() {
   }

   public onFileChange(evt: any) {
   }

   public refresh() {
      this.isLoading = false;

      this.allTeamOfCurrentChampionship = this.cacheService.getAllTeamsWithoutPicture().filter(i => i.championship.isCurrent);

      let currentChampionship = this.cacheService.getAllChampionships().FirstOrDefault(i => i.isCurrent);

      this.currentChampionshipSysId = currentChampionship?.sysId;
      let previousChampionship = this.cacheService.getAllChampionships().FirstOrDefault(i => i.yearStarting == (Number(currentChampionship.yearStarting) - 1).toString());
      if (previousChampionship != null)
         this.previousChampionshipSysId = previousChampionship?.sysId;
      else
         this.previousChampionshipSysId = 0;
      this.importChampionshipDisplay = this.frameworkService.format("{0}-{1}", "yearStarting;yearEnding", currentChampionship);

      // List of all users of the current championship
      this.allUsersCurrentCS = this.cacheService.getAllUsers();
      // List of all users of the previous championship
      //this.allUsersPreviousCS = this.cacheService.getAllUsersOfPC();

      //-> Set filter(s)
      let filterDetail = new datagridFilterDetail();
      filterDetail.dropdownSelectedValueOfLists = currentChampionship?.sysId;
      filterDetail.dropdownLists = this.cacheService.getAllChampionships().map(x => (
         {
            value: x.sysId,
            viewValue: this.frameworkService.format("{0}-{1}", "yearStarting;yearEnding", x)
         }));
      filterDetail.labelText = this.championshipNameDisplay + " : ";
      filterDetail.name = "championship";
      filterDetail.idName = "team.championship.sysId";

      let datagridFilters = new Map<string, datagridFilterDetail>();
      datagridFilters.set(filterDetail.name, filterDetail);
      //<- Set filter(s)

      // Display the datagrid
      this.datagridParam = new datagridParam(
         {
            hidden: !this.isLoading,
            dataSource: this.cacheService.getAllUsers(),
            filters: null, //filters
            mainTitle: "AdminUsersListTitle",
            headerOrder: enumDatagridHeaderOrder.ApiByDefault,
            entity: new user(),
            urlApi: "C1028",
            paginatorItemsCount: this.frameworkService.getPaginatorItemsCount(),
            formTitle: "UserTitle"
         });
      this.isLoading = true;

      //});
   }

   public translatePage() {
      //ExportFiles2FromFvwbPortal
      this.translateService.get(['ExportFiles2FromFvwbPortal'])
         .subscribe(translations => {
            this.exportFilesFromFvwbPortal = translations['ExportFiles2FromFvwbPortal'];
         });

      //Championship
      this.translateService.get(['Championship'])
         .subscribe(translations => {
            this.championshipNameDisplay = translations['Championship'];
         });

      //ImportDataTitleDisplay
      this.translateService.get(['ImportDataTitleDisplay'])
         .subscribe(translations => {
            this.importDataTitleDisplay = translations['ImportDataTitleDisplay'];
         });

      //ImportData1DescriptionDisplay
      this.translateService.get(['ImportData1DescriptionDisplay'])
         .subscribe(translations => {
            this.importDataDescriptionDisplay = translations['ImportData1DescriptionDisplay'];
         });
   }

   // export(): void {
   //  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };

   //   /* generate worksheet */
   //   const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data);

   //   /* generate workbook and add the worksheet */
   //   const wb: XLSX.WorkBook = XLSX.utils.book_new();
   //   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

   //   /* save to file */
   //   XLSX.writeFile(wb, this.fileName);
   // }
}
