import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FrameworkService } from 'src/app/services/framework.service';
import { StorageService } from 'src/app/authentication/storage.service';
import { DataPrivacyService } from 'src/app/services/dataPrivacyService';

@Component({
   selector: 'app-dataPrivacyDialog',
   templateUrl: './dataPrivacyDialog.component.html',
   styleUrls: ['./dataPrivacyDialog.component.scss'],
   encapsulation: ViewEncapsulation.None
})
export class DataPrivacyDialogComponent implements OnInit {
   public dataPrivacy: string;
   public isAccepted: boolean;
   public isAcceptedIsnull: boolean;
   public title: string;

   //isNetworkStatusOnline: boolean = false;
   constructor(
      public dialogRef: MatDialogRef<DataPrivacyDialogComponent>,
      private frameworkService: FrameworkService,
      private dataPrivacyService: DataPrivacyService,
      public storageService: StorageService,
      @Inject(MAT_DIALOG_DATA) public data: DataPrivacyDialogModel) {
      this.title = data.title;
   }

   public ngOnInit() {
      this.isAccepted = this.storageService.isDataPrivacyAccepted();
      this.isAcceptedIsnull = this.storageService.isDataPrivacyIsNull();
   }

   public onConfirm(): void {
      // Close the dialog, return true
      this.dialogRef.close(true);
   }

   public onDismiss(): void {
      // Close the dialog, return false
      this.dialogRef.close(false);
   }
}

export class DataPrivacyDialogModel {
   constructor(public title: string) {
   }
}