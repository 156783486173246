//This file is autogenerated by C# GeneratedServices/Angular/ServicesAngularGenerationTool

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, retry, throwError } from 'rxjs';
import { AccessRightsService } from 'src/app/services/accessRights.service';
import { FrameworkService } from 'src/app/services/framework.service';
import { LogService } from 'src/app/services/log.service';
import { AppConfigService } from 'src/app/services/appconfig.service';
import { ActivityByMemberBaseService } from './activityByMemberBase.service';
import { StorageService } from '../authentication/storage.service';
import { EncryptionService } from 'src/app/services/encryption.service';
import { activityByMember } from '../models/activityByMember';
import { queryFilter } from '../models/queryFilter';
import { jsonIgnoreReplacer } from 'json-ignore';
import { enumQueryFilterOp } from 'src/app/enum/Enum';
import { CacheService } from './cache.service';
import { encryptedValue } from '../authentication/encryptedValue';
import { SubjectService } from 'src/app/services/subject.service';

@Injectable()
export class ActivityByMemberService extends ActivityByMemberBaseService {
   private _logger: LogService;
   private _appConfigService: AppConfigService;
   private _httpClient: HttpClient;
   private _frameworkService: FrameworkService;
   private _accessRightsService: AccessRightsService;
   private _encryptionService: EncryptionService;
   private _tokenStorageService: StorageService;
   private _subjectService: SubjectService;

   username = "UNKNOWN";

   constructor(
      appConfigService: AppConfigService,
      logger: LogService,
      httpClient: HttpClient,
      frameworkService: FrameworkService,
      accessRightsService: AccessRightsService,
      encryptionService: EncryptionService,
      storageService: StorageService,
      subjectService: SubjectService
   ) {
      super(appConfigService, logger, httpClient, frameworkService, accessRightsService, encryptionService, storageService);
      this._appConfigService = appConfigService;
      this._logger = logger;
      this._httpClient = httpClient;
      this._frameworkService = frameworkService;
      this._accessRightsService = accessRightsService;
      this._encryptionService = encryptionService;
      this._tokenStorageService = storageService;
      this._subjectService = subjectService;
      this.username = !this._accessRightsService.isUserConnected ? "UNKNOWN" : this._tokenStorageService.getUserConnected().username;
   }


   convertQueryControllerToPublicFilter(activity: any): queryFilter[] {
      let userLogged = this._tokenStorageService.getUserConnected();

      const queryFilters = [];
      queryFilters.push(new queryFilter({ propertyName: "ActivitySysId", operation: enumQueryFilterOp.Equals, value: activity.sysId }));
      queryFilters.push(new queryFilter({ propertyName: "UserSysId", operation: enumQueryFilterOp.Equals, value: userLogged.sysId }));
      return queryFilters;
   }

   insertOrUpdate(activityByMemberToUpdate: activityByMember, isToBeAdded: boolean) {
      //console.log("TRACE -> insertOrUpdate");

      let encryptedData = new encryptedValue();
      encryptedData.value = this._encryptionService.encryptObject(true, false, activityByMemberToUpdate);

      const username = !this._accessRightsService.isUserConnected ? "UNKNOWN" : this._tokenStorageService.getUserConnected().username;
      let url = "/Q100000002?username=" + username + "&isToBeAdded=" + isToBeAdded;
      return this._httpClient.put<encryptedValue>(this._appConfigService.getApiUrl() + 'api/C1000' + url, JSON.parse(JSON.stringify(encryptedData, jsonIgnoreReplacer)))
         .pipe(retry(1), catchError(this.handleError));
   }

   // Error handling 
   handleError(error) {
      let errorMessage = '';
      if (error.error instanceof ErrorEvent) {
         // Get client-side error
         errorMessage = error.error.message;
      } else {
         // Get server-side error
               if (error.status == 401)
        errorMessage = "Vous n'êtes pas autorisé à effectuer cette opération.";
      else
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      return throwError(() => errorMessage);
   }

   reportByEmail(activitySysId: Number, userToNotifySysId: Number) {
      //console.log("TRACE -> reportByEmail");

      let encryptedData = new encryptedValue();
      encryptedData.value = this._encryptionService.encryptObject(true, false, activitySysId);

      let url = "/Q100000001?userToNotifySysId=" + userToNotifySysId;
      return this._httpClient.post<encryptedValue>(this._appConfigService.getApiUrl() + 'api/C1000' + url, JSON.parse(JSON.stringify(encryptedData, jsonIgnoreReplacer)))
         .pipe(retry(1), catchError(this.handleError));
   }

   async sendReportByEmail(activitySysId: Number, userToNotifySysId: Number) {
      this.reportByEmail(activitySysId, userToNotifySysId).subscribe({
         next: data => {
            let dataDecrypted = this._encryptionService.decryptObject(false, (data as encryptedValue).value);

            if (dataDecrypted != null) {
               if (dataDecrypted == 1)
                  this._frameworkService.displayAlertSendReportSupper(true);
               else
                  this._frameworkService.displayAlertSendReportSupper();
            }
            else
               this._frameworkService.displayAlertSendReportSupper(false);
         },
         error: err => {
            this._frameworkService.displayAlertSendReportSupper(null, err);
         }
      });
   }

   async saveReservationOfActivity(activityByMember: any, activity: any, seniorCount: number, childCount: number, childUnderAgeCount: number, particularDemand: string, isPresent: boolean) {
      let userLogged = this._tokenStorageService.getUserConnected();
      activityByMember.personSeniorCount = seniorCount;
      activityByMember.personChildCount = childCount;
      activityByMember.personChildUnderAgeCount = childUnderAgeCount;
      activityByMember.particularDemand = particularDemand;
      activityByMember.activitySysId = activity.sysId;
      activityByMember.userSysId = userLogged.sysId;
      activityByMember.isPresent = isPresent ? 1 : 0;

      this.insertOrUpdate(activityByMember, true).subscribe({
         next: data => {
            let dataDecrypted = this._encryptionService.decryptObject(false, (data as encryptedValue).value);

            if (dataDecrypted != null) {
               this._subjectService.ActivityByMemberInsertedOrUpdatedSubject.next(dataDecrypted as activityByMember);
               if (isPresent)
                  this._frameworkService.displayAlertReservation(true);
               else
                  this._frameworkService.displayAlertNoReservation();
            }
            else
               this._frameworkService.displayAlertReservation(false);
         },
         error: err => {
            this._frameworkService.displayAlertReservation(null, err);
         }
      });
   }
}