import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { StorageService } from 'src/app/authentication/storage.service';
import { auth } from '../models/auth/auth';

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {
  private iv = "";
  private key = "";

  public datePipe: DatePipe;

  constructor (datePipeService: DatePipe, private storageService: StorageService)
  {
    this.datePipe = datePipeService;
  }

  setKeysCondition(isForToken: boolean)
  {
      if (isForToken)
          this.setKeysForToken();
      else
          this.setKeys();
  }

  setKeys() {
    let currentDate = new Date();
    let mmTemp = "";
    let mm = Number(this.datePipe.transform(currentDate, 'mm'));

    if (mm >= 1 && mm <= 10)
    mmTemp = "00";
    if (mm >= 11 && mm <= 20)
    mmTemp = "01";
    if (mm >= 21 && mm <= 30)
    mmTemp = "02";
    if (mm >= 31 && mm <= 40)
    mmTemp = "03";
    if (mm >= 41 && mm <= 50)
    mmTemp = "04";
    if (mm >= 51 || mm == 0)
    mmTemp = "05";

    const formatter = new Intl.DateTimeFormat('en-BE', {
    timeZone: 'Europe/Brussels',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
    });

    const formattedParts = formatter.formatToParts(currentDate);
    const day = formattedParts.find(part => part.type === 'day')?.value;
    const month = formattedParts.find(part => part.type === 'month')?.value;
    const year = formattedParts.find(part => part.type === 'year')?.value;
    const hour = formattedParts.find(part => part.type === 'hour')?.value;
    // const minute = formattedParts.find(part => part.type === 'minute')?.value;
    // const second = formattedParts.find(part => part.type === 'second')?.value;

    let dateKey = year + month + day + hour + mmTemp;
    let dateIV = day + month + year + hour + mmTemp;
    this.key = CryptoJS.enc.Utf8.parse(CryptoJS.MD5(dateKey).toString().replace("-", "").toUpperCase());
    this.iv = CryptoJS.enc.Utf8.parse(CryptoJS.MD5(dateIV).toString().substring(0, 16).replace("-", "").toUpperCase());
    //console.log("mm : " + mm + " - Key : " + dateKey + " - IV : " + dateIV);
  }

  setKeysForToken() {
    let dateKey = "0"+"1"+"0"+"1"+"2"+"0"+"2"+"5"+"0"+"1";
    let dateIV = "0"+"2"+"2"+"0"+"2"+"5"+"0"+"1"+"0"+"1";
    this.key = CryptoJS.enc.Utf8.parse(CryptoJS.MD5(dateKey).toString().replace("-", "").toUpperCase());
    this.iv = CryptoJS.enc.Utf8.parse(CryptoJS.MD5(dateIV).toString().substring(0, 16).replace("-", "").toUpperCase()); 
  }

  public encrypt(isForToken: boolean, text: string): string {
    this.setKeysCondition(isForToken);
    
    const ciphertext = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), this.key, {
      iv: this.iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    if (ciphertext == undefined)
      return "";

    return ciphertext.toString();
  }

  public decrypt(isForToken: boolean, ciphertext: string): string {
    try {
      this.setKeysCondition(isForToken);

      if (ciphertext == null)
        return "";

      const decrypted = CryptoJS.AES.decrypt(ciphertext, this.key, {
        iv: this.iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
      return decrypted.toString(CryptoJS.enc.Utf8);
    }
    catch(error) {
      //console.log("-------------> ERROR decrypt : " + error); 
    }
  }

  public decryptAngularObject(isForToken: boolean, encryptedData: string): any {
    this.setKeysCondition(isForToken);
    const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, this.key, { iv: this.iv });
    const decryptedObject = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
    return decryptedObject;
  }

  public decryptObject(isForToken: boolean, encryptedData: string): any {
    try {
      this.setKeysCondition(isForToken);
      const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, this.key, { iv: this.iv });
      const decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);
      const decryptedObject = JSON.parse(decryptedText, this.dateReviver);
      return decryptedObject;
    }
    catch(error)
    {
        //console.log("-------------> ERROR decryptObject : " + error); 
    }
  }

  public encryptObjectForAngular(isForToken: boolean, obj: any): string {
    this.setKeysCondition(isForToken);
    const jsonString = JSON.stringify(obj);
    const encrypted = CryptoJS.AES.encrypt(jsonString, this.key, {
      iv: this.iv
    });
    return encrypted.toString();
  }

  public encryptObjectForWebApi(isForToken: boolean, obj: any): string {
    this.setKeysCondition(isForToken);

    const replacer = (_: any, value: any) => {
      if (value !== null && typeof value === 'object') {
        if (value !== null && value instanceof Date) {
          return this.datePipe.transform(value, "yyyy-MM-ddTHH:mm:ss.SSSZ");
        }
        else {
          return value;
        }
      } else {
        // If the value is an object, keep it unchanged
        return value;
      }
    };

    const jsonString = JSON.stringify(obj, replacer);
    const encrypted = CryptoJS.AES.encrypt(jsonString, this.key, {
      iv: this.iv
    });

    return encrypted.toString();
  }

  public encryptObject(isForWebApi: boolean, isForToken: boolean, obj: any): string {
    this.setKeysCondition(isForToken);

    if (isForWebApi)
      return this.encryptObjectForWebApi(isForToken, obj);
    else
      return this.encryptObjectForAngular(isForToken, obj);
  }

  public getEncryptX(isForToken: boolean, userSysId: string) {
   const currentDateTime = new Date();
   return this.encrypt(isForToken, this.datePipe.transform(currentDateTime, "yyyy-MM-ddTHH:mm:ss.SSSZ") + "###" + userSysId);
  }

  private dateReviver(key: any, value: any) {
    if (typeof value === 'string' && /\/Date\(\d+\+\d+\)\//.test(value)) {
      return new Date(parseInt(value.substring(0, 6), 10));
    }
    return value;
  }
}
