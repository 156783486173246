import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { CacheService } from 'src/app/services/cache.service';
import { AccountService } from "../../services/account.service";
import { AlertShowService } from "../../services/alertShow.service";
import { AppConfigService } from '../../services/appconfig.service';
import { encryptedValue } from 'src/app/authentication/encryptedValue';
import { EncryptionService } from '../../services/encryption.service';
import { FrameworkService } from '../../services/framework.service';
import { SubjectService } from '../../services/subject.service';

@Component({
   selector: 'app-recoverPassword',
   templateUrl: './recoverPassword.component.html',
   styleUrls: ['./recoverPassword.component.scss']
})

export class RecoverPasswordComponent implements OnInit {
   public form: FormGroup;
   public isNetworkStatusOnline: boolean = false;
   public submitted: boolean = false;

   constructor(
      private subjectService: SubjectService,
      private translateService: TranslateService,
      private formBuilder: FormBuilder,
      private router: Router,
      private accountService: AccountService,
      private alertShowService: AlertShowService,
      private appConfigService: AppConfigService,
      private encryptionService: EncryptionService,
      private frameworkService: FrameworkService
   ) {
      this.subjectService.IsNetworkStatusOnlineSubject.subscribe(value => { this.isNetworkStatusOnline = value; });
   }

   public ngOnInit() {
      this.form = this.formBuilder.group({
         username: ['', Validators.required]
      });

      this.translatePage();
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
         this.translatePage();
      });
   }

   public onRecoverPasswordCommand() {
      this.submitted = true;
      // reset alerts on submit
      this.alertShowService.clear();

      // stop here if form is invalid
      if (this.form.invalid) {
         this.subjectService.IsButtonInLoadingSubject.next(false);
         return;
      }

      this.accountService.sendAccountSecurityCode(this.form.controls.username.value, this.siteMail()).subscribe(
         {
            next: data => {
               let dataDecrypted = this.encryptionService.decryptObject(false, (data as encryptedValue).value);

               if (dataDecrypted != null) {
                  this.router.navigate(['/changePassword'], { queryParams: { username: dataDecrypted.username } });
               }
               else {
                  this.alertShowService.error(this.translateService.instant('SendMailFailed'));
               }
               this.subjectService.IsButtonInLoadingSubject.next(false);
            },
            error: err => {
               this.alertShowService.error(err.mes);
               this.subjectService.IsButtonInLoadingSubject.next(false);
            }
         });
   }

   public siteMail() {
      return this.appConfigService.getMail();
   }

   public translatePage() {
   }
}