//This file is autogenerated by C# GeneratedModels

import { auditFields } from "src/app/models/system/auditFields";
import { metadataModelBase } from "src/app/models/system/metadataModelBase";
import { championship } from "./championship";

@Reflect.metadata('blobBase', 'blobBase')
export class blobBase extends auditFields {

 @Reflect.metadata('type',
  <metadataModelBase> <unknown> {
    editVariableValidator: {
    } 
  })
  type : number;

  @Reflect.metadata('title',
  <metadataModelBase> <unknown> {
    editVariableValidator: {
    } 
  })
  title : string = "";

 @Reflect.metadata('uri',
  <metadataModelBase> <unknown> {
    editVariableValidator: {
    } 
  })
  uri : string = "";

 @Reflect.metadata('content',
  <metadataModelBase> <unknown> {
    editVariableValidator: {
    } 
  })
  content : string = "";

 @Reflect.metadata('championshipSysId',
  <metadataModelBase> <unknown> {
    editVariableValidator: {
    } 
  })
  championshipSysId? : number = null;
}