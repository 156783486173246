//This file is autogenerated by C# GeneratedServices/Angular/ServicesAngularGenerationTool

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from 'src/app/services/appconfig.service';
import { FormBaseService } from './formBase.service';
import { FrameworkService } from 'src/app/services/framework.service';

@Injectable()
export class FormService extends FormBaseService {

  constructor(
     httpClient: HttpClient,
     frameworkService: FrameworkService,
     appConfigService : AppConfigService
  ) {
     super(httpClient, frameworkService, appConfigService);
  }
}