//This file is autogenerated by C# GeneratedModels

import { auditFields } from "src/app/models/system/auditFields";
import { metadataModelBase } from "src/app/models/system/metadataModelBase";
import { enumControlType, enumFormValidator, enumWorkgroup } from "../../app/enum/Enum";
import { user } from "src/app/models/user/user";
import { championship } from "./championship";
import { jsonIgnore } from "json-ignore";

@Reflect.metadata('teamBase', 'teamBase')
export class teamBase extends auditFields {

 @Reflect.metadata('division',
  <metadataModelBase> <unknown> {
    editVariableValidator: {
    "MaxLength": "20"
    } ,
    grisIsInDetailExpanded: false
  })
  division : string = "";

 @Reflect.metadata('teamCategory',
  <metadataModelBase> <unknown> {
    editVariableValidator: {
    "MaxLength": "40"
    } 
  })
  teamCategory : string = "";

 @Reflect.metadata('teamDisplay',
  <metadataModelBase> <unknown> {
    editVariableValidator: {
    "MaxLength": "30"
    } ,
    grisIsInDetailExpanded: true
  })
  teamDisplay : string = "";

 @Reflect.metadata('teamFvwb',
  <metadataModelBase> <unknown> {
    editVariableValidator: {
    "MaxLength": "30"
    } ,
    grisIsInDetailExpanded: true
  })
  teamFvwb : string = "";

 @Reflect.metadata('picture',
  <metadataModelBase> <unknown> {
   editControlType: enumControlType.Picture,
    editVariableValidator: {
    } 
  })
  picture : string = "";

 @Reflect.metadata('facebookGroupLink',
  <metadataModelBase> <unknown> {
    editVariableValidator: {
    } ,
    grisIsInDetailExpanded: true
  })
  facebookGroupLink : string = "";

 @Reflect.metadata('trainingDay1',
  <metadataModelBase> <unknown> {
   editVariableValidator: {
   },
   editControlType: enumControlType.Dropdown,
   editDropdownStickyValues: [{ value: '-1', viewValue: '' },
   { value: '1', viewValue: 'Monday' },
   { value: '2', viewValue: 'Tuesday' },
   { value: '3', viewValue: 'Wednesday' },
   { value: '4', viewValue: 'Thursday' },
   { value: '5', viewValue: 'Friday' }],
   editDropdownIsTranslateForDisplay: true
  })
  trainingDay1 : number = 0;

 @Reflect.metadata('trainingTimeFrom1',
  <metadataModelBase> <unknown> {
    editVariableValidator: {
    "MaxLength": "5"
    } ,
    editControlType: enumControlType.TimeOnly
  })
  trainingTimeFrom1 : string = "";

 @Reflect.metadata('trainingTimeTo1',
  <metadataModelBase> <unknown> {
    editVariableValidator: {
    "MaxLength": "5"
    } ,
    editControlType: enumControlType.TimeOnly
  })
  trainingTimeTo1 : string = "";

 @Reflect.metadata('buildingTrainingName1',
  <metadataModelBase> <unknown> {
    editVariableValidator: {
    "MaxLength": "100"
    } 
  })
  buildingTrainingName1 : string = "";

 @Reflect.metadata('trainingDay2',
  <metadataModelBase> <unknown> {
   editVariableValidator: {
   },
   editControlType: enumControlType.Dropdown,
   editDropdownStickyValues: [{ value: '-1', viewValue: '' },
   { value: '1', viewValue: 'Monday' },
   { value: '2', viewValue: 'Tuesday' },
   { value: '3', viewValue: 'Wednesday' },
   { value: '4', viewValue: 'Thursday' },
   { value: '5', viewValue: 'Friday' }],
   editDropdownIsTranslateForDisplay: true
  })
  trainingDay2 : number = 0;

 @Reflect.metadata('trainingTimeFrom2',
  <metadataModelBase> <unknown> {
    editVariableValidator: {
    "MaxLength": "5"
    } ,
    editControlType: enumControlType.TimeOnly
  })
  trainingTimeFrom2 : string = "";

 @Reflect.metadata('trainingTimeTo2',
  <metadataModelBase> <unknown> {
    editVariableValidator: {
    "MaxLength": "5"
    },
    editControlType: enumControlType.TimeOnly
  })
  trainingTimeTo2 : string = "";

 @Reflect.metadata('buildingTrainingName2',
 <metadataModelBase><unknown>{
   editVariableValidator: {
      "MaxLength": "100"
   }
})
  buildingTrainingName2 : string = "";

 @Reflect.metadata('trainingDay3',
  <metadataModelBase> <unknown> {
   editVariableValidator: {
   },
   editControlType: enumControlType.Dropdown,
   editDropdownStickyValues: [{ value: '-1', viewValue: '' },
   { value: '1', viewValue: 'Monday' },
   { value: '2', viewValue: 'Tuesday' },
   { value: '3', viewValue: 'Wednesday' },
   { value: '4', viewValue: 'Thursday' },
   { value: '5', viewValue: 'Friday' }],
   editDropdownIsTranslateForDisplay: true ,
   grisIsInDetailExpanded: true
  })
  trainingDay3 : number = 0;

 @Reflect.metadata('trainingTimeFrom3',
  <metadataModelBase> <unknown> {
    editVariableValidator: {
    "MaxLength": "5"
    },
    editControlType: enumControlType.TimeOnly ,
    grisIsInDetailExpanded: true
  })
  trainingTimeFrom3 : string = "";

 @Reflect.metadata('trainingTimeTo3',
  <metadataModelBase> <unknown> {
    editVariableValidator: {
    "MaxLength": "5"
    },
    editControlType: enumControlType.TimeOnly ,
    grisIsInDetailExpanded: true
  })
  trainingTimeTo3 : string = "";

  @Reflect.metadata('buildingTrainingName3',
  <metadataModelBase><unknown>{
     editVariableValidator: {
        "MaxLength": "100"
     } ,
     grisIsInDetailExpanded: true
  })
buildingTrainingName3: string = "";

 @Reflect.metadata('startOfTrainingDate',
  <metadataModelBase> <unknown> {
    editControlType: enumControlType.DateOnly,
    editVariableValidator: {
    } 
  })
  startOfTrainingDate? : Date = null;

 @Reflect.metadata('endOfTrainingDate',
  <metadataModelBase> <unknown> {
    editControlType: enumControlType.DateOnly,
    editVariableValidator: {
    } 
  })
  endOfTrainingDate? : Date = null;



 @Reflect.metadata('class',
  <metadataModelBase> <unknown> {
    editVariableValidator: {
    "MaxLength": "100"
    } ,
    grisIsInDetailExpanded: true
  })
  class : string = "";

 @Reflect.metadata('isRankingAvailable',
  <metadataModelBase> <unknown> {
    editControlType: enumControlType.Bool2States,
    editVariableValidator: {
    } ,
    grisIsInDetailExpanded: true
  })
  isRankingAvailable : boolean = false;

 @Reflect.metadata('hourPresenceBeforeAtHome',
  <metadataModelBase> <unknown> {
    editVariableValidator: {
    } ,
    grisIsInDetailExpanded: true
  })
  hourPresenceBeforeAtHome : number = 0;

 @Reflect.metadata('hourPresenceBeforeNotAtHome',
  <metadataModelBase> <unknown> {
    editControlType: enumControlType.Integer,
    editVariableValidator: {
    } ,
    grisIsInDetailExpanded: true
  })
  hourPresenceBeforeNotAtHome : number = 0;

 @Reflect.metadata('hourPresenceBeforeAtHome2',
  <metadataModelBase> <unknown> {
    editControlType: enumControlType.Integer,
    editVariableValidator: {
    } ,
    grisIsInDetailExpanded: true
  })
  hourPresenceBeforeAtHome2 : number = 0;

 @Reflect.metadata('hourPresenceBeforeNotAtHome2',
  <metadataModelBase> <unknown> {
    editControlType: enumControlType.Integer,
    editVariableValidator: {
    } ,
    grisIsInDetailExpanded: true
  })
  hourPresenceBeforeNotAtHome2 : number = 0;

  @Reflect.metadata('players',
  <metadataModelBase><unknown>{
  })
@jsonIgnore()
  players : user[];

  @Reflect.metadata('championshipSysId',
  <metadataModelBase><unknown>{
     editControlType: enumControlType.Dropdown,
     editDropdownEntityMapping: "championship",
     editDropdownPropertiesForDisplay: "yearStarting;yearEnding",
     editDropdownFormatForDisplay: "{0}-{1}"
  })
  championshipSysId : number = 0;

  @Reflect.metadata('championship',
  <metadataModelBase><unknown>{
     editColumnIsVisible: enumWorkgroup[enumWorkgroup.None],
     gridColumnIsVisible: enumWorkgroup[enumWorkgroup.None]
  })
@jsonIgnore()
championship: championship;
}