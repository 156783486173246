import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FrameworkService } from 'src/app/services/framework.service';
import { SubjectService } from 'src/app/services/subject.service';
import { CacheService } from 'src/app/services/cache.service';
import { GDPRService } from 'src/app/services/gDPRService';

@Component({
   selector: 'app-gDPRDialog',
   templateUrl: './gDPRDialog.component.html',
   styleUrls: ['./gDPRDialog.component.scss'],
   encapsulation: ViewEncapsulation.None
})
export class GDPRDialogComponent implements OnInit {
   public gDPR: string;
   public isNetworkStatusOnline: boolean = false;
   public title: string;

   constructor(public dialogRef: MatDialogRef<GDPRDialogComponent>,
      private subjectService: SubjectService,
      private gDPRService: GDPRService,
      private frameworkService: FrameworkService,
      @Inject(MAT_DIALOG_DATA) public data: GDPRDialogModel) {
      this.subjectService.IsNetworkStatusOnlineSubject.subscribe(value => { this.isNetworkStatusOnline = value; });
      this.title = data.title;
   }

   public ngOnInit() {
      this.gDPRService.getGDPR().subscribe(i => this.gDPR = i.toString());
   }

   public onClose(): void {
      this.dialogRef.close({ result: false });
      this.subjectService.IsButtonInLoadingSubject.next(false);
   }
}

export class GDPRDialogModel {
   constructor(public title: string) {
   }
}