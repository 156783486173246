import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AccessRightsService } from 'src/app/services/accessRights.service';
import { FrameworkService } from 'src/app/services/framework.service';
import { sportsClub } from '../../models/sportsClub';
import { CacheService } from '../../services/cache.service';
import { StorageService } from '../../authentication/storage.service';
import { SubjectService } from 'src/app/services/subject.service';
import { enumCacheStatusLoad } from 'src/app/enum/Enum';

@Component({
   selector: 'app-club',
   templateUrl: './club.component.html',
   styleUrls: ['./club.component.scss']
})
export class ClubComponent implements OnInit {
   public allRoles = [];
   public championshipFilterDisplay: string = "";
   public filter = [];
   public form: FormGroup = new FormGroup({});
   public isNotFirstTime: boolean = false;
   public sportclub: sportsClub;
   public titleDescriptionDisplay: string = "";

   constructor(
      private cacheService: CacheService,
      private frameworkService: FrameworkService,
      private translateService: TranslateService,
      private subjectService: SubjectService
   ) {
      this.form.addControl("allchampionship", new FormControl(''));
   }

   public ngOnInit() {
      this.subjectService.GlobalCacheLoadedStatusSubject.subscribe(value => { if (value != null && value >= enumCacheStatusLoad.loaded) this.initialize(); });
   }

   public getPhoneFormat(phone: string) {
      return this.frameworkService.phoneFormat(phone);
   }

   public initialize() {
      this.translatePage();
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
         this.translatePage();
      });

      this.sportclub = this.cacheService.getSportClub();
      this.refresh();
   }

   public onFilterChange(): void {
      this.refresh();
   }

   public refresh() {
      this.filter = this.cacheService.getAllChampionships().map(x => (
         {
            value: x.sysId,
            viewValue: this.frameworkService.format("{0}-{1}", "yearStarting;yearEnding", x)
         })
      );

      if (!this.isNotFirstTime) {
         this.form.get("allchampionship")?.setValue(this.cacheService.getCurrentChampionship()?.sysId);
         this.isNotFirstTime = true;
      }
   }

   public translatePage() {
      //Championship
      this.translateService.get(['Championship'])
         .subscribe(translations => {
            this.championshipFilterDisplay = translations['Championship'];
         });

      //SportsclubTitle
      this.translateService.get(['SportsclubTitle'])
         .subscribe(translations => {
            this.titleDescriptionDisplay = translations['SportsclubTitle'];
         });
   }
}
