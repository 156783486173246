import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatCalendar, MatCalendarCellClassFunction } from '@angular/material/datepicker';
import { eventClub } from 'src/app/models/eventClub';
import { CacheService } from 'src/app/services/cache.service';
import { enumAvailability, enumClubActivityType } from '../../enum/Enum';
import { AccessRightsService } from '../../services/accessRights.service';
import { FrameworkService } from '../../services/framework.service';
import { StorageService } from 'src/app/authentication/storage.service';
import { SubjectService } from '../../services/subject.service';

@Component({
   selector: 'mc',
   templateUrl: './matchCalendar.component.html',
   styleUrls: ['./matchCalendar.component.scss'],
   encapsulation: ViewEncapsulation.None
})
export class MatchCalendarComponent implements OnInit {
   @Input() public allClubEventsOfUserAuthenticated = new Map<string, eventClub>();
   @Input() public maxDate: string = "";
   @Input() public minDate: string = "";
   @Input() public selectedDate: Date;
   @Input() public visible: boolean = true;
   @Output() public onSelected = new EventEmitter<Date>();
   @ViewChild('calendar', { static: false }) public calendar: MatCalendar<Date>;

   // <-> Event to customize each date of the calendar
   public changeCalendarDate: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
      return this.getCalendarCellClass(cellDate);
   }

   public isCoachOrAssistantOrSupervisorConnected: boolean = false;
   public isDisplayOnAMobile: boolean = false;

   constructor(
      private cacheService: CacheService,
      private subjectService: SubjectService,
      private frameworkService: FrameworkService,
      public accessRightsService: AccessRightsService,
      public storageService: StorageService) {
   }

   public changeSelectedDate(date: Date) {
      this.selectedDate = date;
      this.calendar._goToDateInView(new Date(date), 'month');
      this.calendar.updateTodaysDate();
   }

   public getByeClass(match: any) {
      if (match?.fromTeam.includes(this.frameworkService.getByeStatus()) || match?.awayTeam.includes(this.frameworkService.getByeStatus()))
         return " bye";
      else
         return "";
   }

   public getCalendarCellClass(originalCellDate) {
      let cellDate = this.frameworkService.getDateFormatYyyyMmDd(originalCellDate);
      let cellClass = "";

      let clubActivitiesCalendar = this.frameworkService.findFirstEventClub(this.allClubEventsOfUserAuthenticated, cellDate)?.eventClub;
      if (this.isCoachOrAssistantOrSupervisorConnected || (cellDate >= this.frameworkService.getDateFormatYyyyMmDd(this.frameworkService.getToday()))) {
         if (clubActivitiesCalendar != undefined) {
            if (clubActivitiesCalendar.clubActivityType == enumClubActivityType.CLOSING)
               return 'closingDay';

            if (clubActivitiesCalendar.clubActivityType == enumClubActivityType.CHAMPIONSHIP ||
               clubActivitiesCalendar.clubActivityType == enumClubActivityType.HAINAUT_CUP ||
               clubActivitiesCalendar.clubActivityType == enumClubActivityType.FRIENDLY_MATCH_DAY)
               cellClass = "matchDay";
            if (clubActivitiesCalendar.clubActivityType == enumClubActivityType.TOURNAMENT_DAY)
               cellClass = "tournamentDay";
            if (clubActivitiesCalendar.clubActivityType == enumClubActivityType.TRAINING_DAY ||
               clubActivitiesCalendar.clubActivityType == enumClubActivityType.EXTRA_TRAINING_DAY)
               cellClass = "trainingDay";

            //this.frameworkService.logInfo(clubActivitiesCalendar, "clubActivitiesCalendar " + cellDate);

            if (clubActivitiesCalendar.availability == enumAvailability.GUARD) {
               cellClass += " guardPresentMember" + this.getByeClass(clubActivitiesCalendar.event);
            }
            else {
               if (clubActivitiesCalendar.availability == enumAvailability.PRESENT) {
                  cellClass += " presentMember" + this.getByeClass(clubActivitiesCalendar.event);
               }
               else {
                  cellClass += " notPresentMember" + this.getByeClass(clubActivitiesCalendar.event);
               }
            }

            //this.frameworkService.logInfo(cellClass, "cellClass " + cellDate);

            return cellClass;
         }
         else {
            // Check si c'est un jour d'entraînement
            if (this.cacheService.getAllTrainingDays().FirstOrDefault(i => i.day == new Date(originalCellDate).getDay()) != null) {
               cellClass = "trainingDay";
               let tp = this.cacheService.getAllTrainingPlayersOfCurrentLogged().FirstOrDefault(i => this.frameworkService.getDateFormatYyyyMmDd(originalCellDate) == this.frameworkService.getDateFormatYyyyMmDd(i.day));
               if (tp != null)
                  cellClass += (tp.present == null) ? " guardPresentMember" : (tp.present >= 1 ? " presentMember" : " notPresentMember");
               else
                  cellClass += " presentMember";
               return cellClass;
            }
         }
      }
      return this.isCoachOrAssistantOrSupervisorConnected ? "" : "noDay";
   }

   public getSelectedDate(): Date {
      return this.selectedDate;
   }

   public initialize() {
      this.isCoachOrAssistantOrSupervisorConnected = this.frameworkService.IsMemberCoachs(this.storageService.getUserConnected()) || this.accessRightsService.isUserConnectedAdministratorOrSupervisor();
   }

   public ngOnInit() {
      this.subjectService.IsWindowResizingSubject.subscribe(value => { this.isDisplayOnAMobile = value; });
      this.subjectService.IsTrainingPlayerCacheLoadedSubject.subscribe(value => {
      if (value) {
            if (this.calendar != undefined && this.selectedDate != null) {
               this.calendar._goToDateInView(new Date(this.selectedDate), 'month');
               this.calendar.updateTodaysDate();
            }
         }
      });

      this.initialize();
   }

   //Original event of calendar (selectedChange)
   public onSelectedChange(date) {
      this.changeSelectedDate(date);
      this.onSelected.emit(date);
   }
}
