import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import 'linq-typed';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/components/confirmDialog/confirmDialog.component';
import { user } from 'src/app/models/user/user';
import { AccessRightsService } from 'src/app/services/accessRights.service';
import { AccountService } from 'src/app/services/account.service';
import { AppConfigService } from 'src/app/services/appconfig.service';
import { FrameworkService } from 'src/app/services/framework.service';
import { CacheService } from '../../services/cache.service';
import { EncryptionService } from 'src/app/services/encryption.service';
import { encryptedValue } from '../../authentication/encryptedValue';
import { SubjectService } from 'src/app/services/subject.service';
import { MailService } from 'src/app/services/mail.service';
import { mailRequest } from 'src/app/models/mail/mailRequest';
import { enumCacheStatusLoad, enumDocumentType, enumPrivacyLevel, enumRoles } from 'src/app/enum/Enum';
import { Observable, catchError, forkJoin, throwError, timeout } from 'rxjs';
import { StorageService } from '../../authentication/storage.service';
import { DomSanitizer } from '@angular/platform-browser';
import { userDTO } from 'src/app/models/user/userDTO';
import { matchUDTO } from '../../models/matchUDTO';

@Component({
   selector: 'app-member',
   templateUrl: './member.component.html',
   styleUrls: ['./member.component.scss']
})

export class MemberComponent implements OnInit {
   public allMembersByAllTeam: user[] = [];
   public allMembersByFirstname: user[] = [];
   public allMembersByLastname: user[] = [];
   public allMemberWithoutPhoneNumber: user[] = [];
   public allMemberWithoutEmail: user[] = [];
   public allMemberWithoutPersonalPicture: user[] = [];
   public allMemberWithoutVabPicture: user[] = [];
   public allMemberWithoutLoginIn: user[] = [];
   public allMemberWithoutJacketNumber: user[] = [];
   public allMemberWithoutContribution: user[] = [];

   public allMembersByRole;
   public allMembersByTeam: user[] = [];
   public allMembersSelected: user[] = [];
   public allRolesOfMembers = [];
   public form: FormGroup = new FormGroup({});
   public isNetworkStatusOnline: boolean = false;
   public isNotFirstTime: boolean = false;
   public queryParams: any;
   public tabIndex: number;
   public titleDescriptionDisplay: string = "";

   public allTeamActiveCount = [];
   public allTeamInactiveCount = [];
   public allTeamInactiveLoginCount = [];

   public activesMembersCount = 0;
   public inactivesLoginMembersCount = 0;
   public inactivesMembersCount = 0;

   public isDisplayOnAMobile = false;

   constructor(
      private sanitizer: DomSanitizer,
      private appConfigService: AppConfigService,
      public dialog: MatDialog,
      private cacheService: CacheService,
      private subjectService: SubjectService,
      private frameworkService: FrameworkService,
      private translateService: TranslateService,
      private accountService: AccountService,
      private formBuilder: FormBuilder,
      private activeRouter: ActivatedRoute,
      private encryptionService: EncryptionService,
      private mailService: MailService,
      public accessRightsService: AccessRightsService,
      public storageService: StorageService,
      private router: Router) {
      this.subjectService.IsWindowResizingSubject.subscribe(value => { this.isDisplayOnAMobile = value; });
      this.form = this.formBuilder.group({
         allMembersSelectedArray: this.formBuilder.array([]),
         allMembersSelectedCheckbox: [false],
         withActiveMembersSelectedCheckbox: [false],
         subject: [""],
         message: [""],
         memberFilter: [""]
      });
   }

   public ngOnInit() {
      this.subjectService.GlobalCacheLoadedStatusSubject.subscribe(value => { if (value != null && value >= enumCacheStatusLoad.loaded) this.initialize(); });
      this.subjectService.IsNetworkStatusOnlineSubject.subscribe(value => { this.isNetworkStatusOnline = value; });
   }

   public initialize() {
      this.activeRouter.queryParams.subscribe(params => {
         this.queryParams = params;
         if (this.queryParams["tabType"] == undefined) {
         }
         else {
            this.tabIndex = Number(this.queryParams["tabType"]);
         }
      });



      this.translatePage();
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
         this.translatePage();
      });

      this.refresh();
   }

   public changeProfile(member, tabType) {
      this.router.navigate(['/profile'], { queryParams: { id: this.encryptionService.encrypt(true, member.sysId), tabType: tabType } });
   }

   public exportToExcel() {
      this.frameworkService.exportToExcel(this.cacheService.getAllMembersByTeam().map(item => 
         new userDTO(
               item.sysIsActive, 
               item.username, 
               item.firstName, 
               item.lastName,
               item.gender,
               item.birthdayDate,
               item.nationalNumber,
               item.nationality,
               item.address,
               item.zipCode,
               item.locality,
               item.email,
               item.phone1,
               item.phone2,
               item.category,
               item.licenceType,
               item.licenceNumber,
               item.team?.teamCategory,
               item.team2?.teamCategory,
               item.jacketNumber,
               item.jacketSize,
               item.shortsSize,
               item.roleInComity,
               item.roleInClub,
               item.roleInClubCustom,
               item.roleInTeam,
               item.contribution ,
               item.isContributionPayed,
               item.workgroupSysId,
               item.isUserLoginInactive,
               item.isNotificationByEmailActivate,
               item.isNotificationByPushActivate,
               item.isNotificationActivityReportByEmailActivate,
               item.isNotificationMatchByEmailActivate,
               item.isNotificationMatchOfClubByEmailActivate,
               item.isNotificationComiteeMeetingByEmailActivate,
               item.isNotificationAvailabilityByEmailActivate,
               item.isNotificationAvailabilityReportByEmailActivate,
               item.isNotificationAvailabilityForTeamByEmailActivate,
               item.emailPrivacyLevel,
               item.addressPrivacyLevel,
               item.phone1PrivacyLevel,
               item.phone2PrivacyLevel,
               item.birthdayDatePrivacyLevel
         )).sort((a, b) => a.sysIsActive + a.username > b.sysIsActive + b.username ? 1 : -1),"membres");
         this.subjectService.IsButtonInLoadingSubject.next(false);
   }


   public isATeamMemberAccessible(user: user): boolean {
      if (user.sysId == this.storageService.getUserConnected().sysId)
         return false;

      if (user.teamSysId == this.storageService.getUserConnected().teamSysId) {
         return (user.phone1PrivacyLevel >= enumPrivacyLevel.Team)
      }
      else {
         if (user.team2SysId != null && user.team2SysId == this.storageService.getUserConnected().teamSysId) {
            return (user.phone1PrivacyLevel >= enumPrivacyLevel.Team)
         }
         else {
            return (user.phone1PrivacyLevel >= enumPrivacyLevel.Club)
         }
      }
   }

   public getAllTeamsCount() {
      this.allTeamActiveCount = [];
      this.allTeamInactiveCount = [];
      this.allTeamInactiveLoginCount = [];
      let i = 0;
      let backupi = 0;
      let teamSysId = 0;

      this.allMembersByTeam = this.cacheService.getAllMembersByTeam();
      if (!this.accessRightsService.isUserConnectedAdministratorOrSupervisor() && !this.accessRightsService.isUserConnectedPresident() && !this.accessRightsService.isUserConnectedSecretary()) {
         this.allMembersByTeam = this.allMembersByTeam.filter(i => this.isATeamMemberAccessible(i));
      }

      this.allMembersByAllTeam.forEach(m => {
         if (this.getPreviousMemberByTeam(i) != m.team.teamCategory) {
            //Rupture -> changement d'équipe

            this.allTeamActiveCount[backupi] = this.allMembersByAllTeam.filter(u => !u.isUserLoginInactive && u.sysIsActive && u.teamSysId == teamSysId && (
               !u.roleInClubCustom?.includes(this.translateService.instant(enumRoles[enumRoles.TeamContact]))
            )).length;
            this.allTeamInactiveCount[backupi] = this.allMembersByAllTeam.filter(u => !u.sysIsActive && u.teamSysId == teamSysId && (
               !u.roleInClubCustom?.includes(this.translateService.instant(enumRoles[enumRoles.TeamContact]))
            )).length;
            this.allTeamInactiveLoginCount[backupi] = this.allMembersByAllTeam.filter(u => u.isUserLoginInactive && u.teamSysId == teamSysId && (
               !u.roleInClubCustom?.includes(this.translateService.instant(enumRoles[enumRoles.TeamContact]))
            )).length - this.allTeamInactiveCount[backupi];

            backupi = i;
         }
         else
            teamSysId = m.team.sysId;
         i++;
      });
      this.allTeamActiveCount[backupi] = this.allMembersByAllTeam.filter(u => !u.isUserLoginInactive && u.sysIsActive && u.teamSysId == teamSysId && (
         !u.roleInClubCustom?.includes(this.translateService.instant(enumRoles[enumRoles.TeamContact]))
      )).length;
      this.allTeamInactiveCount[backupi] = this.allMembersByAllTeam.filter(u => !u.sysIsActive && u.teamSysId == teamSysId && (
         !u.roleInClubCustom?.includes(this.translateService.instant(enumRoles[enumRoles.TeamContact]))
      )).length;
      this.allTeamInactiveLoginCount[backupi] = this.allMembersByAllTeam.filter(u => u.isUserLoginInactive && u.teamSysId == teamSysId && (
         !u.roleInClubCustom?.includes(this.translateService.instant(enumRoles[enumRoles.TeamContact]))
      )).length - this.allTeamInactiveCount[backupi];
   }


   public getAllUsersOfRole(role): [] {
      if (this.allMembersByRole != null) {
         return this.allMembersByRole.get(role);
      }
      return [];
   }

   public getIsInactiveClass(profile, alignRight): string {
      var result = profile.isUserLoginInactive ? "memberLoginInactive" : "";
      if (alignRight)
         result += !profile.sysIsActive ? " memberInactive fontSmaller alignmentRight" : " fontSmaller alignmentRight";
      else
         result += !profile.sysIsActive ? " memberInactive fontSmaller" : " fontSmaller";
      return result;
   }

   public getPreviousMember(i) {
      if (i - 1 < 0)
         return "";
      else {
         return this.allMembersByLastname[i - 1].lastName[0];
      }
   }

   public getPreviousMemberByAllTeam(i) {
      if (i - 1 < 0)
         return "";
      else {
         return this.allMembersByAllTeam[i - 1]?.team?.teamCategory;
      }
   }

   public getPreviousMemberByFirstname(i) {
      if (i - 1 < 0)
         return "";
      else {
         return this.allMembersByFirstname[i - 1]?.firstName[0];
      }
   }

   public getPreviousMemberByTeam(i) {
      if (i - 1 < 0)
         return "XXX";
      else {
         return this.allMembersByAllTeam[i - 1]?.team?.teamCategory;
      }
   }



   public isMemberCoachOnly(profile) {
      return this.accessRightsService.isMemberCoachOnly(profile);
   }

   public isUserRoleAssistantCoach(role) {
      return this.accessRightsService.isUserRoleAssistantCoach(role);
   }

   public isUserRoleCoach(role) {
      return this.accessRightsService.isUserRoleCoach(role);
   }

   public isUserRoleCoachOrAssistant(role) {
      return this.accessRightsService.isUserRoleCoachOrAssistant(role);
   }

   public isUserRoleTeamContact(role) {
      return this.accessRightsService.isUserRoleTeamContact(role);
   }

   public isUserRoleTeamDelegate(role) {
      return this.accessRightsService.isUserRoleTeamDelegate(role);
   }



   public onChange_MemberSelected(event, member: user) {
      if (event.checked) {
         this.allMembersSelected.push(member);
         if (this.allMembersSelected.length == this.allMembersByTeam.length)
            this.form.get("allMembersSelectedCheckbox").setValue(true);
      } else {
         this.allMembersSelected = this.removeAt(this.allMembersSelected, member.sysId);
         this.form.get("allMembersSelectedCheckbox").setValue(false);
      }
      let withActiveMembersSelectedCheckbox = false;
      this.allMembersSelected.forEach(i => {
         if (!i.sysIsActive || i.isUserLoginInactive) {
            withActiveMembersSelectedCheckbox = true;
         }
      });

      this.form.get("withActiveMembersSelectedCheckbox").setValue(withActiveMembersSelectedCheckbox);
   }

   public onChange_AllMembersSelected(event) {
      this.form.get("withActiveMembersSelectedCheckbox").setValue(true);
      this.setMembersSelected();
   }

   public onChange_WithActiveMembersSelected(event) {
      this.setMembersSelected();
   }

   public setMembersSelected() {
      const allMembersSelectedArray = this.form.get('allMembersSelectedArray') as FormArray;

      this.allMembersSelected = [];
      if (this.form.get("allMembersSelectedCheckbox").value) {
         allMembersSelectedArray.controls.forEach(control => {
            control.setValue(true);
         });
         if (this.form.get('withActiveMembersSelectedCheckbox').value)
            this.allMembersSelected = this.allMembersByTeam.map(x => x);
         else
            this.allMembersSelected = this.allMembersByTeam.filter(i => i.sysIsActive && !i.isUserLoginInactive).map(x => x);
      }
      else {
         allMembersSelectedArray.controls.forEach(control => {
            control.setValue(false);
         });
         this.allMembersSelected = [];
      }
   }

   public getImgURL(url: string) {
      return this.sanitizer.bypassSecurityTrustUrl(JSON.parse(url));
   }

   public refresh() {
      this.allMembersByRole = this.cacheService.getAllMembersByRole();
      this.allMembersByLastname = this.cacheService.getAllMembersByLastname().filter(i => this.frameworkService.normalizeString(i.firstName).includes(this.frameworkService.normalizeString(this.form.get("memberFilter").value)) || this.frameworkService.normalizeString(i.lastName).includes(this.frameworkService.normalizeString(this.form.get("memberFilter").value)));
      this.allMembersByFirstname = this.cacheService.getAllMembersByFirstname().filter(i => this.frameworkService.normalizeString(i.firstName).includes(this.frameworkService.normalizeString(this.form.get("memberFilter").value)) || this.frameworkService.normalizeString(i.lastName).includes(this.frameworkService.normalizeString(this.form.get("memberFilter").value)));
      this.allMembersByTeam = this.cacheService.getAllMembersByTeam().filter(i => this.frameworkService.normalizeString(i.firstName).includes(this.frameworkService.normalizeString(this.form.get("memberFilter").value)) || this.frameworkService.normalizeString(i.lastName).includes(this.frameworkService.normalizeString(this.form.get("memberFilter").value)));
      this.allMembersByAllTeam = this.cacheService.getAllMembersByAllTeam().filter(i => this.frameworkService.normalizeString(i.firstName).includes(this.frameworkService.normalizeString(this.form.get("memberFilter").value)) || this.frameworkService.normalizeString(i.lastName).includes(this.frameworkService.normalizeString(this.form.get("memberFilter").value)));

      if (this.accessRightsService.isUserConnectedAdministratorOrSupervisor() || this.accessRightsService.isUserConnectedPresident() || this.accessRightsService.isUserConnectedSecretary()) {
         this.allMemberWithoutPhoneNumber = this.allMembersByAllTeam.filter(i => !i.isUserLoginInactive && i.sysIsActive && (i.phone1 == null || i.phone1 == "")).sort(function (a, b) {
            if ((a.team.teamCategory == "" ? "ZZZZZZZZZZZZ" : a.team.teamCategory) + a.firstName + a.lastName < (b.team.teamCategory == "" ? "ZZZZZZZZZZZZ" : b.team.teamCategory) + b.firstName + b.lastName) {
               return -1;
            }
            if ((a.team.teamCategory == "" ? "ZZZZZZZZZZZZ" : a.team.teamCategory) + a.firstName + a.lastName > (b.team.teamCategory == "" ? "ZZZZZZZZZZZZ" : b.team.teamCategory) + b.firstName + b.lastName) {
               return 1;
            }
            return 0;
         });
         this.allMemberWithoutEmail = this.allMembersByAllTeam.filter(i => !i.isUserLoginInactive && i.sysIsActive && (i.email == null || i.email == "")).sort(function (a, b) {
            if ((a.team.teamCategory == "" ? "ZZZZZZZZZZZZ" : a.team.teamCategory) + a.firstName + a.lastName < (b.team.teamCategory == "" ? "ZZZZZZZZZZZZ" : b.team.teamCategory) + b.firstName + b.lastName) {
               return -1;
            }
            if ((a.team.teamCategory == "" ? "ZZZZZZZZZZZZ" : a.team.teamCategory) + a.firstName + a.lastName > (b.team.teamCategory == "" ? "ZZZZZZZZZZZZ" : b.team.teamCategory) + b.firstName + b.lastName) {
               return 1;
            }
            return 0;
         });
         this.allMemberWithoutPersonalPicture = this.allMembersByAllTeam.filter(i => !i.isUserLoginInactive && i.sysIsActive && (i.picturePersonal == null || i.picturePersonal == "")).sort(function (a, b) {
            if ((a.team.teamCategory == "" ? "ZZZZZZZZZZZZ" : a.team.teamCategory) + a.firstName + a.lastName < (b.team.teamCategory == "" ? "ZZZZZZZZZZZZ" : b.team.teamCategory) + b.firstName + b.lastName) {
               return -1;
            }
            if ((a.team.teamCategory == "" ? "ZZZZZZZZZZZZ" : a.team.teamCategory) + a.firstName + a.lastName > (b.team.teamCategory == "" ? "ZZZZZZZZZZZZ" : b.team.teamCategory) + b.firstName + b.lastName) {
               return 1;
            }
            return 0;
         });
         this.allMemberWithoutVabPicture = this.allMembersByAllTeam.filter(i => !i.isUserLoginInactive && i.sysIsActive && (i.pictureVab == null || i.pictureVab == "")).sort(function (a, b) {
            if ((a.team.teamCategory == "" ? "ZZZZZZZZZZZZ" : a.team.teamCategory) + a.firstName + a.lastName < (b.team.teamCategory == "" ? "ZZZZZZZZZZZZ" : b.team.teamCategory) + b.firstName + b.lastName) {
               return -1;
            }
            if ((a.team.teamCategory == "" ? "ZZZZZZZZZZZZ" : a.team.teamCategory) + a.firstName + a.lastName > (b.team.teamCategory == "" ? "ZZZZZZZZZZZZ" : b.team.teamCategory) + b.firstName + b.lastName) {
               return 1;
            }
            return 0;
         });
         this.allMemberWithoutLoginIn = this.allMembersByAllTeam.filter(i => !i.isUserLoginInactive && i.sysIsActive && (i.username == "xxxxxxxxxxx")).sort(function (a, b) {
            if ((a.team.teamCategory == "" ? "ZZZZZZZZZZZZ" : a.team.teamCategory) + a.firstName + a.lastName < (b.team.teamCategory == "" ? "ZZZZZZZZZZZZ" : b.team.teamCategory) + b.firstName + b.lastName) {
               return -1;
            }
            if ((a.team.teamCategory == "" ? "ZZZZZZZZZZZZ" : a.team.teamCategory) + a.firstName + a.lastName > (b.team.teamCategory == "" ? "ZZZZZZZZZZZZ" : b.team.teamCategory) + b.firstName + b.lastName) {
               return 1;
            }
            return 0;
         });
         this.allMemberWithoutJacketNumber = this.allMembersByAllTeam.filter(i => i.team?.teamCategory != "" && !i.isUserLoginInactive && i.sysIsActive && i.jacketNumber == -1).sort(function (a, b) {
            if ((a.team.teamCategory == "" ? "ZZZZZZZZZZZZ" : a.team.teamCategory) + a.firstName + a.lastName < (b.team.teamCategory == "" ? "ZZZZZZZZZZZZ" : b.team.teamCategory) + b.firstName + b.lastName) {
               return -1;
            }
            if ((a.team.teamCategory == "" ? "ZZZZZZZZZZZZ" : a.team.teamCategory) + a.firstName + a.lastName > (b.team.teamCategory == "" ? "ZZZZZZZZZZZZ" : b.team.teamCategory) + b.firstName + b.lastName) {
               return 1;
            }
            return 0;
         });
         this.allMemberWithoutContribution = this.allMembersByAllTeam.filter(i => !i.isUserLoginInactive && i.sysIsActive && !i.isContributionPayed).sort(function (a, b) {
            if ((a.team.teamCategory == "" ? "ZZZZZZZZZZZZ" : a.team.teamCategory) + a.firstName + a.lastName < (b.team.teamCategory == "" ? "ZZZZZZZZZZZZ" : b.team.teamCategory) + b.firstName + b.lastName) {
               return -1;
            }
            if ((a.team.teamCategory == "" ? "ZZZZZZZZZZZZ" : a.team.teamCategory) + a.firstName + a.lastName > (b.team.teamCategory == "" ? "ZZZZZZZZZZZZ" : b.team.teamCategory) + b.firstName + b.lastName) {
               return 1;
            }
            return 0;
         });
      }

      if (this.accessRightsService.isUserConnected && (this.storageService.getUserConnected().sysId == this.appConfigService.getRootUser() || this.storageService.getUserConnected().sysId == this.appConfigService.getAdminUser())) {
         this.activesMembersCount = 0;
         this.inactivesLoginMembersCount = 0;
         this.inactivesMembersCount = 0;
      }
      else {
         this.activesMembersCount = this.cacheService.getAllMembersForMainTeam().filter(i => !i.isUserLoginInactive && i.sysIsActive).length;
         this.inactivesMembersCount = this.cacheService.getAllMembersForMainTeam().filter(i => !i.sysIsActive).length;
         this.inactivesLoginMembersCount = this.cacheService.getAllMembersForMainTeam().filter(i => i.isUserLoginInactive).length - this.inactivesMembersCount;
      }

      this.allRolesOfMembers = this.frameworkService.getAllRolesOfMembers();

      const formArray = this.form.get('allMembersSelectedArray') as FormArray;
      this.allMembersByTeam.forEach(x => formArray.push(new FormControl(false)));

      this.allMembersByTeam = this.allMembersByTeam.sort(function (a, b) {
         if ((a.team.teamCategory == "" ? "ZZZZZZZZZZZZ" : a.team.teamCategory) + a.firstName + a.lastName < (b.team.teamCategory == "" ? "ZZZZZZZZZZZZ" : b.team.teamCategory) + b.firstName + b.lastName) {
            return -1;
         }
         if ((a.team.teamCategory == "" ? "ZZZZZZZZZZZZ" : a.team.teamCategory) + a.firstName + a.lastName > (b.team.teamCategory == "" ? "ZZZZZZZZZZZZ" : b.team.teamCategory) + b.firstName + b.lastName) {
            return 1;
         }
         return 0;
      });

      this.getAllTeamsCount();
   }

   public removeAt(objectArray, sysId: number) {
      objectArray.forEach((value, index) => {
         if (value.sysId == sysId) objectArray.splice(index, 1);
      });
      return objectArray;
   }

   public sendVCard() {
      this.accountService.sendVCardToUser(this.allMembersSelected, this.storageService.getUserConnected().email).pipe(
         timeout(240000),
         catchError(err => {
            console.error('Request timed out or failed', err);
            this.frameworkService.displayAlert(false, this.translateService.instant('SendVCardFailed'));
            this.subjectService.IsButtonInLoadingSubject.next(false);
            return throwError(err); // Handle the error or timeout
         })
      ).subscribe({
         next: data => {
            let dataDecrypted = this.encryptionService.decryptObject(false, (data as encryptedValue).value);
      
            if (dataDecrypted != null) {
               this.frameworkService.displayAlert(true, this.translateService.instant('SendVCardSuccessfull'));
               this.subjectService.IsButtonInLoadingSubject.next(false);
            } else {
               this.frameworkService.displayAlert(false, this.translateService.instant('SendVCardFailed'));
               this.subjectService.IsButtonInLoadingSubject.next(false);
            }
         },
         error: err => {
            this.frameworkService.displayAlert(false, err.mes || this.translateService.instant('SendVCardFailed'));
            this.subjectService.IsButtonInLoadingSubject.next(false);
         }
      });
   }

   public sendLoginProcedure() {
      this.accountService.sendLoginProcedureToUser(this.allMembersSelected, this.siteMail()).subscribe(
         {
            next: data => {
               let dataDecrypted = this.encryptionService.decryptObject(false, (data as encryptedValue).value);

               if (dataDecrypted != null) {
                  this.frameworkService.displayAlert(true, this.translateService.instant('SendLoginProcedureSuccessfull'));
                  this.subjectService.IsButtonInLoadingSubject.next(false);
               }
               else {
                  this.frameworkService.displayAlert(false, this.translateService.instant('SendLoginProcedureFailed'));
                  this.subjectService.IsButtonInLoadingSubject.next(false);
               }
            },
            error: err => {
               this.frameworkService.displayAlert(false, err.mes);
               this.subjectService.IsButtonInLoadingSubject.next(false);
            }
         });
   }

   public onClick_SendToVCard(): void {
      this.subjectService.IsButtonInLoadingSubject.next(false);

      const message1 = "Vous êtes sur le point de vous envoyer les informations des membres sélectionnés par mail.";
      const message2 = "Voulez-vous continuer ?";

      const dialogData = new ConfirmDialogModel("Envoi du VCard", message1, message2);

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
         position: { top: '0px' },
         maxWidth: "100%",
         width: "100%",
         data: dialogData
      });

      dialogRef.afterClosed().subscribe(dialogResult => {
         if (dialogResult) {
            this.sendVCard();
         }
         this.subjectService.IsButtonInLoadingSubject.next(false);
      });
   }

   public onClick_LoginProcedure(): void {
      this.subjectService.IsButtonInLoadingSubject.next(false);

      const message1 = "Vous êtes sur le point d'envoyer la procédure à tous les membres sélectionnés.";
      const message2 = "Voulez-vous continuer ?";

      const dialogData = new ConfirmDialogModel("Procédure de login", message1, message2);

      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
         position: { top: '0px' },
         maxWidth: "100%",
         width: "100%",
         data: dialogData
      });

      dialogRef.afterClosed().subscribe(dialogResult => {
         if (dialogResult) {
            this.sendLoginProcedure();
         }
         this.subjectService.IsButtonInLoadingSubject.next(false);
      });
   }

   public onClick_Mailing(): void {
      let request = new mailRequest();
      request.toEmail = this.allMembersSelected.join(';');
      request.subject = this.form.get('subject').value;
      request.title = "SAISON " + this.cacheService.getCurrentChampionship().yearStarting + "-" + this.cacheService.getCurrentChampionship().yearEnding + " : " + this.form.get('subject').value;
      request.body = this.form.get('message').value;
      request.type = enumDocumentType.SimpleMailing;

      let appServiceArray: Observable<any>[] = [];

      appServiceArray.push(this.mailService.sendMail(request));

      forkJoin(appServiceArray).subscribe({
         next: async data => {
            this.subjectService.IsButtonInLoadingSubject.next(false);
            if (data[0])
               this.frameworkService.displayAlert(true, "Le mail a été envoyé aux destinataires.");
            else
               this.frameworkService.displayAlert(false, "Le mail n'a pas pu être envoyées aux destinataires.");
        },
        error: err => {
            this.subjectService.IsButtonInLoadingSubject.next(false);
            this.frameworkService.displayAlert(null, err);
        }
      });
   }

   public siteMail() {
      return this.appConfigService.getMail();
   }

   public translatePage() {
      //ProfileTitle
      this.translateService.get(['MemberTitle'])
         .subscribe(translations => {
            this.titleDescriptionDisplay = translations['MemberTitle'];
         });
   }
}