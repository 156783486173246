import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import 'zone.js'; //Added for lazy module error in firefox,safari in server.
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';


if (environment.production) {
   enableProdMode();

   if ('serviceWorker' in navigator) {
      console.log("'serviceWorker' in navigator");
      navigator.serviceWorker.getRegistrations().then((registrations) => {
         for (let registration of registrations) {
           registration.unregister().then((successful) => {
            console.log("-> unregister");
             if (successful) {
               console.log('Service Worker unregistered successfully');
             }
           });
         }
       }).catch((error) => {
         console.error('Error unregistering service worker:', error);
       });

       if ('caches' in window) {
         caches.keys().then(function(cacheNames) {
           cacheNames.forEach(function(cacheName) {
             console.log('Delete cache ' + cacheName);
             caches.delete(cacheName);
           });
         });
       }

      navigator.serviceWorker.register('/vab-ngsw-worker.js', { scope: '/' })
      .then((registration) => {
        console.log('Custom service worker registered with scope:', registration.scope);
      }).catch((error) => {
         console.error('Error registering Angular Service Worker : ', error);
       });
   }
}

function bootstrap() {
   platformBrowserDynamic().bootstrapModule(AppModule)
   .catch(err => console.error(err));
};

if (document.readyState === 'complete') {
   bootstrap();
} else {
   document.addEventListener('DOMContentLoaded', bootstrap);
}

