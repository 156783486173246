//This file is autogenerated by C# GeneratedServices/Angular/ServicesAngularGenerationTool

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, retry, throwError } from 'rxjs';
import { AppConfigService } from 'src/app/services/appconfig.service';
import { authToken } from './authToken';
import { encryptedValue } from './encryptedValue';
import { StorageService } from './storage.service';
import { AccessRightsService } from 'src/app/services/accessRights.service';
import { EncryptionService } from 'src/app/services/encryption.service';
import { jsonIgnoreReplacer } from 'json-ignore';

@Injectable()
export class AuthenticationService {
   public username = "UNKNOWN";

   constructor(
      private appConfigService: AppConfigService,
      private httpClient: HttpClient,
      private accessRightsService: AccessRightsService,
      private storageService: StorageService,
      private encryptionService: EncryptionService
   ) {
      this.httpClient = httpClient;
      this.storageService = storageService;
      this.username = !this.accessRightsService.isUserConnected ? "UNKNOWN" : this.storageService.getUserConnected()?.username;
   }

   // Error handling
   public handleError(error) {

      let errorMessage = '';
      if (error.error instanceof ErrorEvent) {
         // Get client-side error
         errorMessage = error.error.message;
      } else {
         // Get server-side error
               if (error.status == 401)
        errorMessage = "Vous n'êtes pas autorisé à effectuer cette opération.";
      else
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      return throwError(() => errorMessage);
   }

   public refreshToken(authToken: authToken) {
    let encryptedData = new encryptedValue();
    encryptedData.value = this.encryptionService.encryptObject(true, false, authToken);

    let url = "/Q200400001";
    return this.httpClient.post<encryptedValue>(this.appConfigService.getApiUrl() + 'api/C2004' + url, JSON.parse(JSON.stringify(encryptedData, jsonIgnoreReplacer)))
    .pipe(retry(1),catchError(this.handleError));
   }
}


