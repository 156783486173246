import { Component, HostListener, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import 'linq-typed';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/components/confirmDialog/confirmDialog.component';
import { ImageCropMainComponent, ImageCropModel } from 'src/app/components/imageCrop/imageCropMain.component';
import { enumRoles, enumCacheTypeLoad, enumPrivacyLevel, enumPrivacyControlType, enumCacheStatusLoad } from 'src/app/enum/Enum';
import { ModalFormParam } from 'src/app/models/system/modalFormParam';
import { user } from 'src/app/models/user/user';
import { AccessRightsService } from 'src/app/services/accessRights.service';
import { FrameworkService } from 'src/app/services/framework.service';
import { UserService } from 'src/app/services/user.service';
import { team } from '../../models/team';
import { CacheService } from '../../services/cache.service';
import { EncryptionService } from 'src/app/services/encryption.service';
import { StorageService } from '../../authentication/storage.service';
import { encryptedValue } from '../../authentication/encryptedValue';
import { SubjectService } from 'src/app/services/subject.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { NotificationService } from 'src/app/services/notification.service';
import { forkJoin, Observable, of } from 'rxjs';
import { SystemService } from 'src/app/services/system.service';
import { PushNotificationModel } from 'src/app/models/system/pushNotificationModel';

@Component({
   selector: 'app-profile',
   templateUrl: './profile.component.html',
   styleUrls: ['./profile.component.scss']
})

export class ProfileComponent implements OnInit {
   public allMembersByRole;
   public allMembersSelected: user[] = [];
   public allRolesOfMembers = [];
   public allTeams = [];
   public allTeamsWithoutPicture: team[];
   public checkedRoles: Array<string> = [];
   public checkedWebPushCategory: Array<string> = [];
   public checkedTeams: Array<team> = [];
   public form: FormGroup = new FormGroup({});
   public isButtonLockUserDisabled = false;
   public isButtonResetTryPasswordDisabled = false;
   public isDataModified = false;
   public isDisplayOnAMobile = (window.innerWidth <= 960);
   public isNotFirstTime: boolean = false
   public isSupervisorConnected: boolean = false;
   public profile: user;
   public profileId: string;
   public queryParams: any;
   public selectedRoles: string[] = []
   public selectedWebPushCategory: string[] = []
   public selectedTeams: team[] = []
   public tabType: number;
   public teamRoleSelected: string = "";
   public teamSelected1: string = "";
   public teamSelected2: string = "";
   public titleMyProfileDescriptionDisplay: string = "";
   public titleProfileOfMemberDescriptionDisplay: string = "";
   public EnumPrivacyControlType = enumPrivacyControlType;
   public EnumPrivacyLevel = enumPrivacyLevel;

   constructor(
      private sanitizer: DomSanitizer,
      @Optional() @Inject(MAT_DIALOG_DATA) public modalFormParam: ModalFormParam,
      @Optional() public dialog: MatDialog,
      private cacheService: CacheService,
      private subjectService: SubjectService,
      private frameworkService: FrameworkService,
      private translateService: TranslateService,
      public accessRightsService: AccessRightsService,
      private userService: UserService,
      private formBuilder: FormBuilder,
      private activeRouter: ActivatedRoute,
      private encryptionService: EncryptionService,
      private router: Router,
      public storageService: StorageService,
      private clipboard: Clipboard,
      private notificationService: NotificationService,
      private systemService: SystemService
   ) {
      this.subjectService.GlobalCacheLoadedStatusSubject.subscribe(value => { if (value != null && value >= enumCacheStatusLoad.loaded) this.initialize(); });

      this.getScreenSize();

      this.isSupervisorConnected = this.accessRightsService.isUserConnectedAdministratorOrSupervisor();
   }


   public ngOnInit() {
      this.form = this.formBuilder.group(
         {
            allTeam1: [[]],
            allTeam2: [[]],
            memberPicturePersonal: [''],
            memberPictureVAB: [''],
            isNotificationByEmailActivate: [false],
            isNotificationMatchByEmailActivate: [false],
            isNotificationActivityReportByEmailActivate: [false],
            isNotificationMatchOfClubByEmailActivate: [false],
            isNotificationComiteeMeetingByEmailActivate: [false],
            isNotificationAvailabilityByEmailActivate: [false],
            isNotificationAvailabilityReportByEmailActivate: [false],
            isNotificationByPushActivate: [false],
            emailPrivacyLevel1: [false],
            emailPrivacyLevel2: [false]
         });

      this.activeRouter.queryParams.subscribe(params => {
         this.queryParams = params;
         if (this.queryParams["tabType"] == undefined) {
            this.tabType = 0;
         }
         else {
            this.tabType = Number(this.queryParams["tabType"]);
         }

         if (this.queryParams["id"] == undefined) {
            this.profileId = "-1";
         }
         else {
            if (this.queryParams["id"] != "-1") {
               this.profileId = this.encryptionService.decrypt(true, this.queryParams["id"]);
            }
            else {
               this.profileId = "-1";
            }
         }
         this.refresh();
      });
   }

   public initialize() {
      this.refresh();
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
         this.translatePage();
      });
   }

   public refresh() {
      if (this.profileId == "-1")
         this.profile = this.cacheService.getAllUsers().FirstOrDefault(i => i.sysId == Number(this.storageService.getUserConnected().sysId));
      else
         this.profile = this.cacheService.getAllUsers().FirstOrDefault(i => i.sysId == Number(this.profileId));

      if (this.profile != undefined) {
         if (this.form.get('allTeam1') != null) {
            this.form.get('allTeam1').setValue(this.profile.team);
         }
         if (this.form.get('allTeam2') != null) {
            this.form.get('allTeam2').setValue(this.profile.team2);
         }
         if (this.form.get('memberPicturePersonal') != null && this.profile.picturePersonal != "") {
            this.form.get('memberPicturePersonal').setValue(JSON.parse(this.profile.picturePersonal));
         }
         if (this.form.get('memberPictureVAB') != null && this.profile.pictureVab != "") {
            this.form.get('memberPictureVAB').setValue(JSON.parse(this.profile.pictureVab));
         }

         if (this.form.get('isNotificationByEmailActivate') != null) {
            this.form.get('isNotificationByEmailActivate').setValue(this.profile.isNotificationByEmailActivate);
         }
         if (this.form.get('isNotificationMatchByEmailActivate') != null) {
            this.form.get('isNotificationMatchByEmailActivate').setValue(this.profile.isNotificationMatchByEmailActivate);
         }
         if (this.form.get('isNotificationActivityReportByEmailActivate') != null) {
            this.form.get('isNotificationActivityReportByEmailActivate').setValue(this.profile.isNotificationActivityReportByEmailActivate);
         }
         if (this.form.get('isNotificationMatchOfClubByEmailActivate') != null) {
            this.form.get('isNotificationMatchOfClubByEmailActivate').setValue(this.profile.isNotificationMatchOfClubByEmailActivate);
         }
         if (this.form.get('isNotificationComiteeMeetingByEmailActivate') != null) {
            this.form.get('isNotificationComiteeMeetingByEmailActivate').setValue(this.profile.isNotificationComiteeMeetingByEmailActivate);
         }
         if (this.form.get('isNotificationAvailabilityByEmailActivate') != null) {
            this.form.get('isNotificationAvailabilityByEmailActivate').setValue(this.profile.isNotificationAvailabilityByEmailActivate);
         }
         if (this.form.get('isNotificationAvailabilityReportByEmailActivate') != null) {
            this.form.get('isNotificationAvailabilityReportByEmailActivate').setValue(this.profile.isNotificationAvailabilityReportByEmailActivate);
         }
         if (this.form.get('isNotificationAvailabilityForTeamByEmailActivate') != null) {
            this.form.get('isNotificationAvailabilityForTeamByEmailActivate').setValue(this.profile.isNotificationAvailabilityForTeamByEmailActivate);
         }
         if (this.form.get('isNotificationByPushActivate') != null) {
            this.form.get('isNotificationByPushActivate').setValue(this.profile.isNotificationByPushActivate);
         }
         if (this.form.get('emailPrivacyLevel1') != null) {
            this.form.get('emailPrivacyLevel1').setValue(this.profile.isNotificationByPushActivate);
         }
         if (this.form.get('emailPrivacyLevel2') != null) {
            this.form.get('emailPrivacyLevel2').setValue(this.profile.isNotificationByPushActivate);
         }

         this.allTeamsWithoutPicture = this.cacheService.getAllTeamsWithoutPicture().filter(i => i.teamCategory != "");

         this.selectedTeams = [];
         if (this.profile != null && this.profile.isNotificationAvailabilityForTeamByEmailActivate != null) this.profile.isNotificationAvailabilityForTeamByEmailActivate.split(";").forEach(i => this.selectedTeams.push(this.allTeamsWithoutPicture.FirstOrDefault(j => j.sysId == Number(i))));

         this.selectedRoles = this.getProfileRolesArray(this.profile).map(i => i.trim());
         this.selectedWebPushCategory = this.getWebPushCategoryArray(this.profile).map(i => i.trim());

         this.teamSelected1 = this.profile?.team?.teamCategory;
         this.teamSelected2 = this.profile?.team2?.teamCategory;

         this.allTeams = this.cacheService.getAllTeamsWithoutPicture();
         this.allMembersByRole = this.getAllMembersByRole();
         this.allRolesOfMembers = this.frameworkService.getAllRolesOfMembers();

         this.translatePage();
      }
   }

   @HostListener('window:resize', ['$event'])
   public getScreenSize() {
      this.isDisplayOnAMobile = (window.innerWidth <= 960);
   }

   public disableLockUserButton(isDisabled: boolean) {
      this.isButtonLockUserDisabled = isDisabled;
   }

   public disableResetTryPasswordButton(isDisabled: boolean) {
      this.isButtonResetTryPasswordDisabled = isDisabled;
   }

   public getAllMembersByAllTeam() {
      return this.cacheService.getAllMembersByAllTeam();
   }

   public getAllMembersByFirstname() {
      let allPlayers = this.cacheService.getAllUsers().filter(i => i.sysId != 1 && i.sysId != 2).sort(function (a, b) {
         if (a.firstName + a.lastName < b.firstName + b.lastName) {
            return -1;
         }
         if (a.firstName + a.lastName > b.firstName + b.lastName) {
            return 1;
         }
         return 0;
      });

      return allPlayers;
   }

   public getAllMembersByLastname() {
      let allPlayers = this.cacheService.getAllUsers().filter(i => i.sysId != 1 && i.sysId != 2).sort(function (a, b) {
         if (a.lastName + a.firstName < b.lastName + b.firstName) {
            return -1;
         }
         if (a.lastName + a.firstName > b.lastName + b.firstName) {
            return 1;
         }
         return 0;
      });

      return allPlayers;
   }

   public getAllMembersByRole(): Map<string, object> {
      let list = new Map<string, object>();
      let allUsers = this.cacheService.getAllUsers().filter(i => i.sysId != 1 && i.sysId != 2);
      allUsers.forEach(u => this.frameworkService.getProfileRoles(u, false, false).forEach(i => {
         let temp = [];
         temp = list.get(i) as user[];
         if (temp == undefined) {
            temp = [];
         }
         temp.push(u);
         list.set(i, temp);
      }
      ));

      return list;
   }

   public getAllMembersByTeam() {
      let allPlayers = this.cacheService.getAllUsers().filter(i => i.sysId != 1 && i.sysId != 2).sort(function (a, b) {
         if ((a.team.teamCategory == "" ? "ZZZZZZZZZZZZ" : a.team.teamCategory) + a.lastName + a.firstName < (b.team.teamCategory == "" ? "ZZZZZZZZZZZZ" : b.team.teamCategory) + b.lastName + b.firstName) {
            return -1;
         }
         if ((a.team.teamCategory == "" ? "ZZZZZZZZZZZZ" : a.team.teamCategory) + a.lastName + a.firstName > (b.team.teamCategory == "" ? "ZZZZZZZZZZZZ" : b.team.teamCategory) + b.lastName + b.firstName) {
            return 1;
         }
         return 0;
      });

      return allPlayers;
   }

   public getAllRolesOfMembers() {
      return this.frameworkService.getAllRolesOfMembers().map(i => i.name);
   }

   public getAllWebPushCategory() {
      return ["Test", "Nouvelles"];
   }

   public getAllUsersOfRole(role): [] {
      if (this.allMembersByRole != null) {
         return this.allMembersByRole.get(role);
      }
      return [];
   }

   public getImgURL(column: string) {
      if (this.form.controls[column] == null || this.form.controls[column].value == null || this.form.controls[column].value == "")
         return null;
      else
         return this.sanitizer.bypassSecurityTrustUrl(this.form.controls[column].value);
   }

   public getIsInactiveBackgroundClass(profile): string {
      return profile.isUserLoginInactive ? "memberBackgroundInactive" : "memberBackgroundActive";
   }

   public getIsInactiveClass(profile): string {
      return !profile.sysIsActive ? "memberInactive" : "";
   }

   public getIsInactiveProfile(profile): boolean {
      return profile.isUserLoginInactive;
   }

   public getIsUserLoginInactiveClass() {
      return this.profile.isUserLoginInactive ? "no_encryption" : "lock";
   }

   public getIsUserInactiveClass() {
      return !this.profile.sysIsActive ? "no_encryption" : "lock";
   }

   public getMatButtonToggleClass(role: string) {
      switch (role) {
         case this.translateService.instant(enumRoles[enumRoles.MarkerDelegate]):
         case this.translateService.instant(enumRoles[enumRoles.President]):
         case this.translateService.instant(enumRoles[enumRoles.Secretary]):
         case this.translateService.instant(enumRoles[enumRoles.Treasurer]):
            return true;
         default:
            return false;
      }
   }

   public getNotificationClass(level: number, status: boolean) {
      switch (level) {
         case 0:
         case 6:
            return status ? '' : 'text-strike';
         default:
            return status ? 'text-muted text-mutedSize' : 'text-muted text-mutedSize text-strike';
      }
   }

   public getPhoneFormat(phone: string) {
      return this.frameworkService.phoneFormat(phone);
   }

   public getPreviousMember(i) {
      if (i - 1 < 0)
         return "";
      else {
         return this.getAllMembersByLastname()[i - 1].lastName[0];
      }
   }

   public getPreviousMemberByAllTeam(i) {
      if (i - 1 < 0)
         return "";
      else {
         return this.getAllMembersByAllTeam()[i - 1]?.team?.teamCategory;
      }
   }

   public getPreviousMemberByFirstname(i) {
      if (i - 1 < 0)
         return "";
      else {
         return this.getAllMembersByFirstname()[i - 1].firstName[0];
      }
   }
   
   public getPrivacyClass(status: number) {
      return status == 0 ? 'text-width text-muted text-mutedSize text-strike' : 'text-width text-muted text-mutedSize';
   }

   public getPrivacyVisiblity(level: number) {
      switch (level) {
         case 0: return "masqué";
         case 1: return "visible équipe";
         case 2: return "visible club";
         case 3: return "visible public";
      }
   }

   public getProfileRoles(profile) {
      return this.frameworkService.getProfileRoles(profile, false, false).join(", ");
   }

   public getProfileRolesArray(profile) {
      return this.frameworkService.getProfileRoles(profile, false, false);
   }

   public getWebPushCategoryArray(profile) {
      return this.frameworkService.getWebPushCategories(profile);
   }

   public getProfileTitle() {
      if (this.profile != null && this.storageService.getUserConnected() != null) {
         if (this.profileId == "-1" || this.profile.sysId == this.storageService.getUserConnected().sysId)
            return this.titleMyProfileDescriptionDisplay;
         else
            return this.titleProfileOfMemberDescriptionDisplay;
      }
      else
         return "";
   }

   public getUserCountTitle() {
      let activeCount = this.getAllMembersByLastname().filter(i => !i.isUserLoginInactive).length;
      let inactiveCount = this.getAllMembersByLastname().filter(i => i.isUserLoginInactive).length;
      return activeCount + " actifs + " + inactiveCount + " inactifs";
   }

   public getUserIcon(profile): string {
      if (profile.workgroup != null) {
         return "../../../app/images/account/" + this.accessRightsService.getUserIconFromMember(profile);
      }
   }

   public onEventCopyToClipboard(profile: user) {
      this.clipboard.copy(profile.address + " " + profile.zipCode + " " + profile.locality);
      this.frameworkService.displayAlert(true, "L'adresse a été copiée dans le presse-papier");
   }



   public isButtonNotificationVisible(profileValue: boolean, status: boolean) {
      if (profileValue) {
         if (status)
            return false;
         else
            return true;
      }
      else {
         if (status)
            return true;
         else
            return false;
      }
   }

   public isButtonPicturePersonalDeleteDisabled() {
      return this.profile.picturePersonal == null || this.profile.picturePersonal == "";
   }

   public isButtonPictureVABDeleteDisabled() {
      return this.profile.pictureVab == null || this.profile.pictureVab == "";
   }

   public isButtonPrivacyVisible(level: number, status: number) {
      switch (status) {
         //Hide
         case enumPrivacyLevel.None:
            {
               switch (level) {
                  case enumPrivacyLevel.None: return false;
                  case enumPrivacyLevel.Team: return true;
                  case enumPrivacyLevel.Club: return true;
                  case enumPrivacyLevel.Public: return true;
               }
               break;
            }
         //Team
         case enumPrivacyLevel.Team:
            {
               switch (level) {
                  case enumPrivacyLevel.None: return true;
                  case enumPrivacyLevel.Team: return false;
                  case enumPrivacyLevel.Club: return true;
                  case enumPrivacyLevel.Public: return true;
               }
               break;
            }
         //Club
         case enumPrivacyLevel.Club:
            {
               switch (level) {
                  case enumPrivacyLevel.None: return true;
                  case enumPrivacyLevel.Team: return true;
                  case enumPrivacyLevel.Club: return false;
                  case enumPrivacyLevel.Public: return true;
               }
               break;
            }
         //Public
         case enumPrivacyLevel.Public:
            {
               switch (level) {
                  case enumPrivacyLevel.None: return true;
                  case enumPrivacyLevel.Team: return true;
                  case enumPrivacyLevel.Club: return true;
                  case enumPrivacyLevel.Public: return false;
               }
               break;
            }
      }
   }

   public isMemberCoachOnly(profile) {
      return this.accessRightsService.isMemberCoachOnly(profile);
   }

   public isNoMemberIsSelected() {
      return this.allMembersSelected.length == 0;
   }

   public isProfileInfoVisible(type: number) {
      if (this.accessRightsService.isUserConnectedAdministratorOrSupervisor || this.accessRightsService.isUserConnectedPresident() || this.accessRightsService.isUserConnectedSecretary())
         return true;

      switch (type) {
         case 0:
            if (this.profile.emailPrivacyLevel == enumPrivacyLevel.None)
               return false;
            else {
               if (this.profile.emailPrivacyLevel == enumPrivacyLevel.Team) {
                  //Check if user connected is in the same team
                  return this.profile.sysId == this.storageService.getUserConnected().sysId;
               }
               else
                  return true;
            }
         case 1:
            if (this.profile.phone1PrivacyLevel == enumPrivacyLevel.None)
               return false;
            else {
               if (this.profile.phone1PrivacyLevel == enumPrivacyLevel.Team) {
                  //Check if user connected is in the same team
                  return this.profile.sysId == this.storageService.getUserConnected().sysId;
               }
               else
                  return true;
            }
         case 2:
            if (this.profile.phone2PrivacyLevel == enumPrivacyLevel.None)
               return false;
            else {
               if (this.profile.phone2PrivacyLevel == enumPrivacyLevel.Team) {
                  //Check if user connected is in the same team
                  return this.profile.sysId == this.storageService.getUserConnected().sysId;
               }
               else
                  return true;
            }
         case 3:
            if (this.profile.addressPrivacyLevel == enumPrivacyLevel.None)
               return false;
            else {
               if (this.profile.addressPrivacyLevel == enumPrivacyLevel.Team) {
                  //Check if user connected is in the same team
                  return this.profile.sysId == this.storageService.getUserConnected().sysId;
               }
               else
                  return true;
            }
         case 4:
            if (this.profile.birthdayDatePrivacyLevel == enumPrivacyLevel.None)
               return false;
            else {
               if (this.profile.birthdayDatePrivacyLevel == enumPrivacyLevel.Team) {
                  //Check if user connected is in the same team
                  return this.profile.sysId == this.storageService.getUserConnected().sysId;
               }
               else
                  return true;
            }
      }
   }

   public isProfileOfUserForUpdate() {
      return true;
      return this.accessRightsService.isUserConnectedAdministratorOrSupervisor() || this.accessRightsService.isUserConnectedSecretary()  || this.profileId == "-1" || this.profile.sysId == this.storageService.getUserConnected().sysId;
   }

   public isUserRoleCoachOrAssistant(role) {
      return this.accessRightsService.isUserRoleCoachOrAssistant(role);
   }

   public isUserProfile() {
      return this.profileId == "-1" || this.profile?.sysId == this.storageService.getUserConnected()?.sysId;
   }

   public enableOrDisableUser(profile) {
      let status = !profile.sysIsActive;

      this.userService.changeUserActivityStatus(profile.sysId, status).subscribe({
         next: data => {
            let dataDecrypted = this.encryptionService.decryptObject(false, (data as encryptedValue).value);

            if (dataDecrypted != null) {
               this.profile = dataDecrypted as user;
               this.cacheService.manageUUser(dataDecrypted);
               this.subjectService.IsTrainingPlayerCacheLoadedSubject.next(true);
               this.frameworkService.displayAlert(true);
            }
            else
               this.frameworkService.displayAlert(false);
            this.subjectService.IsButtonInLoadingSubject.next(false);
         },
         error: err => {
            this.frameworkService.displayAlert(null, err);
            this.subjectService.IsButtonInLoadingSubject.next(false);
         }
      });
   }

   public lockOrUnlockUser(profile) {
      let status = !profile.isUserLoginInactive;

      this.userService.changeUserLoginStatus(profile.sysId, status).subscribe({
         next: data => {
            let dataDecrypted = this.encryptionService.decryptObject(false, (data as encryptedValue).value);

            if (dataDecrypted != null) {
               this.profile = dataDecrypted as user;
               this.cacheService.manageUUser(dataDecrypted);
               this.subjectService.IsTrainingPlayerCacheLoadedSubject.next(true);
               this.frameworkService.displayAlert(true);
            }
            else
               this.frameworkService.displayAlert(false);
            this.subjectService.IsButtonInLoadingSubject.next(false);
         },
         error: err => {
            this.frameworkService.displayAlert(null, err);
            this.subjectService.IsButtonInLoadingSubject.next(false);
         }
      });
   }

   public onClickUpdatePicturePersonal(column: string) {

      if (this.isSupervisorConnected || this.isUserProfile()) {
         const dialogRefData = new ImageCropModel(column, "200", "jpeg", "60");

         const dialogRef = this.dialog.open(ImageCropMainComponent, {
            data: dialogRefData,
            position: { top: '0px' },
            maxWidth: "100%",
            width: "100%",
            disableClose: true
         }
         );

         dialogRef.afterClosed().subscribe(dialogResult => {
            if (dialogResult && dialogResult.image != undefined && dialogResult.image != "") {
               this.form.controls[column].setValue(JSON.stringify(dialogResult.image));
               this.profile.picturePersonal = this.form.get(column)?.value;
               this.form.controls[column].markAsDirty();
               this.setDataModified();
            }
            else {
               this.isDataModified = null;
               this.subjectService.IsDataUpdatingSubject.next(false);
            }

            this.subjectService.IsButtonInLoadingSubject.next(false);
         });
      }
   }

   public onClickUpdatePictureVAB(column: string) {

      if (this.isSupervisorConnected) {
         const dialogRefData = new ImageCropModel(column, "200", "jpeg", "60");

         const dialogRef = this.dialog.open(ImageCropMainComponent, {
            data: dialogRefData,
            position: { top: '0px' },
            maxWidth: "100%",
            width: "100%",
            disableClose: true
         }
         );

         dialogRef.afterClosed().subscribe(dialogResult => {
            if (dialogResult && dialogResult.image != undefined && dialogResult.image != "") {
               this.form.controls[column].setValue(JSON.stringify(dialogResult.image));
               this.profile.pictureVab = this.form.get(column)?.value;
               this.form.controls[column].markAsDirty();
               this.setDataModified();
            }
            else {
               this.isDataModified = null;
               this.subjectService.IsDataUpdatingSubject.next(false);
            }

            this.subjectService.IsButtonInLoadingSubject.next(false);
         });
      }
   }

   public onDeletePicturePersonalProfile() {
      this.form.controls["memberPicturePersonal"].setValue(null);
      this.form.controls["memberPicturePersonal"].markAsDirty();
      this.profile.picturePersonal = null;

      this.onSaveProfile();
   }

   public onDeletePictureVABProfile() {
      this.form.controls["memberPictureVAB"].setValue(null);
      this.form.controls["memberPictureVAB"].markAsDirty();
      this.profile.pictureVab = null;

      this.onSaveProfile();
   }

   public onFilterChange(): void {
      this.refresh();
   }

   public onReturnToMember(): void {
      this.router.navigate(['/member'], { queryParams: { tabType: this.tabType } });
   }

   public onRoleClick(profile: user, role) {
      if (role == this.translateService.instant(enumRoles[enumRoles.MarkerDelegate]) ||
         role == this.translateService.instant(enumRoles[enumRoles.President]) ||
         role == this.translateService.instant(enumRoles[enumRoles.Secretary]) ||
         role == this.translateService.instant(enumRoles[enumRoles.Treasurer])) {
         if (this.checkedRoles.includes(role)) {
            this.frameworkService.removeItemFromArray(this.selectedRoles, role);
         }
         else {
            this.selectedRoles.push(role);
         }
      }
      else {
         const message1 = (this.selectedRoles.includes(role)) ?
            "Ajout du rôle \"" + role + "\"" : "Retrait du rôle \"" + role + "\"";
         const message2 = "Voulez-vous continuer ?";

         const dialogData = new ConfirmDialogModel("Changement de rôle", message1, message2);

         const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            position: { top: '0px' },
            maxWidth: "100%",
            width: "100%",
            data: dialogData
         });

         dialogRef.afterClosed().subscribe(dialogResult => {
            if (!dialogResult) {
               if (this.checkedRoles.includes(role))
                  this.frameworkService.removeItemFromArray(this.selectedRoles, role);
               else
                  this.selectedRoles.push(role);
            }
            else {
               this.onUpdateRoleOfMember(profile.sysId, role, this.checkedRoles.includes(role));
            }
         });
      }
   }

   public onRolesChecked($event) {
      this.checkedRoles = $event.value;
      this.setDataModified();
   }

   public onWebPushCategoryChecked($event) {
      this.checkedWebPushCategory = $event.value;
      this.profile.subscriptionCategories = this.checkedWebPushCategory.join(",");
      this.setDataModified();
   }

   public onSaveProfile() {
      let manageUUser = JSON.parse(JSON.stringify(this.profile));

      this.userService.update(manageUUser).subscribe({
         next: data => {
            let dataDecrypted = this.encryptionService.decryptObject(false, (data as encryptedValue).value);

            if (dataDecrypted != null) {
               this.profile = dataDecrypted as user;
               if (this.profileId == "-1")
               {
                  this.storageService.updateTokenUser(this.profile);
                  this.subjectService.UserConnectedSubject.next(this.profile);
               }

               this.cacheService.manageUUser(dataDecrypted);
               this.subjectService.IsTrainingPlayerCacheLoadedSubject.next(true);
               this.frameworkService.displayAlert(true);
               this.subjectService.IsButtonInLoadingSubject.next(false);

               this.isDataModified = null;
               this.subjectService.IsDataUpdatingSubject.next(false);
            }
            else
               this.frameworkService.displayAlert(false);
            this.subjectService.IsButtonInLoadingSubject.next(false);
         },
         error: err => {
            this.frameworkService.displayAlert(null, err);
            this.subjectService.IsButtonInLoadingSubject.next(false);
         }
      });
   }

   public onTeamChange1() {
      this.profile.teamSysId = this.form.get('allTeam1').value.sysId;
      this.setDataModified();
   }

   public onTeamChange2() {
      this.profile.team2SysId = this.form.get('allTeam2').value.sysId;
      this.setDataModified();
   }

   public onTeamClick() {
      let temp: number[] = [];
      this.checkedTeams.forEach(i => temp.push(i.sysId));
      this.profile.isNotificationAvailabilityForTeamByEmailActivate = temp.join(";");
      this.setDataModified();
   }

   public onTeamsChecked($event) {
      this.checkedTeams = $event.value;
   }

   public onUpdateRoleOfMember(userSysId: number, role: string, isToBeAdded: boolean) {
      this.userService.updateRoleOfMember(userSysId, role, isToBeAdded).subscribe({
         next: data => {
            let dataDecrypted = this.encryptionService.decryptObject(false, (data as encryptedValue).value);

            if (dataDecrypted != null) {
               this.profile = dataDecrypted as user;
               this.cacheService.manageUUser(dataDecrypted);
               this.subjectService.IsTrainingPlayerCacheLoadedSubject.next(true);
               this.frameworkService.displayAlert(true);
            }
            else
               this.frameworkService.displayAlert(false);
            this.subjectService.IsButtonInLoadingSubject.next(false);
         },
         error: err => {
            this.frameworkService.displayAlert(null, err);
            this.subjectService.IsButtonInLoadingSubject.next(false);
         }
      });
   }

   public resetTryPasswordUser(profile) {
      this.userService.resetLoginTries(profile.sysId).subscribe({
         next: data => {
            let dataDecrypted = this.encryptionService.decryptObject(false, (data as encryptedValue).value);

            if (dataDecrypted != null) {
               this.profile = dataDecrypted as user;
               this.cacheService.manageUUser(dataDecrypted);
               this.subjectService.IsTrainingPlayerCacheLoadedSubject.next(true);
               this.frameworkService.displayAlert(true);
            }
            else
               this.frameworkService.displayAlert(false);
            this.subjectService.IsButtonInLoadingSubject.next(false);
         },
         error: err => {
            this.frameworkService.displayAlert(null, err);
            this.subjectService.IsButtonInLoadingSubject.next(false);
         }
      });
   }

   public setDataModified() {
      this.isDataModified = true;
      this.subjectService.IsDataUpdatingSubject.next(true);
   }

   public simulateProfile(profile) {
      this.storageService.updateTokenUser(profile);
      this.subjectService.UserConnectedSubject.next(profile);

      //-> REFRESH the cacheService
      //this.frameworkService.logInfo("CALL cacheLoadStart","simulateProfile (profile)");
      this.cacheService.cacheStartLoad(enumCacheTypeLoad.fromWebAPI, false, false);
      this.subjectService.IsButtonInLoadingSubject.next(false);

      this.router.navigate(['/home']);
   }

   public translatePage() {
      //ProfileTitle
      this.translateService.get(['ProfileTitle'])
         .subscribe(translations => {
            this.titleMyProfileDescriptionDisplay = translations['ProfileTitle'];
         });

      //ProfileOfMemberTitle
      this.translateService.get(['ProfileOfMemberTitle'])
         .subscribe(translations => {
            this.titleProfileOfMemberDescriptionDisplay = translations['ProfileOfMemberTitle'];
         });
   }

   public updateNotificationVisibility(level: number, status: number) {
      switch (level) {
         //isNotificationByEmailActivate
         case 0:
            {
               this.profile.isNotificationByEmailActivate = !!status;
               this.form.get('isNotificationByEmailActivate').setValue(this.profile.isNotificationByEmailActivate);
               this.setDataModified();
               break;
            }
         //isNotificationMatchByEmailActivate
         case 1:
            {
               this.profile.isNotificationMatchByEmailActivate = !!status;
               this.form.get('isNotificationMatchByEmailActivate').setValue(this.profile.isNotificationMatchByEmailActivate);
               this.setDataModified();
               break;
            }
         //isNotificationMatchOfClubByEmailActivate
         case 5:
            {
               this.profile.isNotificationMatchOfClubByEmailActivate = !!status;
               this.form.get('isNotificationMatchOfClubByEmailActivate').setValue(this.profile.isNotificationMatchOfClubByEmailActivate);
               this.setDataModified();
               break;
            }
         //isNotificationComiteeMeetingByEmailActivate
         case 2:
            {
               this.profile.isNotificationComiteeMeetingByEmailActivate = !!status;
               this.form.get('isNotificationComiteeMeetingByEmailActivate').setValue(this.profile.isNotificationComiteeMeetingByEmailActivate);
               this.setDataModified();
               break;
            }
         //isNotificationAvailabilityByEmailActivate
         case 3:
            {
               this.profile.isNotificationAvailabilityByEmailActivate = !!status;
               this.form.get('isNotificationAvailabilityByEmailActivate').setValue(this.profile.isNotificationAvailabilityByEmailActivate);
               this.setDataModified();
               break;
            }
         //isNotificationAvailabilityReportByEmailActivate
         case 4:
            {
               this.profile.isNotificationAvailabilityReportByEmailActivate = !!status;
               this.form.get('isNotificationAvailabilityReportByEmailActivate').setValue(this.profile.isNotificationAvailabilityReportByEmailActivate);
               this.setDataModified();
               break;
            }
         //isNotificationByPushActivate
         case 6:
            {
               this.profile.isNotificationByPushActivate = !!status;
               this.form.get('isNotificationByPushActivate').setValue(this.profile.isNotificationByPushActivate);
               this.setDataModified();
               break;
            }
         //isNotificationActivityReportByEmailActivate
         case 7:
            {
               this.profile.isNotificationActivityReportByEmailActivate = !!status;
               this.form.get('isNotificationActivityReportByEmailActivate').setValue(this.profile.isNotificationActivityReportByEmailActivate);
               this.setDataModified();
               break;
            }
      }
      this.subjectService.IsButtonInLoadingSubject.next(false);
   }

   public updatePrivacyVisibility(level: number, status: number) {
      switch (level) {
         case enumPrivacyControlType.Email:
            {
               this.profile.emailPrivacyLevel = status;
               this.setDataModified();
               break;
            }
         case enumPrivacyControlType.Phone1:
            {
               this.profile.phone1PrivacyLevel = status;
               this.setDataModified();
               break;
            }
         case enumPrivacyControlType.Phone2:
            {
               this.profile.phone2PrivacyLevel = status;
               this.setDataModified();
               break;
            }
         case enumPrivacyControlType.Address:
            {
               this.profile.addressPrivacyLevel = status;
               this.setDataModified();
               break;
            }
         case enumPrivacyControlType.BirthdayDate:
            {
               this.profile.birthdayDatePrivacyLevel = status;
               this.setDataModified();
               break;
            }
      }
      this.subjectService.IsButtonInLoadingSubject.next(false);
   }

   acceptWebPush() {
      this.notificationService.requestPermission();
   }

   isServiceWorkerIsSupported() {
      return "serviceWorker" in navigator;
   }

   subscribeWebPush() {
      if (!this.isServiceWorkerIsSupported()) {
         this.frameworkService.displayAlert(false, "Les notifications ne sont pas supportées par votre navigateur.");
      }
      else {
         if (this.isDataModified) {
            this.frameworkService.displayAlert(false, "Vous avez des données en cours de modification. Veuillez les sauver préalablement, svp.");
            this.subjectService.IsButtonInLoadingSubject.next(false);
         }
         else {
            this.notificationService.subscribeToNotifications(this.profile.sysId)
               .subscribe(user => {
                  if (user != null) {
                     this.profile = user;
                     this.cacheService.manageUUser(user);
                     this.subjectService.IsTrainingPlayerCacheLoadedSubject.next(true);

                     this.isDataModified = null;
                     this.subjectService.IsDataUpdatingSubject.next(false);
                     this.frameworkService.displayAlert(true, "Vous êtes abonné aux notifications web push");
                     this.subjectService.IsButtonInLoadingSubject.next(false);
                  }
                  else {
                     this.frameworkService.displayAlert(false, "Votre souscription est impossible en raison d'une erreur. Veuillez contacter l'administrateur, svp.");
                     this.subjectService.IsButtonInLoadingSubject.next(false);
                  }
               });
         }
      }
   }

   unsubscribeWebPush() {
      this.profile.subscriptionEndpoint = "";
      this.profile.subscriptionP256DH = "";
      this.profile.subscriptionAuth = "";
      this.setDataModified();
      this.frameworkService.displayAlert(true, "Sauver les données afin de vous désabonné des notifications web push");
      this.subjectService.IsButtonInLoadingSubject.next(false);
   }

   SetCategoryWebPush() {
      this.profile.subscriptionCategories = "Test";
      this.setDataModified();
      this.subjectService.IsButtonInLoadingSubject.next(false);
   }

   SendMessageWebPush() {
      if (this.cacheService.getCurrentChampionship() != null && this.profile.subscriptionEndpoint != "" && this.profile.subscriptionP256DH != "" && this.profile.subscriptionAuth != "") {
         let appServiceArray: Observable<any>[] = [];

         let pushNotificationModel = new PushNotificationModel();
         pushNotificationModel.championshipSysId = this.cacheService.getCurrentChampionship().sysId;
         pushNotificationModel.message = "Ceci est une notification de test";
         pushNotificationModel.category = "Test";
         pushNotificationModel.users = [];

         appServiceArray.push(this.systemService.NotificationMessageSend(pushNotificationModel));

         forkJoin(appServiceArray).subscribe(res => {
            if (res[0]) {
               this.subjectService.IsButtonInLoadingSubject.next(false);
               this.frameworkService.displayAlert(true, "La notification a été envoyée.");
            }
            else {
               this.subjectService.IsButtonInLoadingSubject.next(false);
               this.frameworkService.displayAlert(false, "La notification n'a pas pu être envoyée en raison d'une erreur. Veuillez contacter l'administrateur, svp.");
            }
         });
      }
      else {
         this.subjectService.IsButtonInLoadingSubject.next(false);
         this.frameworkService.displayAlert(false, "La notification n'a pas pu être envoyée en raison d'une erreur. Veuillez contacter l'administrateur, svp.");
      }

   }
}