import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import 'linq-typed';
import { enumCacheStatusLoad, enumRoles } from 'src/app/enum/Enum';
import { user } from 'src/app/models/user/user';
import { AccessRightsService } from 'src/app/services/accessRights.service';
import { AccountService } from 'src/app/services/account.service';
import { AppConfigService } from 'src/app/services/appconfig.service';
import { FrameworkService } from 'src/app/services/framework.service';
import { CacheService } from '../../services/cache.service';
import { SubjectService } from 'src/app/services/subject.service';
import { StorageService } from '../../authentication/storage.service';

@Component({
   selector: 'app-comiteeMember',
   templateUrl: './comiteeMember.component.html',
   styleUrls: ['./comiteeMember.component.scss']
})
export class ComiteeMemberComponent implements OnInit {
   public allMembersByAllTeam: user[] = [];
   public allMembersByFirstname: user[] = [];
   public allMembersByLastname: user[] = [];
   public allMembersByRole;
   public allMembersByTeam: user[] = [];
   public allMembersSelected: user[] = [];
   public allRolesOfMembers = [];
   public form: FormGroup = new FormGroup({});
   public isDisplayOnAMobile: boolean = (window.innerWidth <= 960);
   public isNotFirstTime: boolean = false;
   public queryParams: any;
   public tabIndex: number;
   public titleDescriptionDisplay: string = "";

   constructor(
      public dialog: MatDialog,
      private cacheService: CacheService,
      private frameworkService: FrameworkService,
      private translateService: TranslateService,
      private formBuilder: FormBuilder,
      private activeRouter: ActivatedRoute,
      public accessRightsService: AccessRightsService,
      public storageService: StorageService,
      private subjectService: SubjectService
   ) {
      this.getScreenSize();
   }

   @HostListener('window:resize', ['$event'])
   public getScreenSize() {
      this.isDisplayOnAMobile = (window.innerWidth <= 960);
   }

   public ngOnInit() {
      this.subjectService.GlobalCacheLoadedStatusSubject.subscribe(value => { if (value != null && value >= enumCacheStatusLoad.loaded) this.initialize(); });

      this.activeRouter.queryParams.subscribe(params => {
         this.queryParams = params;
         if (this.queryParams["tabType"] == undefined) {
         }
         else {
            this.tabIndex = Number(this.queryParams["tabType"]);
         }
      });

      this.form = this.formBuilder.group({
         allMembersSelectedArray: this.formBuilder.array([])
      });
   }

   public getAllMembersByRole(): Map<string, object> {
      let list = new Map<string, object>();
      let allUsers = this.cacheService.getAllUsers().filter(i => i.sysId != 1 && i.sysId != 2).sort(function (a, b) {
         if (a.firstName + a.lastName < b.firstName + b.lastName) {
            return -1;
         }
         if (a.firstName + a.lastName > b.firstName + b.lastName) {
            return 1;
         }
         return 0;
      });
      allUsers.forEach(u => this.frameworkService.getProfileRoles(u, false, false).forEach(i => {
         let temp = [];
         temp = list.get(i) as user[];
         if (temp == undefined) {
            temp = [];
         }
         temp.push(u);
         list.set(i, temp);
      }
      ));

      return list;
   }

   public getAllUsersOfRole(role): [] {
      if (this.allMembersByRole != null) {
         return this.allMembersByRole.get(role);
      }
      return [];
   }

   public getClass(alignRight): string {
      if (alignRight)
         return "fontSmaller alignmentRight";
      else
         return "fontSmaller";
   }

   public initialize() {
      this.translatePage();
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
         this.translatePage();
      });

      this.refresh(false);
   }

   public refresh(reload: boolean) {
      this.allMembersByRole = this.getAllMembersByRole();
      this.allRolesOfMembers = this.frameworkService.getAllRolesOfMembers().filter(i => i.code != enumRoles.Coach.toString() && i.code != enumRoles.AssistantCoach.toString() && i.code != enumRoles.MarkerDelegate.toString() && i.code != enumRoles.TeamContact.toString());
   }

   public translatePage() {
      //ProfileTitle
      this.translateService.get(['MemberTitle'])
         .subscribe(translations => {
            this.titleDescriptionDisplay = translations['MemberTitle'];
         });
   }
}
