//This file is autogenerated by C# GeneratedModels

import { jsonIgnore } from "json-ignore";
import { auditFields } from "src/app/models/system/auditFields";
import { metadataModelBase } from "src/app/models/system/metadataModelBase";
import { enumControlType, enumWorkgroup } from "../../app/enum/Enum";
import { championship } from "./championship";

@Reflect.metadata('buildingTrainingBase', 'buildingTrainingBase')
export class buildingTrainingBase extends auditFields {

 @Reflect.metadata('code',
  <metadataModelBase> <unknown> {
    editVariableValidator: {
    "MaxLength": "20"
    } 
  })
  code : string = "";

 @Reflect.metadata('buildingName',
  <metadataModelBase> <unknown> {
    editVariableValidator: {
    "MaxLength": "100"
    } 
  })
  buildingName : string = "";

 @Reflect.metadata('address',
  <metadataModelBase> <unknown> {
    editVariableValidator: {
    "MaxLength": "250"
    } 
  })
  address : string = "";

 @Reflect.metadata('zipCode',
  <metadataModelBase> <unknown> {
    editVariableValidator: {
    "MaxLength": "10"
    } 
  })
  zipCode : string = "";

 @Reflect.metadata('locality',
  <metadataModelBase> <unknown> {
    editVariableValidator: {
    "MaxLength": "50"
    } 
  })
  locality : string = "";

  @Reflect.metadata('championshipSysId',
  <metadataModelBase> <unknown> {
    editControlType: enumControlType.Dropdown,
    editDropdownEntityMapping : "championship",
    editDropdownPropertiesForDisplay : "yearStarting;yearEnding",
    editDropdownFormatForDisplay : "{0}-{1}"
  })
  championshipSysId : number = 0;

  @Reflect.metadata('championship',
  <metadataModelBase> <unknown> {
    editColumnIsVisible: enumWorkgroup[enumWorkgroup.None],
    gridColumnIsVisible: enumWorkgroup[enumWorkgroup.None]
  })
  @jsonIgnore()
  championship : championship;
}