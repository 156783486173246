import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { AppConfigService } from './appconfig.service';
import { HttpTransportType, HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';
import { CacheService } from 'src/app/services/cache.service';
import { SubjectService } from './subject.service';
import { FrameworkService } from './framework.service';

@Injectable({
  providedIn: 'root'
})
export class SignalRService implements OnDestroy {
  private hubConnection: signalR.HubConnection;
  public HubConnectionSubject: Subject<boolean> = new Subject<boolean>();

  constructor(
    private appConfigService: AppConfigService, 
    public subjectService: SubjectService) {
  }

  public startConnection(subjectService: SubjectService) {
    this.hubConnection = new HubConnectionBuilder()
      .configureLogging(LogLevel.Debug)
      .withAutomaticReconnect([10000, 30000])
      .withUrl(this.appConfigService.getApiUrl() + 'SignalHub/', {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
      })
      .build();

    this.hubConnection.serverTimeoutInMilliseconds = 100000; // 100 seconds
    this.hubConnection.keepAliveIntervalInMilliseconds = 60000; // 60 seconds

    this.hubConnection.start().then(function () {
      subjectService.HubConnectionStatusSubject.next(2);

    }).catch(function (err) {
      subjectService.HubConnectionStatusSubject.next(0);
      return console.error("==> ERROR : " + err.toString());
    });

    this.hubConnection.onreconnecting(error => {
      subjectService.HubConnectionStatusSubject.next(1);
    });
    this.hubConnection.onreconnected(connectionId => {
      subjectService.HubConnectionStatusSubject.next(2);
    });
    this.hubConnection.onclose(error => {
      subjectService.HubConnectionStatusSubject.next(0);
    });

    this.hubConnection.on("SynchroniseAsync", (type: number, method: number) => {
      switch(type)
      {
        case 1:
          this.subjectService.IsSynchronisationInProgressSubject.next(method);
          break;
        case 2:
          this.subjectService.IsSynchronisationMembersInProgressSubject.next(method);
          break;
        case 3:
          this.subjectService.IsSynchronisationSeniorMatchsInProgressSubject.next(method);
          break;
        case 4:
          this.subjectService.IsSynchronisationYoungMatchsInProgressSubject.next(method);
          break;
      }
    });
  }

  public closeHubConnection() {
    //this.frameworkService.logInfo(this.hubConnection.state != HubConnectionState.Disconnected && this.hubConnection.state != HubConnectionState.Disconnecting, "closeHubConnection");

    if (this.hubConnection != null) {
      //this.frameworkService.logInfo(this.hubConnection.state, "closeHubConnection state 1");
      if (this.hubConnection.state != HubConnectionState.Disconnected && this.hubConnection.state != HubConnectionState.Disconnecting) {
        this.hubConnection.stop();
        //this.frameworkService.logInfo(this.hubConnection.state, "closeHubConnection state 2");
      }
    }
  }

  public ngOnDestroy(): void {
    this.HubConnectionSubject.unsubscribe();
  }

  //BOOKMARK : isHubConnectionClosed
  public isHubConnectionClosed(): boolean {
    return this.hubConnection.state != HubConnectionState.Connected;
  }
}
