<div class="mat-card-popup">
   <mat-card class="mat-card-color">
      <mat-card-header>
         <mat-card-title><img class="mat-card-icon"
               src="../../app/images/account/recoverLoginPassword.png" alt="Recover login"> {{
            'RecoverLoginPasswordTitle' | translate }}</mat-card-title>
         <mat-card-subtitle>{{ 'RecoverLoginPasswordDescription' | translate }}</mat-card-subtitle>
      </mat-card-header>

      <mat-card-content class="mat-card-content-padding">
         <form [formGroup]="form">
            <div class="form-group text-center">
               <input type="text" formControlName="username" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && form.controls.username.errors }"
                  placeholder="{{ 'Username' | translate }}" />
               <div *ngIf="submitted && form.controls.username.errors" class="invalid-feedback">
                  <div *ngIf="form.controls.username.errors.required">{{ 'UsernameIsRequired' | translate }}</div>
               </div>
            </div>

            <app-alertShow></app-alertShow>

            <div class="text-center mt-12 font-weight-light">
               <app-button icon="send" [class]="'spanButtonSeparator'" [text]="'RecoverLoginPasswordCommand' | translate" [isBackgroundOpaque]="true" (onClick)="onRecoverPasswordCommand()"></app-button>
            </div>

            <div style="height:2%"><br /></div>

            <div class="text-center font-weight-light">
               <a routerLink="/login" class="auth-link text-white">{{ 'LoginBack' | translate }}</a>
            </div>
         </form>
      </mat-card-content>
   </mat-card>
</div>