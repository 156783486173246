//This file is autogenerated by C# GeneratedModels

import { jsonIgnore } from "json-ignore";
import { auditFields } from "src/app/models/system/auditFields";
import { metadataModelBase } from "src/app/models/system/metadataModelBase";
import { enumControlType, enumWorkgroup } from "../../app/enum/Enum";
import { championship } from "./championship";

@Reflect.metadata('sponsorBase', 'sponsorBase')
export class sponsorBase extends auditFields {

   @Reflect.metadata('name',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "50"
         }
      })
   name: string = "";

   @Reflect.metadata('description',
      <metadataModelBase><unknown>{
         editVariableValidator: {
         }
      })
   description: string = "";

   @Reflect.metadata('picture',
      <metadataModelBase><unknown>{
         editControlType: enumControlType.Picture,
         editVariableValidator: {
         }
      })
   picture: string = "";

   @Reflect.metadata('address',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "250"
         }
      })
   address: string = "";

   @Reflect.metadata('zipCode',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "10"
         }
      })
   zipCode: string = "";

   @Reflect.metadata('locality',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "50"
         }
      })
   locality: string = "";

   @Reflect.metadata('phone',
      <metadataModelBase><unknown>{
         editControlType: enumControlType.Text, //TODO JD Manage tel and gsm format
         editVariableValidator: {
            "MaxLength": "50"
         }
      })
   phone: string = "";

   @Reflect.metadata('email',
      <metadataModelBase><unknown>{
         editControlType: enumControlType.Email,
         editVariableValidator: {
            "MaxLength": "50"
         }
      })
   email: string = "";

   @Reflect.metadata('website',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "50"
         }
      })
   website: string = "";

   @Reflect.metadata('order',
   <metadataModelBase> <unknown> {
     editVariableValidator: {
     } 
   })
   order : number = 0;

   @Reflect.metadata('championshipSysId',
      <metadataModelBase><unknown>{
         editControlType: enumControlType.Dropdown,
         editDropdownEntityMapping: "championship",
         editDropdownPropertiesForDisplay: "yearStarting;yearEnding",
         editDropdownFormatForDisplay: "{0}-{1}",
         editColumnIsVisible: enumWorkgroup[enumWorkgroup.UserEdit],
         gridColumnIsVisible: enumWorkgroup[enumWorkgroup.None]
      })
   championshipSysId: number = 0;

   @Reflect.metadata('championship',
      <metadataModelBase><unknown>{
         editColumnIsVisible: enumWorkgroup[enumWorkgroup.None],
         gridColumnIsVisible: enumWorkgroup[enumWorkgroup.None]
      })
   @jsonIgnore()
   championship: championship;
}