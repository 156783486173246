import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { enumWorkgroup } from '../enum/Enum';
import { user } from '../models/user/user';
import { AppConfigService } from './appconfig.service';
import { FrameworkService } from './framework.service';
import { LogService } from './log.service';
import { encryptedValue } from 'src/app/authentication/encryptedValue';
import { StorageService } from 'src/app/authentication/storage.service';
import { jsonIgnoreReplacer } from 'json-ignore';
import { EncryptionService } from './encryption.service';
import { SubjectService } from './subject.service';
import { auth } from '../models/auth/auth';
import { changePassword } from '../models/auth/changePassword';

@Injectable({ providedIn: 'root' })
//-- Account service
export class AccountService implements OnInit, OnDestroy {
   constructor(
      private appConfigService: AppConfigService,
      private storageService: StorageService,
      private logger: LogService,
      private translateService: TranslateService,
      private frameworkService: FrameworkService,
      private router: Router,
      private httpClient: HttpClient,
      private subjectService: SubjectService,
      private encryptionService: EncryptionService
   ) {
      this.subjectService.UserConnectedSubject.next(storageService.getUserConnected());
   }

   public changeAccountPassword(username: string, newPassword: string, accountSecurityCode: string): Observable<encryptedValue> {
      //console.log("TRACE -> changeAccountPassword");
      
      let encryptedData = new encryptedValue();
      encryptedData.value = this.encryptionService.encryptObject(true, false, new changePassword(username, newPassword, accountSecurityCode));

      let url = "/Q202800003";
      return this.httpClient.post<encryptedValue>(this.appConfigService.getApiUrl() + 'api/C2028' + url, JSON.parse(JSON.stringify(encryptedData, jsonIgnoreReplacer)))
         .pipe(
            tap(data => {
               return (data != null);
            }),
            catchError(this.handleError)
         );
   }

   public isAuthenticated(username, password): Observable<encryptedValue> {
      let encryptedData = new encryptedValue();
      encryptedData.value = this.encryptionService.encryptObject(true, false, new auth(username, password));

      let url = "/Q200400003";
      return this.httpClient.post<encryptedValue>(this.appConfigService.getApiUrl() + 'api/C2004' + url, JSON.parse(JSON.stringify(encryptedData, jsonIgnoreReplacer)))
         .pipe(
            tap(data => {
               return data;
            }),
            catchError(this.handleError)
         );
   }


   public isUsernameAvailable(user: user): Observable<encryptedValue> {
      let encryptedData = new encryptedValue();
      encryptedData.value = this.encryptionService.encryptObject(true, false, user);

      const username = this.frameworkService.buildUsername(user.firstName, user.lastName);
      let url = "/Q202800008/" + encodeURI(username);
      return this.httpClient.post<encryptedValue>(this.appConfigService.getApiUrl() + 'api/C2028' + url, JSON.parse(JSON.stringify(encryptedData, jsonIgnoreReplacer)))
         .pipe(
            tap(data => {
               return data;
            }),
            catchError(this.handleError)
         );
   }

   public signOut() {
      this.storageService.signOut();
      this.translateService.use('fr'); //Set language to fr (default)
      this.subjectService.UserConnectedSubject.next(null);
      this.router.navigate(['/home']);
   }

   public ngOnDestroy(): void {
      this.subjectService.UserConnectedSubject.unsubscribe();
   }

   public ngOnInit() {
   }

   //TODO (User) : call userService.Insert instead
   public register(user: user) {
      let url = "/Q2004?username=REGISTER";
      user.workgroupSysId = enumWorkgroup.UserReadOnly;
      user.language = "fr";
      user.username = this.frameworkService.buildUsername(user.firstName, user.lastName);

      let encryptedData = new encryptedValue();
      encryptedData.value = this.encryptionService.encryptObject(true, false, user);

      return this.httpClient.post<encryptedValue>(this.appConfigService.getApiUrl() + 'api/C2028' + url, JSON.parse(JSON.stringify(encryptedData, jsonIgnoreReplacer)));
   }

   public sendAccountSecurityCode(username: string, websiteDomain: string): Observable<encryptedValue> {
      //console.log("TRACE -> sendAccountSecurityCode");
      
      let encryptedData = new encryptedValue();
      encryptedData.value = this.encryptionService.encryptObject(true, false, username);

      let url = "/Q202800009/" + encodeURI(websiteDomain);
      return this.httpClient.post<encryptedValue>(this.appConfigService.getApiUrl() + 'api/C2028' + url, JSON.parse(JSON.stringify(encryptedData, jsonIgnoreReplacer)))
         .pipe(
            tap(data => {   // Get user with AccountSecurityCode and validation
               if (data != null) {
                  return data;
               }
               else
                  return null;
            }),
            catchError(this.handleError)
         );
   }

   public sendLoginProcedureToUser(usersList: user[], websiteDomain: string): Observable<encryptedValue> {
      let encryptedData = new encryptedValue();
      encryptedData.value = this.encryptionService.encryptObject(true, false, usersList);

      let url = "/Q202800010/" + encodeURI(websiteDomain) + "/" + encodeURI("LoginProcedure");
      return this.httpClient.post<encryptedValue>(this.appConfigService.getApiUrl() + 'api/C2028' + url, JSON.parse(JSON.stringify(encryptedData, jsonIgnoreReplacer)))
         .pipe(
            tap(data => {
               if (data != null) {
                  return data;
               }
               else
                  return null;
            }),
            catchError(this.handleError)
         );
   }

   public sendVCardToUser(usersList: user[], email: string): Observable<encryptedValue> {
      let encryptedData = new encryptedValue();
      encryptedData.value = this.encryptionService.encryptObject(true, false, usersList);

      let url = "/Q202800013/" + encodeURI(email);
      return this.httpClient.post<encryptedValue>(this.appConfigService.getApiUrl() + 'api/C2028' + url, JSON.parse(JSON.stringify(encryptedData, jsonIgnoreReplacer)))
         .pipe(
            tap(data => {
               if (data != null) {
                  return data;
               }
               else
                  return null;
            }),
            catchError(this.handleError)
         );
   }

   private handleError(err: HttpErrorResponse): Observable<never> {
      return throwError(() => err);
   }
}