<div class="mat-card-popup">
   <mat-card class="mat-card-color">
      <mat-card-header>
         <mat-card-title><img class="mat-card-icon" src="../../app/images/account/changePassword.png"
               alt="Change password"> {{ 'ChangePasswordTitle' | translate }}</mat-card-title>
      </mat-card-header>

      <mat-card-content class="mat-card-content-padding">
         <div *ngIf="!isChangeSuccessfull"><li>{{ 'ChangePasswordDescription' | translate }}</li></div>

         <form [formGroup]="form">
            <div *ngIf="!isChangeSuccessfull">
               <form [formGroup]="form" autocomplete="dontAutoComplete">
                  <div class="form-group text-center"
                     style="padding:10px;width: 200px;margin-left: auto;margin-right: auto;border-radius: 4px;">
                     <input type="text" autocomplete="noac" formControlName="accountSecurityCode"
                        class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && form.controls.accountSecurityCode.errors }"
                        placeholder="{{ 'AccountSecurityCode' | translate }}" />
                     <div *ngIf="form.controls.accountSecurityCode.errors" class="invalid-feedback">
                        <div *ngIf="form.controls.accountSecurityCode.errors.required">{{ 'AccountSecurityCodeIsRequired' |
                           translate }}</div>
                     </div>
                  </div>

                  <div><li>{{ 'ChangePasswordDescription2' | translate }}</li>
                  </div>
   
                  <div class="form-group text-center">
                     <input [type]="'text'" maxLength="50" autocomplete="new-password"
                        formControlName="password" class="form-control"
                        placeholder="{{ 'Password' | translate }}"
                        [ngClass]="{ 'is-invalid': submitted && form.controls.password.errors }"
                        style="float:left;width:calc(100% - 30px)" />&nbsp;
                     <!-- <mat-icon matSuffix style="margin-top:10px;float: right;" (click)="hidePassword = !hidePassword">
                        {{hidePassword ?
                        'visibility_off' : 'visibility'}}</mat-icon> -->
   
                     <app-passwordStrength class="passwordStrength" [passwordToCheck]="getPassword()"
                        [barLabel]="barLabel"></app-passwordStrength>
   
                     <div *ngIf="form.controls.password.errors" class="invalid-feedback">
                        <div *ngIf="form.controls.password.errors.required">{{ 'PasswordIsRequired' | translate }}</div>
                        <div *ngIf="form.controls.password.errors.minlength">{{ 'PasswordCheckRule' | translate }}</div>
                        <div *ngIf="form.controls.password.errors.strengthPassword">{{ 'PasswordStrengthIsTooLow' |
                           translate }}</div>
                     </div>
                  </div>
   
                  <div class="form-group text-center">
                     <input [type]="'text'" maxLength="50"
                        autocomplete="new-password" formControlName="confirmPassword" class="form-control"
                        placeholder="{{ 'ConfirmPassword' | translate }}"
                        [ngClass]="{ 'is-invalid': submitted && form.controls.confirmPassword.errors }"
                        style="float:left;width:calc(100% - 30px)" />&nbsp;
                     <!-- <mat-icon matSuffix style="margin-top:10px;float: right;"
                        (click)="hideConfirmationPassword = !hideConfirmationPassword">{{hideConfirmationPassword ?
                        'visibility_off' : 'visibility'}}</mat-icon> -->
   
                     <app-passwordStrength class="passwordStrength" [passwordToCheck]="getConfirmationPassword()"
                        [barLabel]="barLabel"></app-passwordStrength>
   
                     <div *ngIf="form.controls.confirmPassword.errors" class="invalid-feedback">
                        <div *ngIf="form.controls.confirmPassword.errors.required">{{ 'ConfirmPasswordIsRequired' |
                           translate }}</div>
                        <div *ngIf="form.controls.confirmPassword.errors.minlength">{{ 'ConfirmPasswordCheckRule' |
                           translate }}</div>
                        <div *ngIf="form.controls.confirmPassword.errors.confirmedValidator">{{
                           'ConfirmPasswordMustBeMatch' | translate }}</div>
                     </div>
                  </div>
   
                  <div class="text-center mt-12 font-weight-light">
                     <app-button icon="send" [class]="'spanButtonSeparator'" [text]="'ChangePasswordCommand' | translate" [isBackgroundOpaque]="true" (onClick)="onConfirmChangePasswordCommand()"></app-button>
                  </div>
               </form>
            </div>
   
            <div style="height:2%"><br /></div>
   
            <app-alertShow></app-alertShow>
            <div class="text-center font-weight-light">
               <a routerLink="/login" class="auth-link text-white">{{ 'LoginBack' | translate }}</a>
            </div>
         </form>
      </mat-card-content>
   </mat-card>
</div>