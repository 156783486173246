import { Component, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import 'linq-typed';
import { enumCacheStatusLoad, enumDatagridHeaderOrder, enumQueryFilterOp } from 'src/app/enum/Enum';
import { datagridParam } from 'src/app/models/system/datagridParam';
import { FrameworkService } from 'src/app/services/framework.service';
import { team } from 'src/app/models/team';
import { TeamService } from 'src/app/services/team.service';
import { CacheService } from '../../services/cache.service';
import { queryFilter } from '../../models/queryFilter';
import { encryptedValue } from '../../authentication/encryptedValue';
import { EncryptionService } from 'src/app/services/encryption.service';
import { SubjectService } from 'src/app/services/subject.service';

@Component({
   selector: 'app-adminTeam-list',
   templateUrl: './adminTeam-list.component.html',
   styleUrls: ['./adminTeam-list.component.scss']
})
export class AdminTeamListComponent implements OnInit {
   public championshipNameDisplay: string = "";
   public datagridParam: datagridParam<team>;
   public importDataDescriptionDisplay: string = "";
   public importDataTitleDisplay: string = "";
   public isLoading: boolean = false;

   constructor(
      private cacheService: CacheService,
      private subjectService: SubjectService,
      private frameworkService: FrameworkService,
      private translateService: TranslateService,
      private encryptionService: EncryptionService,
      private teamService: TeamService) {
      this.subjectService.IsDatagridShouldbeRefreshedSubject.subscribe(value => {
         if (this.isLoading) this.refresh();
      }
      );
   }

   public getDatagridFilters($event) {
      this.subjectService.IsDatagridShouldbeRefreshedSubject.next(true);
   }

   public initialize() {
      this.translatePage();
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
         this.translatePage();
      });

      this.refresh();
   }

   public ngOnInit() {
      this.subjectService.GlobalCacheLoadedStatusSubject.subscribe(value => { if (value != null && value >= enumCacheStatusLoad.loaded) this.initialize(); });
     }

   public refresh() {
      this.isLoading = false;

      const queryFilters = [];
      if (this.cacheService.getCurrentChampionship() != null)
         queryFilters.push(new queryFilter({ propertyName: "championshipSysId", operation: enumQueryFilterOp.Equals, value: this.cacheService.getCurrentChampionship().sysId }));

      this.teamService.getAllActiveWithoutIncluding(queryFilters).subscribe(
         (data: encryptedValue) => {
            let datasource = this.encryptionService.decryptObject(false, data.value);
            datasource.forEach(t => t = this.cacheService.loadTeamsForeignKeys(t));

            this.datagridParam = new datagridParam(
               {
                  hidden: !this.isLoading,
                  dataSource: datasource,
                  filters: null, //filters
                  mainTitle: "AdminTeamsListTitle",
                  headerOrder: enumDatagridHeaderOrder.ApiByDefault,
                  entity: new team(),
                  urlApi: "C1023",
                  paginatorItemsCount: this.frameworkService.getPaginatorItemsCount(),
                  formTitle: "TeamManagementTitle"
               });
            this.isLoading = true;
         }
      );
   }

   public translatePage() {
      //Championship
      this.translateService.get(['Championship'])
         .subscribe(translations => {
            this.championshipNameDisplay = translations['Championship'];
         });
   }
}
