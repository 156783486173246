//This file is autogenerated by C# GeneratedModels

import { jsonIgnore } from "json-ignore";
import { auditFields } from "src/app/models/system/auditFields";
import { metadataModelBase } from "src/app/models/system/metadataModelBase";
import { user } from "src/app/models/user/user";
import { enumControlType, enumWorkgroup } from "../../app/enum/Enum";
import { championship } from "./championship";

@Reflect.metadata('trainingPlayerBase', 'trainingPlayerBase')
export class trainingPlayerBase extends auditFields {

   @Reflect.metadata('day',
      <metadataModelBase><unknown>{
         editVariableValidator: {
         },
         editControlType: enumControlType.DateTime
      })
   day: Date = null;

   @Reflect.metadata('present',
      <metadataModelBase><unknown>{
         editVariableValidator: {
         }
      })
   present?: number = null;

   @Reflect.metadata('type',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "30"
         }
      })
   type: string = "";

   @Reflect.metadata('extraTrainingIsAtHome',
      <metadataModelBase><unknown>{
         editVariableValidator: {
         }
      })
   extraTrainingIsAtHome: boolean = false;

   @Reflect.metadata('extraTrainingTeam',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "50"
         }
      })
   extraTrainingTeam: string = "";

   @Reflect.metadata('extraTrainingTimeStarting',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "5"
         }
      })
   extraTrainingTimeStarting: string = "";

   @Reflect.metadata('extraTrainingTimeEnding',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "5"
         }
      })
   extraTrainingTimeEnding: string = "";

   @Reflect.metadata('extraTrainingBuilding',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "200"
         }
      })
   extraTrainingBuilding: string = "";

   @Reflect.metadata('reason',
      <metadataModelBase><unknown>{
         editVariableValidator: {
            "MaxLength": "30"
         }
      })
   reason: string = "";

   @Reflect.metadata('isPresenceNotCounted',
      <metadataModelBase><unknown>{
         editVariableValidator: {
         }
      })
   isPresenceNotCounted: boolean = false;

   @Reflect.metadata('trainingPlayerSysId',
      <metadataModelBase><unknown>{
         editVariableValidator: {
         }
      })
   trainingPlayerSysId?: number = null;

   @Reflect.metadata('playerSysId',
      <metadataModelBase><unknown>{
         editVariableValidator: {
         }
      })
   playerSysId: number = 0;

   @Reflect.metadata('player',
      <metadataModelBase><unknown>{
         editColumnIsVisible: enumWorkgroup[enumWorkgroup.None],
         gridColumnIsVisible: enumWorkgroup[enumWorkgroup.None]
      })
   @jsonIgnore()
   player: user = new user();

   @Reflect.metadata('championshipSysId',
      <metadataModelBase><unknown>{
         editControlType: enumControlType.Dropdown,
         editDropdownEntityMapping: "championship",
         editDropdownPropertiesForDisplay: "yearStarting;yearEnding",
         editDropdownFormatForDisplay: "{0}-{1}"
      })
   championshipSysId: number = 0;

   @Reflect.metadata('championship',
      <metadataModelBase><unknown>{
         editColumnIsVisible: enumWorkgroup[enumWorkgroup.None],
         gridColumnIsVisible: enumWorkgroup[enumWorkgroup.None]
      })
   @jsonIgnore()
   championship: championship;
}